import {useContext, useEffect, useRef, useState} from "react";
import AlertContext from "../../context/alert/AlertContext";
import dayjs from "dayjs";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import {findFile, updateFile} from "../../context/files/FileActions";
import FileContext from "../../context/files/FileContext";
import {useNavigate, useParams} from "react-router-dom";

function UpdateFile() {

    const { dispatch} = useContext( FileContext );
    const { dispatch: alertDispatch } = useContext(AlertContext)
    const { userData, token } = useTokenAndUserData();

    const [file, setFile] = useState(null);
    const [startDate, setDateOfPublication] = useState(dayjs().format('YYYY-MM-DD'));
    const [finishDate, setDateOfExpiration] = useState(dayjs().add(7, 'day').format('YYYY-MM-DD'));
    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const [isPriority, setIsPriority] = useState(false);
    const [fileName, setFileName] = useState('');
    const priorityRef = useRef();

    let { fileId } = useParams();

    useEffect(() => {
        const getData = async () => {
            const response = await findFile(fileId, userData.appId, token);
            console.log(response)
            setFileName(response.fileName);
            setDateOfPublication(response.startDate);
            setTitle(response.title);
            setDateOfExpiration(response.finalDate);
            setDescription(response.description);
            setIsPriority(response.isPriority);

            if(priorityRef.current){
                priorityRef.current.checked  = response.isPriority;

            }
        }

        getData();

    }, [userData]);

    const navigate = useNavigate();
    const handleChangeFile= (e) => {
        const f = e.target.files[0];
        if (f) {
            setFile(e.target.files[0]);
        }
    };
    const handleChangeDateOfPublication = (e) => setDateOfPublication(e.target.value);
    const handleChangeDateOfExpiration = (e) => setDateOfExpiration(e.target.value);
    const handleChangeDescription = (e) => setDescription(e.target.value);
    const handleChangeTitle = (e) => setTitle(e.target.value);
    const handleChangeIsPriority = (e) => setIsPriority(!isPriority);


    const handleSubmit = async (e) => {
        e.preventDefault()

        alertDispatch({ type: 'SET_LOADING' })
        const start = dayjs(startDate).format('DD/MM/YYYY');
        const finish = dayjs(finishDate).format('DD/MM/YYYY');

        const formData = new FormData();
        if(file){
            formData.append("file", file);
        }

        formData.append("fileDto", JSON.stringify({
            id:fileId,
            fileName,
            appId: userData.appId,
            appName: userData.app.appName.replace(" ", ""),
            title,
            description,
            isPriority,
            publicationDate:start,
            publicationFinalDate:finish,
            updatedBy : userData.email

        }));

        for (const pair of formData.entries()) {
            console.log(pair[0], pair[1]);
        }

        const newFile = await updateFile(formData, token)
        //
        dispatch({ type: 'ADD_FILE', payload: newFile });
        dispatch({ type: 'GET_FILES', payload: []});

        setFile('');
        setDateOfPublication('');
        setDateOfExpiration('');
        setDescription('');
        navigate(`/files`);

    }

    return (
        <div className="w-full mx-auto lg:w-10/12">
            <div className="text-center hero-content">
                <h1 className='text-3xl card-title'>Agregar Documento</h1>
            </div>

            <form onSubmit={handleSubmit}>
                <div className='form-control'>

                    <label className='mt-4 text-lg'>
                        File * :
                        <input
                            className="block mt-2 w-full text-md text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                            id="file_input"
                            type="file"

                            placeholder='file'
                            onChange={handleChangeFile} />

                    </label>

                    <label className='mt-4 text-lg'>
                        Titulo * :
                        <input
                            type='text'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Titulo'
                            value={title}
                            onChange={handleChangeTitle}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Fecha de publicación *:
                        <input
                            type='date'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='fecha de publicación'
                            value={startDate}
                            onChange={handleChangeDateOfPublication}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Fecha de expiración:
                        <input
                            type='date'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Fecha de expiración'
                            value={finishDate}
                            onChange={handleChangeDateOfExpiration}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Descripción:
                        <textarea
                            className='mt-2 w-full h-40 pr-4 bg-gray-200 input input-lg text-black'
                            placeholder='Descripción'
                            value={description}
                            onChange={handleChangeDescription}
                        />
                    </label>

                    <label className='label cursor-pointer mt-4 '>
                        <span className="text-lg">Destacar:</span>
                        <input
                            type='checkbox'
                            className='toggle'
                            value={isPriority}
                            ref={priorityRef}
                            onChange={handleChangeIsPriority}
                        />
                    </label>


                    <div className="mt-6 card-actions">
                        <button type='submit' className='btn btn-outline'>
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default UpdateFile
