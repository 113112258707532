const visitorReducer = (state, action) =>{
  switch(action.type) {
    case 'GET_VISITORS':
      return {
        ...state,
        visitors: action.payload,
        loading: false,
      }
    case 'GET_PREV_PAGE':
      return {
        ...state,
        previousPage: action.payload,
      }
    case 'GET_CURR_PAGE':
      return {
        ...state,
        currentPage: action.payload,
      }
    case 'GET_LAST_PAGE':
      return {
        ...state,
        lastPage: action.payload,
      }
    case 'GET_VISITOR':
      return {
        ...state,
        visitor: action.payload,
        loading: false,
      }
    case 'ADD_VISITOR':
      return {
        ...state,
        newVisitor: action.payload,
        loading: false,
      }
    case 'SET_PROVIDER':
      return {
        ...state,
        provider: action.payload,
        loading: false,
      }
    case 'GET_ADDRESSES':
      return {
        ...state,
        addresses: action.payload,
        loading: false,
      }
    case 'CLEAR_VISITORS':
      return {
        ...state,
        visitors: [],
      }
    case 'SET_IS_UP':
      return {
        ...state,
        isUpVisitor : action.payload
      }
    default:
      return state
  }
}

export default visitorReducer
