
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import {BsFillHouseDoorFill} from "react-icons/bs";
import {Link} from "react-router-dom";

function CollectCard ( { collect } ) {


    return (
        <>
            <div className='card shadow-md compact side bg-base-100'>
                <Link to={`/collects/transaction/${collect.id}/${collect.unitId}`} className="w-100%">

                <div className="grid grid-cols-6 xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-6 flex-row items-center space-x-4 card-body">


                    <div>
                        <div className="rounded-full w-16 h-16 col-span-1">
                            <BsFillHouseDoorFill className='text-6xl md:text-5xl' />
                        </div>
                    </div>


                    <div className={"col-span-3"}>
                        <h2 className='card-title'>
                            {collect.concept.description.slice(0,15)+"..." }
                        </h2>
                        <h3 className='text-base-content text-opacity-40'>{collect.description.slice(0,15)}</h3>
                        <h3 className='text-base-content text-opacity-40'> {collect.transactionDate}</h3>
                        {/*<h3 className='text-base-content text-opacity-60'> {collect.updatedBy} </h3>*/}

                    </div>


                    <div className={"w-full flex flex-col"}>

                        <div className={"w-full"}>

                            <p className='text-base-content text-opacity-50'>Cantidad </p>

                            <div className={`text-base-content text-opacity-50 flex flex-row`}>
                                {collect.concept.operation === 'SUB' ? "-" : "+"} {collect.quantity}
                            </div>

                        </div>

                        <div >

                            {/*<p className='text-base-content text-opacity-40'>Saldo</p>*/}

                            {/*<div className={`text-opacity-40  ${colorConcept}`}>*/}

                            {/*    {collect.balanceQuantity >= 0 && operatorConcept}  {collect.balanceQuantity}*/}

                            {/*</div>*/}

                        </div>

                    </div>

                </div>
                </Link>
            </div>

        </>
    )
}

export default CollectCard;
