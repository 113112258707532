import React, {useContext, useRef, useCallback, useEffect, useState} from 'react'
import VisitItem from './VisitItem'
import Spinner from '../layout/Spinner'
import VisitContext from '../../context/visits/VisitContext'
import {useNearScreen} from "../../hooks/useNearScreen";
import debounce from "just-debounce-it";

function VisitResults() {
    const externalRef = useRef()
    const { visits, currentPage, dispatch, loading } = useContext(VisitContext)
    const [minHeight, setMinHeight] = useState('50rem');

    const {isNearScreen} = useNearScreen({
        externalRef : loading ? null : externalRef,
        once: false  });

    const debounceHandleNextPage = useCallback( debounce(
        () =>  dispatch({ type: 'GET_CURR_PAGE', payload: currentPage + 1 }), 200
    ), [isNearScreen]);

    useEffect(() => {
        if(isNearScreen) debounceHandleNextPage();

    }, [debounceHandleNextPage, isNearScreen]);

    useEffect(() => {
        const handleResize = () => {

            if (window.innerHeight < 769) {
                setMinHeight('30rem');
            } else if (window.innerHeight < 1025) {
                setMinHeight('60rem');
            } else if (window.innerHeight > 1025) {
                setMinHeight('70rem');
            } else {
                setMinHeight('50rem');
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerHeight]);

    if(!loading) {
        return (
            <>
                <div style={{ minHeight }}>
                    <div
                        style={{
                            flexDirection: "column"
                        }}
                        className='grid grid-cols-1 gap-8 xl:grid-cols-3 lg:grid-cols-2 md:gris-cols-2'>
                        {visits.map((visit) => (
                            <VisitItem key={visit.visitCode} visit={visit} />
                        ))}
                    </div>
                </div>

                <div
                    id='scroll'
                    ref={externalRef}
                ></div>

            </>
        )
    } else {
        return (
            <Spinner />
        )
    }
}

export default VisitResults
