import {createRef, useState} from 'react'
import {useScreenshot, createFileName} from 'use-react-screenshot'
import {MdOutlineQrCode2} from 'react-icons/md'
import {useEffect, useContext} from 'react'
import {useParams/*, useNavigate*/} from 'react-router-dom'
import VisitContext from '../../context/visits/VisitContext'
import AlertContext from '../../context/alert/AlertContext'
import Spinner from '../layout/Spinner'
import {getVisit, updateVisit} from '../../context/visits/VisitActions'
import {validatePermissions} from '../../context/login/LoginActions'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import Ad from "../ads/Ad";

function getDateStr() {
    const date = new Date()
    date.setDate(date.getDate())
    const year = `${date.getFullYear()}`
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
    const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
    const hour = date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`
    const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : `${date.getSeconds()}`

    return [year.slice(-2), month, day, hour, minutes, seconds].join('')
}

function Visit() {
    // const navigate = useNavigate()
    const {token, userData} = useTokenAndUserData()
    const {visit, loading, dispatch} = useContext(VisitContext)
    const {dispatch: alertDispatch} = useContext(AlertContext)
    const ref = createRef(null)
    const [takeScreenShot] = useScreenshot({
        type: 'image/jpeg',
        quality: 1.0
    })

    const params = useParams()

    const [loadingImg, setLoadingImg] = useState(false)

    useEffect(() => {
        alertDispatch({type: 'REMOVE_ALERT'})
        dispatch({type: 'SET_LOADING'})
        const getUserData = async () => {
            const visitData = await getVisit(params.visitCode, token)
            dispatch({type: 'GET_VISIT', payload: visitData})
        }

        getUserData()
    }, [dispatch, params.visitCode, token, alertDispatch])

    const handleUseCode = async (e) => {
        e.preventDefault()

        if (params.visitCode !== '') {
            dispatch({type: 'SET_LOADING'})
            const visitData = await updateVisit(params.visitCode, {visitCodeStatus: 'USED'}, token)

            dispatch({
                type: 'GET_VISIT', payload: {
                    ...visit,
                    visitCodeValidity: visitData.visitCodeValidity,
                    visitCodeStatus: visitData.visitCodeStatus
                }
            })
            dispatch({type: 'GET_VISITS', payload: []})
            dispatch({type: 'GET_LAST_PAGE', payload: false})
            dispatch({type: 'GET_PREV_PAGE', payload: 0})
            dispatch({type: 'GET_CURR_PAGE', payload: 1})

            // navigate(`/visits`)
        }
    }

    const handleUseCodeButton = (visitCodeValidity, permissions) => {
        if (visitCodeValidity === 'ACTIVE' & validatePermissions(userData.userPermissions, 'codes')) return true
        return false
    }

    const download = (image, {name = 'cotoadmin_', extension = 'jpg'} = {}) => {
        const dateStr = getDateStr()
        // eslint-disable-next-line
        const imgName = `${name}` + `${dateStr}`
        const a = document.createElement('a')
        a.href = image
        a.download = createFileName(extension, imgName)
        a.click()
    }

    const handleShare = async (e) => {
        setLoadingImg(true)
        e.preventDefault()
        await takeScreenShot(ref.current).then(download)
        setLoadingImg(false)
    }

    const {
        visitorName,
        visitorPlates,
        startDate,
        startTime,
        endDate,
        visitCode,
        visitCodeValidity,
        comment,
        address,
    } = visit

    if (loading) {
        return <Spinner/>
    } else {

    }

    return (<>
        <div className="w-full mx-auto lg:w-10/12">
            <Ad/>
            {/*commend by Rubi for the new format*/}
            {/*<div className="mb-4">*/}
            {/*  {validateRoles(userData.permissions, 'isResident') && (*/}
            {/*    loadingImg ? */}
            {/*      <button disabled={true} className='btn btn-outline' >*/}
            {/*        <RiLoader2Fill className='mr-2'/> Descargando...*/}
            {/*      </button>*/}
            {/*    :*/}
            {/*      <button onClick={handleShare} className='btn btn-outline' >*/}
            {/*        <MdDownload className='mr-2'/> Descargar QR*/}
            {/*      </button>*/}
            {/*  )}*/}

            {/*  {false && (<img src={image} alt={'Screenshot'} />)}*/}
            {/*</div>*/}


            <div ref={ref}
                 className=" pt-4 pb-4 mb-2 md:gab-8 ">

                <div className="col-span-2 px-8 ">

                    <div className="py-5 mb-6 rounded-lg shadow-md bg-base-100 ">

                        <div className="flex align-items-center justify-center custom-card-image mb-6 md:mb-0">
                            <figure>
                                {/*commend by Rubi for the new format*/}
                                {/*<QRCodeImg visitCode={visitCode} />*/}
                                {visitCode && (
                                    <div className='stat'>
                                        <div className="text-5xl md:text-5xl stat-value">{visitCode}</div>
                                    </div>
                                )}

                            </figure>
                        </div>

                        <div>
                            <div className="grid  xl:grid-rows-1 lg:grid-rows-1 md:grid-rows-1 sd:grid-rows-1 stats">
                                {/*{{visitorTrustLevel === 'HIGH' && (*/}
                                {/*    <div className='stat'>*/}
                                {/*        <div className="stat-figure text-success">*/}
                                {/*            <FaFlagCheckered className='text-4xl md:text-4xl'/>*/}
                                {/*        </div>*/}
                                {/*        <div className="stat-title">*/}
                                {/*            Confiabilidad*/}
                                {/*        </div>*/}
                                {/*        <div className="stat-value text-2xl md:text-3xl">Alta</div>*/}
                                {/*    </div>*/}
                                {/*)}*/}

                                {/*{visitorTrustLevel === 'MEDIUM' && (*/}
                                {/*    <div className='stat'>*/}
                                {/*    <div className="stat-figure text-warning">*/}
                                {/*    <FaFlagCheckered className='text-4xl md:text-4xl' />*/}
                                {/*    </div>*/}
                                {/*    <div className="stat-title pr-2">*/}
                                {/*    Confiabilidad*/}
                                {/*    </div>*/}
                                {/*    <div className="stat-value pr-2 text-2xl md:text-3xl">Media</div>*/}
                                {/*    </div>*/}
                                {/*    )}*/}

                                {/*{visitorTrustLevel === 'LOW' && (*/}
                                {/*    <div className='stat'>*/}
                                {/*    <div className="stat-figure text-error">*/}
                                {/*    <FaFlagCheckered className='text-4xl md:text-4xl' />*/}
                                {/*    </div>*/}
                                {/*    <div className="stat-title pr-2">*/}
                                {/*    Confiabilidad*/}
                                {/*    </div>*/}
                                {/*    <div className="stat-value pr-2 text-2xl md:text-3xl">Baja</div>*/}
                                {/*    </div>*/}
                                {/*    )}}*/}

                                {visitCodeValidity === 'ACTIVE' && (
                                    <div className='stat'>
                                        <div className="stat-figure text-success">
                                            <MdOutlineQrCode2 className='text-4xl md:text-4xl'/>
                                        </div>
                                        <div className="stat-title pr-2">
                                            Código
                                        </div>
                                        <div className="stat-value pr-2 text-2xl md:text-3xl">Válido</div>
                                    </div>
                                )}

                                {visitCodeValidity === 'INACTIVE' && (
                                    <div className='stat'>
                                        <div className="stat-figure text-secondary">
                                            <MdOutlineQrCode2 className='text-4xl md:text-4xl'/>
                                        </div>
                                        <div className="stat-title pr-2">
                                            Código
                                        </div>
                                        <div className="stat-value pr-2 text-2xl md:text-3xl">Utilizado</div>
                                    </div>
                                )}

                                {visitCodeValidity === 'EXPIRED' && (
                                    <div className='stat'>
                                        <div className="stat-figure text-error">
                                            <MdOutlineQrCode2 className='text-4xl md:text-4xl'/>
                                        </div>
                                        <div className="stat-title pr-2">
                                            Código
                                        </div>
                                        <div className="stat-value pr-2 text-2xl md:text-3xl">Expirado</div>
                                    </div>
                                )}

                                {visitCodeValidity === 'FUTURE' && (
                                    <div className='stat'>
                                        <div className="stat-figure text-primary">
                                            <MdOutlineQrCode2 className='text-4xl md:text-4xl'/>
                                        </div>
                                        <div className="stat-title pr-2">
                                            Código
                                        </div>
                                        <div className="stat-value pr-2 text-2xl md:text-3xl">Fecha futura</div>
                                    </div>
                                )}

                                {visitCodeValidity === 'INVALID' && (
                                    <div className='stat'>
                                        <div className="stat-figure text-success">
                                            <MdOutlineQrCode2 className='text-4xl md:text-4xl'/>
                                        </div>
                                        <div className="stat-title pr-2">
                                            Código
                                        </div>
                                        <div className="stat-value pr-2 text-2xl md:text-3xl">Inválido</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="p-5 ">

                            <h1 className='text-3xl card-title font-semibold'>
                                {visitorName}
                            </h1>

                            <div className="flex">
                                <p className="font-semibold"> Visita: &nbsp; </p>
                                <p> {address} </p>
                            </div>

                            <div className="flex">
                                <p className="font-semibold">Fecha: &nbsp; </p>
                                <p>{startDate} at: {startTime} </p>
                            </div>

                            <div className="flex">
                                <p className="font-semibold">Vence el: &nbsp; </p>
                                <p> {endDate} - {startTime}</p>
                            </div>

                            <div className="flex">
                                <p className="font-semibold"> Placas: &nbsp;</p>
                                <p> {visitorPlates} </p>
                            </div>
                            <p>{comment}</p>

                            <div className="mt-4 card-actions">
                                {handleUseCodeButton(visitCodeValidity, userData.permissions) && (
                                    <button onClick={handleUseCode} className='btn btn-outline'>
                                        Usar Código
                                    </button>
                                )}
                            </div>
                        </div>


                    </div>

                    {/*<div*/}
                    {/*    className="py-5 mb-6 rounded-lg shadow-md bg-base-100 stats grid   md:grid-rows-1 sd:grid-rows-1">*/}

                    {/*    /!*{visitorPlates && (*!/*/}
                    {/*    /!*  <div className='stat'>*!/*/}
                    {/*    /!*    <div className="stat-title text-md">Placas</div>*!/*/}
                    {/*    /!*    <div className="text-lg stat-value">{visitorPlates}</div>*!/*/}
                    {/*    /!*  </div>*!/*/}
                    {/*    /!*)}*!/*/}
                    {/*    /!*{startDate && (*!/*/}
                    {/*    /!*  <div className='stat'>*!/*/}
                    {/*    /!*    <div className="stat-title text-md">Fecha</div>*!/*/}
                    {/*    /!*    <div className="text-lg stat-value">{startDate}</div>*!/*/}
                    {/*    /!*    <div className="text-lg stat-value">{startTime}</div>*!/*/}
                    {/*    /!*  </div>*!/*/}
                    {/*    /!*)}*!/*/}
                    {/*    /!*{visitCode && (*!/*/}
                    {/*    /!*  <div className='stat'>*!/*/}
                    {/*    /!*    <div className="stat-title text-md">Codigo</div>*!/*/}
                    {/*    /!*    <div className="text-lg stat-value">{visitCode}</div>*!/*/}
                    {/*    /!*  </div>*!/*/}
                    {/*    /!*)}*!/*/}
                    {/*</div>*/}
                </div>

            </div>


        </div>
    </>)
}

export default Visit
