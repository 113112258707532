import {useContext, useEffect, useState} from "react";
import AlertContext from "../../context/alert/AlertContext";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import FileContext from "../../context/files/FileContext";
import {useNavigate, useParams} from "react-router-dom";
import {
    addReservation, approvalReservation,
    getAllAmenities, getReservationById
} from "../../context/reservation/ReservationActions";
import dayjs from "dayjs";
import {dayIndexMap} from "../../utils/constantest";
function ReservationApproval() {

    let { id } = useParams();

    const { dispatch } = useContext( FileContext );
    const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
    const { userData } = useTokenAndUserData();

    const [reservation, setReservation] = useState(null);
    const [dayOfReservation, setDayOfReservation] = useState('');
    const [approved, setApproved] = useState(true);
    const [descriptionApproval, setDescriptionApproval] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {

            if(userData.appId){
                const res = await getReservationById(id,userData.appId);
                console.log(res);
                const selectedDate = dayjs(res.dayOfReservation);
                const selectedWeekdayName = selectedDate.day();

                // const indices = days.map(day => day.dayOfWeek);
                const foundEntry = Object.entries(dayIndexMap).find(([key, value]) => value === selectedWeekdayName);
                const [foundKey] = foundEntry;
                setDayOfReservation(foundKey.toUpperCase());
                setReservation(res);
            }
        }
        getData();
    }, [userData]);

    const handleChangeDescriptionApproval= (e) => setDescriptionApproval(e.target.value);
    const handleChangeApproved = (e) => setApproved(!approved);


    const handleApproved = async (e) => {
        e.preventDefault()

        if(descriptionApproval.length <= 0 ) {
            setAlert('Por favor ingresa todos los datos requeridos', 'error')

        } else {
            alertDispatch({ type: 'SET_LOADING' })

            const appId = userData.appId;

            const request = {
                id,
                appId,
                approvalRejectDescription:descriptionApproval,
                approved:true,
                approvedBy : userData.email,
                updatedBy : userData.email
            }
            const add = await approvalReservation(request);

            navigate(`/reservation`);
        }
    }

    const handleReject = async (e) => {
        e.preventDefault()

        if(descriptionApproval.length <= 0 ) {
            setAlert('Por favor ingresa todos los datos requeridos', 'error')

        } else {
            alertDispatch({ type: 'SET_LOADING' })

            const appId = userData.appId;

            const request = {
                id,
                appId,
                approvalRejectDescription:descriptionApproval,
                approved:false,
                approvedBy : userData.email,
                updatedBy : userData.email
            }
            const add = await approvalReservation(request);

            navigate(`/reservation`);
        }
    }

    return (
        <div className="w-full mx-auto lg:w-10/12">
            <div className="text-center hero-content">
                <h1 className='text-3xl card-title'>Aprobar Reservación </h1>
            </div>

            {reservation &&
                <form >
                    <div className='form-control'>

                        <label className='mt-4 text-lg'>
                            Amenidad :
                            <input
                                className="mt-2 w-full pr-6 bg-gray-200 input input-lg text-black"
                                id="text"
                                type='text'
                                placeholder='Fecha'
                                value={reservation.amenity.name}
                                disabled={true}
                            />
                        </label>

                        <label className='mt-4 text-lg'>
                            Solicitado por :
                            <input
                                className="mt-2 w-full pr-6 bg-gray-200 input input-lg text-black"
                                id="text"
                                type='text'
                                placeholder='solicitado por'
                                value={reservation.requestedBy}
                                disabled={true}
                            />
                        </label>

                        <label className='mt-4 text-lg'>
                            Fecha :
                            <input
                                className="mt-2 w-full pr-6 bg-gray-200 input input-lg text-black"
                                id="date"
                                type='date'
                                placeholder='Fecha'
                                value={reservation.dayOfReservation}
                                disabled={true}
                            />
                        </label>

                        <label className='mt-4 text-lg'>
                            Día :
                            <input
                                className="mt-2 w-full pr-6 bg-gray-200 input input-lg text-black"
                                id="date"
                                type='text'
                                placeholder='Dia'
                                value={dayOfReservation}
                                disabled={true}/>

                        </label>

                        <label className='mt-4 text-lg'>
                            Hora :
                            <input
                                className="mt-2 w-full pr-6 bg-gray-200 input input-lg text-black"
                                id="date"
                                type='text'
                                placeholder='Hora'
                                value={`${reservation.fromTime} - ${reservation.toTime}`}
                                disabled={true}/>
                        </label>
                        <label className='mt-4 text-lg'>
                            Descripción :
                            <input
                                className="mt-2 w-full pr-6 bg-gray-200 input input-lg text-black"
                                id="file_input"
                                type='text'
                                placeholder='Descripcion'
                                value={reservation.description}
                                disabled={true}
                            />
                        </label>

                        {/*<label className='label cursor-pointer mt-4 '>*/}
                        {/*    <span className="text-lg">Aprobar:</span>*/}
                        {/*    <input*/}
                        {/*        type='checkbox'*/}
                        {/*        className='toggle'*/}
                        {/*        defaultChecked={true}*/}
                        {/*        onChange={handleChangeApproved}*/}
                        {/*    />*/}
                        {/*</label>*/}

                        <label className='mt-4 text-lg'>
                            Comentario * :
                            <input
                                className="mt-2 w-full pr-6 bg-gray-200 input input-lg text-black"
                                id="file_input"
                                type='text'
                                placeholder='Comentario'
                                value={descriptionApproval}
                                onChange={handleChangeDescriptionApproval}
                            />
                        </label>

                        <div className="flex mt-6 card-actions justify-between">
                            <button onClick={handleApproved}  className='btn btn-outline'>
                                Aprobar
                            </button>

                            <button onClick={handleReject} className='btn btn-outline border-color-red'>
                                Rechazar
                            </button>
                        </div>
                    </div>
                </form>
            }

        </div>
    )
}

export default ReservationApproval
