import { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaTimes } from 'react-icons/fa'
import { MdOutlineQrCode2 } from 'react-icons/md'
import { HiIdentification } from 'react-icons/hi'
import { deleteVisitor } from '../../context/visitors/VisitorActions'
import {validatePermissions, validateRoles} from '../../context/login/LoginActions'
import VisitorContext from '../../context/visitors/VisitorContext'
import PropTypes from 'prop-types'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";

function VisitorItem({ visitor: { visitorId, visitorName, visitorPlates, provider } }) {
  const { token, userData } = useTokenAndUserData()
  const { visitors, dispatch } = useContext(VisitorContext)

  const navigate = useNavigate()

  // console.log(token);

  const handleDelete = async () => {

    if(await deleteVisitor(visitorId, token)) {
      dispatch({ type: 'GET_VISITORS', payload: visitors.filter((item) => item.visitorId !== visitorId ) })
    }

  }
 
  const checkEditPermissions = (permissions, provider) => {
    if(!provider) return true
    if(provider & validateRoles(permissions, 'isAdmin')) return true
    return false
  }
  
  return (
    <div className='card shadow-md compact side bg-base-100'>
      <div className="grid grid-cols-6 xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-6 flex-row items-center space-x-4 card-body">

        <Link to={`/visitors/${visitorId}/update`} className='col-span-1'>
          <div>
            <div className="rounded-full w-16 h-16">
              <HiIdentification className='text-6xl md:text-5xl' />
            </div>
          </div>
        </Link>

        <Link to={`/visitors/${visitorId}/update`} className='col-span-3'>
          <div>
            <h2 className='card-title'>
              {visitorName}
            </h2>
            {provider && (
              <h3 className='text-base-content text-opacity-40'>Proveedor</h3>
            )}
            {visitorPlates && (
              <h3 className='text-base-content text-opacity-40'>{visitorPlates}</h3>
            )}
          </div>
        </Link>

        <div className='flex flex-row gap-4'>

          {validatePermissions(userData.userPermissions, 'visitors') && (
              <>
                <div className='flex col-span-2 justify-end xl:col-span-1 lg:col-span-1 md:col-span-1'>
                  <button onClick={() => navigate(`/visit/create?visitorId=${visitorId}`)}
                          className='close tooltip rounded-full'
                          data-tip='Generar QR'>
                    <MdOutlineQrCode2 className='text-4xl md:text-3xl' color='primary '/>
                  </button>
                </div>
              </>
          )}

          {checkEditPermissions(userData.permissions, provider) && (
              <>
                {/*{validateRoles(userData.permissions, 'isResident') ? (*/}
                {/*    <div className='flex '>*/}
                {/*      <button onClick={() => navigate(`/visitors/${visitorId}/update`)}*/}
                {/*              className='edite tooltip rounded-full'*/}
                {/*              data-tip='Editar Visitante'>*/}
                {/*        <FaEdit className='text-2xl md:text-4xl sm:text-3xl' color='primary ' />*/}
                {/*      </button>*/}
                {/*    </div>*/}
                {/*) : (*/}
                {/*    <div className='flex  justify-center'>*/}
                {/*      <button onClick={() => navigate(`/visitors/${visitorId}/update`)}*/}
                {/*              className='edite tooltip rounded-full'*/}
                {/*              data-tip='Editar Visitante'>*/}
                {/*        <FaEdit className='text-2xl md:text-4xl sm:text-3xl' color='primary ' />*/}
                {/*      </button>*/}
                {/*    </div>*/}
                {/*)}*/}

                <div className='flex col-span-2 justify-end '>
                  <button onClick={handleDelete}
                          className='close tooltip rounded-full'
                          data-tip='Remover Visitante'>
                    <FaTimes className='text-4xl md:text-3xl' color='primary '/>
                  </button>
                </div>
              </>
          )}

        </div>


      </div>
    </div>
  )
}

VisitorItem.propTypes = {
  visitor: PropTypes.object.isRequired,
}

export default VisitorItem
