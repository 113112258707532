import { useState, useContext, useEffect } from 'react'
import LoginContext from '../../context/login/LoginContext'
import AlertContext from '../../context/alert/AlertContext'
import { getTempPassword, updateUserPass } from '../../context/login/LoginActions'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import Ad from "../ads/Ad";

function UpdatePass() {
  const { dispatch } = useContext(LoginContext)
  const { token, userData } = useTokenAndUserData()
  const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)

  const [userPass, setPass] = useState('')
  const [newPass, setNewPass] = useState('')
  const [confirmPass, setConfirmPass] = useState('')

  const handleChangePass = (e) => setPass(e.target.value)
  const handleChangeNewPass = (e) => setNewPass(e.target.value)
  const handleChangeConfirmPass = (e) => setConfirmPass(e.target.value)

  useEffect(() => {
    alertDispatch({ type: 'REMOVE_ALERT'})
  }, [alertDispatch])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if(userPass === '' || newPass === '' || confirmPass === '') {
      setAlert('Por favor ingresa todos los datos requeridos', 'error')
    } else {
      if (newPass !== confirmPass) {
        setAlert('Contraseña nueva diferente en campo de confirmación', 'error')
      } else {
        if(newPass.trim().length <= 5) {
          setAlert('La contraseña debe contener al menos 6 caracteres alfanumericos', 'error')
        } else {
          dispatch({ type: 'SET_LOADING' })
          const userDetails = await getTempPassword(newPass)

          const newUser = await updateUserPass(userData.userId, {
            userPass,
            newPass: userDetails.userPass.trim(),
          }, token)
          dispatch({ type: 'GET_USER', payload: newUser })

          if(Object.prototype.hasOwnProperty.call(newUser, 'userId')) {
            setPass('')
            setNewPass('')
            setConfirmPass('')

            setAlert(`Contraseña actualizada exitosamente. Se recomienda salir de la aplicación e ingresar de nuevo con su nueva contraseña.`, 'info')
          } else {
            setAlert(newUser, 'error')
          }
        }
      }
    }
  }

  return (
    <div className="w-full mx-auto lg:w-10/12">

      <Ad/>

      <div className="text-center hero-content">
        <h1 className='text-3xl card-title'>Cambiar Contraseña</h1>
      </div>

      <form onSubmit={handleSubmit}>
        <div className='form-control'>

          <label className='mt-4 text-lg'>
            Contraseña Actual:
            <input
              type='password'
              className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
              placeholder='Contraseña Actual'
              value={userPass}
              onChange={handleChangePass}
            />
          </label>

          <label className='mt-4 text-lg'>
            Nueva Contraseña:
            <input
              type='password'
              className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
              placeholder='Nueva Contraseña'
              value={newPass}
              onChange={handleChangeNewPass}
            />
          </label>

          <label className='mt-4 text-lg'>
            Confirmar Nueva Contraseña:
            <input
              type='password'
              className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
              placeholder='Confirmar Contraseña'
              value={confirmPass}
              onChange={handleChangeConfirmPass}
            />
          </label>

          <div className="mt-6 card-actions">
            <button type='submit' className='btn btn-outline'>
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default UpdatePass
