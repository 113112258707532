import { useEffect, useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import AddressContext from '../../context/address/AddressContext'
import AlertContext from '../../context/alert/AlertContext'
import { getAddress, updateAddress } from '../../context/address/AddressActions'
import Spinner from '../layout/Spinner'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import Ad from "../ads/Ad";

function UpdateAddress() {
  const { token } = useTokenAndUserData()
  const { loading, dispatch } = useContext(AddressContext)
  const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
  const navigate = useNavigate()
  const params = useParams()

  const [addressUnit, setAddressUnit] = useState('')
  const [addressBuilding, setAddressBuilding] = useState('')
  const [addressIntNum, setAddressIntNum] = useState('')
  const [addressPhone, setAddressPhone] = useState('')
  const [comment, setComment] = useState('')

  useEffect(() => {
    alertDispatch({ type: 'REMOVE_ALERT'})
    dispatch({ type: 'SET_LOADING' })
    const getAddressData = async () => {

      const addressData = await getAddress(params.addressId, token)

      dispatch({ type: 'GET_ADDRESS', payload: addressData })

      setAddressUnit(addressData.addressUnit || '')
      setAddressBuilding(addressData.addressBuilding || '')
      setAddressIntNum(addressData.addressIntNum || '')
      setAddressPhone(addressData.addressPhone || '')
      setComment(addressData.comment || '')
    }

    getAddressData()
  }, [dispatch, params.addressId, token, alertDispatch])

  const handleChangeStreet = (e) => setAddressUnit(e.target.value)
  const handleChangeExtNum = (e) => setAddressBuilding(e.target.value)
  const handleChangeIntNum = (e) => setAddressIntNum(e.target.value)
  const handleChangePhone = (e) => setAddressPhone(e.target.value)
  const handleChangeComment = (e) => setComment(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if(addressUnit === '') {
      setAlert('Por favor ingresa todos los datos requeridos', 'error')
    } else {
      dispatch({ type: 'SET_LOADING' })
      const address = await updateAddress(params.addressId, {
        addressUnit,
        addressBuilding,
        addressIntNum,
        addressPhone,
        comment
      }, token)
      
      dispatch({ type: 'GET_ADDRESS', payload: address })
      dispatch({ type: 'GET_ADDRESSES', payload: []})
      dispatch({ type: 'GET_LAST_PAGE', payload: false })
      dispatch({ type: 'GET_PREV_PAGE', payload: 0 })
      dispatch({ type: 'GET_CURR_PAGE', payload: 1 })

      setAddressUnit('')
      setAddressBuilding('')
      setAddressIntNum('')
      setAddressPhone('')
      setComment('')

      navigate(`/address/${address.addressId}`)
    }
  }

  if (loading) {
    return <Spinner />
  } else {
    return (
      <div className="w-full mx-auto lg:w-10/12">

        <Ad/>

        <div className="text-center hero-content">
          <h1 className='text-3xl card-title'>Actualizar Unidad</h1>
        </div>

        <form onSubmit={handleSubmit}>
          <div className='form-control'>

            <label className='mt-4 text-lg'>
              Unidad * :
              <input
                type='text'
                className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                placeholder='Unidad'
                value={addressUnit}
                onChange={handleChangeStreet}
              />
            </label>

            <label className='mt-4 text-lg'>
              Edificio:
              <input
                type='text'
                className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                placeholder='Edificio'
                value={addressBuilding}
                onChange={handleChangeExtNum}
              />
            </label>

            <label className='mt-4 text-lg'>
              Número Interior:
              <input
                type='text'
                className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                placeholder='Número Interior'
                value={addressIntNum}
                onChange={handleChangeIntNum}
              />
            </label>

            <label className='mt-4 text-lg'>
              Télefono:
              <input
                type='text'
                className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                placeholder='Télefono'
                value={addressPhone}
                onChange={handleChangePhone}
              />
            </label>

            <label className='mt-4 text-lg'>
              Comentario:
              <textarea
                type='text'
                className='mt-2 w-full h-40 pr-4 bg-gray-200 input input-lg text-black'
                placeholder='Comentario'
                value={comment}
                onChange={handleChangeComment}
              />
            </label>

            <div className="mt-6 card-actions">
              <button type='submit' className='btn btn-outline'>
                Guardar
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default UpdateAddress
