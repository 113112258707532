import axios from 'axios'
// const visitServiceUrl = process.env.REACT_APP_VISIT_SERVICE || 'http://70.35.204.186:3000/pjd-cotoadmin-visit'
const host = process.env.REACT_APP_HOST || window.location.origin
const visitServiceUrl = `${host}${process.env.REACT_APP_VISIT_SERVICE || '/visit'}`

// const visitServiceUrl = `http://localhost:8080/visit`

const db = axios.create({
  baseURL: visitServiceUrl,
})

// Search/Get Visitors on the DB
export const searchVisitors = async ({ keywords = '', pageSize = 10, page = 0, token }) => {
  try{

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    const params = new URLSearchParams({
      keywords,
      pageSize,
      page,
    })

    const response = await db.get(`/api/v1/visitors?${params}`, config)

    return response.data

  }catch (error){
    console.log(error);
  }

}

// Get visitor details
export const getVisitor = async (visitorId, token) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const response = await db.get(`/api/v1/visitors/${visitorId}`, config)
  return response.data.data
}

// Create new visitor
export const addVisitor = async (data, token) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json'
    }
  }

  const response = await db.post(`/api/v1/visitors`, data, config)
  return response.data.data
}

// Update visitor
export const updateVisitor = async (visitorId, data, token) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const response = await db.patch(`/api/v1/visitors/${visitorId}`, data, config)
  return response.data.data
}

// Delete visitor
export const deleteVisitor = async (visitorId, token) => {

  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  if(window.confirm('¿Seguro que deseas remover al visitante?')) {    
    const response = await db.delete(`/api/v1/visitors/${visitorId}`, config)
    return response.data.data
  }
  return false
}


export const checkVisitHealth = async () => {
  try {
    return await db.get(`/health`);
  } catch (error) {
    return error;
  }
}
