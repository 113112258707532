
import {useContext, useEffect, useState} from "react";
import dayjs from "dayjs";
import {Link, useNavigate} from "react-router-dom";
import {BsFillHouseDoorFill} from "react-icons/bs";

function RecurrentCollectCard ({ recurrentJob } ) {

    const [colorConcept, setColorConcept] = useState('');
    const [operatorConcept, setOperatorConcept] = useState('');
    const [units, setUnits] = useState(0);
    const [date, setDate] = useState();

    useEffect(() => {

        setDate(dayjs(recurrentJob.date).format('YYYY-MM-DD'))

        if(recurrentJob.concept.operation === 'SUM'){
            setColorConcept('text-green-600');
            setOperatorConcept('+');
        }else if(recurrentJob.concept.operation === 'SUB'){
            setColorConcept('text-red-600')
            setOperatorConcept('-');
        }

        const unitsJobs = recurrentJob.unitJob.map(unitJob => unitJob.unitId);

        setUnits(unitsJobs.length);

    }, []);

    return (
        <>
            <div className='card shadow-md compact side bg-base-100'>

                <div className="grid grid-cols-6 xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-6 flex-row items-center space-x-4 card-body">

                    <Link to={`/recurrent_collects/${recurrentJob.id}/update`} className='col-span-1'>
                        <div>
                            <div className="rounded-full w-16 h-16">
                                <BsFillHouseDoorFill className='text-6xl md:text-5xl' />
                            </div>
                        </div>
                    </Link>

                    <Link to={`/recurrent_collects/${recurrentJob.id}/update`} className='col-span-3'>
                        <div>
                            <h2 className='card-title'>
                                {recurrentJob.title ? recurrentJob.title.slice(0,15)+"..."  : recurrentJob.concept.description.slice(0,20)}
                            </h2>
                            <h3 className='text-base-content text-opacity-40'>{recurrentJob.cron.description}</h3>
                            <h3 className='text-base-content text-opacity-40'> {date} </h3>
                            {/*<h3 className='text-base-content text-opacity-60'> {recurrentJob.updatedBy} </h3>*/}

                        </div>
                    </Link>


                    <div className={"w-full flex flex-col"}>

                        <div className={"w-full"}>

                            <p className='text-base-content text-opacity-50'>Cantidad </p>

                            <div className={`text-base-content text-opacity-50 flex flex-row ${colorConcept}`}>
                                {operatorConcept} {recurrentJob.quantity}
                            </div>

                        </div>

                        <div className={""}>

                            <p className='text-base-content text-opacity-40'>Unidades</p>
                            <div className={`text-base-content text-opacity-40 `}>
                                {units}
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default RecurrentCollectCard;
