import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import VisitorContext from '../../context/visitors/VisitorContext'
import VisitContext from '../../context/visits/VisitContext'
import AlertContext from '../../context/alert/AlertContext'
import { addVisitor } from '../../context/visitors/VisitorActions'
import { searchAddresses } from '../../context/address/AddressActions'
import {validatePermissions, validateRoles} from '../../context/login/LoginActions'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import Ad from "../ads/Ad";

// const trustLevels = [
//   {
//     level: 'HIGH',
//     levelName: 'Alto',
//     description: 'Visitante muy confianble'
//   },
//   {
//     level: 'MEDIUM',
//     levelName: 'Medio',
//     description: 'Visitante confiable'
//   },
//   {
//     level: 'LOW',
//     levelName: 'Bajo',
//     description: 'Visitante no confiable'
//   }
// ]

function AddVisitor() {
  const { token, userData } = useTokenAndUserData()
  const { addresses, provider, dispatch } = useContext(VisitorContext)
  const { dispatch: visitDispatch } = useContext(VisitContext)
  const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)

  const navigate = useNavigate()

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatValue(value, first) {
    const prefix = first ? '' : ' - '
    if(value === null || value === '' || !value)
    {
      return ''
    }
    return `${prefix}${value}`
  }

  useEffect(() => {
    alertDispatch({ type: 'REMOVE_ALERT'})
    dispatch({ type: 'SET_LOADING' })

    const getAddressesData = async () => {


      const addressData = await searchAddresses({keywords: '', pageSize: 2000, token })
      dispatch({ type: 'GET_ADDRESSES', payload: addressData.data })

      const isAdmin = validateRoles(userData.permissions, 'isAdmin')
      dispatch({ type: 'SET_PROVIDER', payload: isAdmin })
    }

    getAddressesData()
  }, [dispatch, token, userData.permissions, alertDispatch])

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-');
  }

  function formatTimestamp(date, time) {
    return `${date} ${time}:00`;
  }

  function formatTime(date) {
    return [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
    ].join(':');
  }

  const [visitorName, setName] = useState('')
  const [visitorPlates, setPlates] = useState('')
  // const [visitorTrustLevel, setTrustLevel] = useState('')
  const visitorTrustLevel = 'MEDIUM'
  const [description, setDescription] = useState('')
  const [recurrent, setRecurrent] = useState(false)
  // const [provider] = useState(isAdmin)
  const [blacklist, setBlackList] = useState(false)
  const [blacklistComment, setComment] = useState('')
  const [createQR, setQR] = useState(false)

  const [addressId, setAddressId] = useState('')
  const [address, setAddress] = useState('')
  const [date, setDate] = useState(formatDate(new Date()))
  const [time, setTime] = useState(formatTime(new Date()))
  const [commentVisit, setCommentVisit] = useState('')
  const minDate = formatDate(new Date())

  const handleChangeName = (e) => setName(e.target.value)
  const handleChangePlates = (e) => setPlates(e.target.value)
  // const handleChangeTrustLevel = (e) => setTrustLevel(e.target.value)
  const handleChangeDescription = (e) => setDescription(e.target.value)
  const handleChangeRecurrent = (e) => setRecurrent(!recurrent)
  // const handleChangeProvider = (e) => setProvider(!provider)
  const handleChangeBlackList = (e) => {
    if(blacklist) {
      setComment('')
    }
    setBlackList(!blacklist)
  }
  const handleChangeComment = (e) => setComment(e.target.value)
  const handleCreateQR = (e) => {
    if(createQR) {
      setAddressId('')
      setAddress('')
      setDate(formatDate(new Date()))
      setTime(formatTime(new Date()))
      setCommentVisit('')
    } else {
      setAddressId(addresses[0] ? addresses[0].addressId : '')
      let item = addresses[0] ? addresses[0] : { addressUnit: '', addressBuilding: '', addressIntNum: '' }
      let address = `${formatValue(item.addressUnit, true)}${formatValue(item.addressBuilding)}${formatValue(item.addressIntNum)}`
      setAddress(address)
    }
    setQR(!createQR) 
  }

  const handleChangeAddressId = (e) => {
    const idSelected = parseInt(e.target.value, 10)
    setAddressId(idSelected)
    const item = (addresses.find((address) => address.addressId = idSelected))
    let address = `${formatValue(item.addressUnit, true)}${formatValue(item.addressBuilding)}${formatValue(item.addressIntNum)}`
    setAddress(address)
  }

  const handleChangeDate = (e) => {
    if (e.target.value === '') {
      setDate(formatDate(new Date()))
    } else {
      setDate(e.target.value)
    }
  }

  const handleChangeTime = (e) => {
    if (e.target.value === '') {
      setTime(formatTime(new Date()))
    } else {
      setTime(e.target.value)
    }
  }

  const handleChangeCommentVisit = (e) => setCommentVisit(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if(visitorName === '') {
      setAlert('Por favor ingresa todos los datos requeridos', 'error')
    } else {
      dispatch({ type: 'SET_LOADING' })
      const newVisitor = await addVisitor({
        residentName: userData.name,
        visitorName,
        visitorPlates,
        visitorTrustLevel,
        description,
        recurrent,
        provider,
        blacklist,
        blacklistComment,
        visit: !createQR ? undefined : {
          addressId,
          address,
          date: formatTimestamp(date, time),
          comment: commentVisit
        }
      }, token)

      dispatch({ type: 'ADD_VISITOR', payload: newVisitor })
      dispatch({ type: 'GET_VISITORS', payload: []})
      dispatch({ type: 'GET_LAST_PAGE', payload: false })
      dispatch({ type: 'GET_PREV_PAGE', payload: 0 })
      dispatch({ type: 'GET_CURR_PAGE', payload: 1 })

      if(createQR) {
        visitDispatch({ type: 'GET_VISITS', payload: []})
        visitDispatch({ type: 'GET_LAST_PAGE', payload: false })
        visitDispatch({ type: 'GET_PREV_PAGE', payload: 0 })
        visitDispatch({ type: 'GET_CURR_PAGE', payload: 1 })
      }

      setName('')
      setPlates('')
      // setTrustLevel('')
      setComment('')
      setDescription('')
      setRecurrent(false)
      // setProvider(false)
      setBlackList(false)
      setAddressId('')
      setAddress('')
      setDate(formatDate(new Date()))
      setTime(formatTime(new Date()))
      setCommentVisit('')

      const redirectUrl = createQR ? `/visit/${newVisitor.visit.visitCode}` : `/visitors/${newVisitor.visitorId}`

      navigate(redirectUrl)
    }
  }

  return (
    <div className="w-full mx-auto lg:w-10/12">

      <Ad/>

      <div className="text-center hero-content">
        <h1 className='text-3xl card-title'>{provider ? ('Agregar Proveedor') : ('Alta Visitante')}</h1>
      </div>

      <form onSubmit={handleSubmit}>
        <div className='form-control'>

          <label className='mt-4 text-lg'>
            Nombre * :
            <input
              type='text'
              className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
              placeholder='Nombre'
              value={visitorName}
              onChange={handleChangeName}
            />
          </label>

          {validatePermissions(userData.userPermissions, 'visitors') && (
            <label className='mt-4 text-lg'>
              Placas:
              <input
                type='text'
                className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                placeholder='Placas'
                value={visitorPlates}
                onChange={handleChangePlates}
              />
            </label>
          )}

          {/* <label className='mt-4 text-lg lg:text-md md:text-md'>
            Nivel de confiabilidad* :
            <select
              type='select'
              className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
              placeholder='Nivel de confiabilidad'
              value={visitorTrustLevel}
              onChange={handleChangeTrustLevel}
            >
              <option disabled defaultValue key='' value=''>Selecciona una opción</option>
              {trustLevels.map((level) => (
                <option key={level.level} value={level.level}>
                  {level.levelName}
                </option>
              ))}
            </select>
          </label> */}

          <label className='mt-4 text-lg'>
            Descripción:
            <textarea
              className='mt-2 w-full h-40 pr-4 bg-gray-200 input input-lg text-black'
              placeholder='Descripción'
              value={description}
              onChange={handleChangeDescription}
            />
          </label>

          {validatePermissions(userData.userPermissions, 'visitors') && (
            <label className="label cursor-pointer mt-4 ">
              <span className="text-lg">Visitante Recurrente:</span> 
              <input 
                type='checkbox'
                className='toggle'
                value={recurrent}
                onChange={handleChangeRecurrent}
              />
            </label>
          )}

          {/* <label className="label cursor-pointer mt-4 ">
            <span className="text-lg">Proveedor:</span> 
            <input 
              type='checkbox'
              className='toggle'
              value={provider}
              onChange={handleChangeProvider}
            />
          </label> */}

          {validatePermissions(userData.userPermissions, 'BLACKLIST') && (
            <label className="label cursor-pointer mt-4 ">
              <span className="text-lg">Black List:</span> 
              <input 
                type='checkbox'
                className='toggle'
                value={blacklist}
                onChange={handleChangeBlackList}
              />
            </label>
          )}

          {blacklist && (
            <label className='mt-4 text-lg'>
              Comentario:
              <textarea
                className='mt-2 w-full h-40 pr-4 bg-gray-200 input input-lg text-black'
                placeholder='Comentario Black List'
                value={blacklistComment}
                onChange={handleChangeComment}
              />
            </label>
          )}

          {validatePermissions(userData.userPermissions, 'visitors') && (
            <label className="label cursor-pointer mt-4 ">
              <span className="text-lg">Agendar Visita:</span> 
              <input 
                type='checkbox'
                className='toggle'
                value={createQR}
                onChange={handleCreateQR}
              />
            </label>
          )}

          {createQR && (
            <>
              <label className='mt-4 text-lg lg:text-md md:text-md'>
                Selecciona una Unidad * :
                <select
                  type='select'
                  className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                  placeholder='Unidad'
                  value={addressId}
                  onChange={handleChangeAddressId}
                >
                  <option disabled defaultValue key='' value=''>Selecciona una opción</option>
                  {addresses.map((address) => (
                    <option key={address.addressId} value={address.addressId}>
                      {address.addressUnit && (address.addressUnit)} {address.addressBuilding && (`- ${address.addressBuilding}`)} {address.addressIntNum && (`- ${address.addressIntNum}`)}
                    </option>
                  ))}
                </select>
              </label>

              <label className='mt-4 text-lg'>
                Día de la visita * :
                <input
                  type='date'
                  className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                  placeholder='Día de la visita'
                  value={date}
                  min={minDate}
                  onChange={handleChangeDate}
                />
              </label>

              <label className='mt-4 text-lg'>
                Hora de la visita * :
                <input
                  type='time'
                  className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                  placeholder='Hora de la visita'
                  value={time}
                  onChange={handleChangeTime}
                />
              </label>

              <label className='mt-4 text-lg'>
                Comentario:
                <textarea

                  className='mt-2 w-full h-40 pr-4 bg-gray-200 input input-lg text-black'
                  placeholder='Comentario'
                  value={commentVisit}
                  onChange={handleChangeCommentVisit}
                />
              </label>
            </>
          )}

          <div className="mt-6 card-actions">
            <button type='submit' className='btn btn-outline'>
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddVisitor
