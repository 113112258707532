import {createContext, useEffect, useReducer} from 'react'
import AdsReducer from "./AdsReducer";
import {getAllAds} from "./AdsActions";
import useToken from "../login/useToken";
import {getSettings} from "../login/LoginActions";

const AdContext = createContext()

export const AdsProvider = ({ children }) => {

    const initialState = {
        ads: [],
        tags: [],
        ad: {},
        tag: {},
        loading: false,
        isDataLoaded: false
    }

    const [state, dispatch] = useReducer(AdsReducer, initialState)
    const {ads} = state;
    const { userData, token } = useToken();


    function setAds(ads){
        dispatch({type: 'GET_ADS', payload: ads});
    }

    useEffect(  () => {


        const getAds = async () => {

            if(userData.appId && token){
                const tenantDetails = await getSettings();
                const setting = tenantDetails.settings;

                if(setting.AD) {
                    console.log("have ads");
                    const ads = await getAllAds(userData.appId, token);
                    setAds(ads);
                }else{
                    console.log("NOT have ads");
                    setAds([]);
                }
            }
        }

       getAds();

    }, [userData]);



    return <AdContext.Provider value={{
        ads,
        ...state,
        dispatch,
    }}>
        {children}
    </AdContext.Provider>
}

export default AdContext
