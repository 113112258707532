const FileReducer = (state, action) =>{
    switch(action.type) {

        case 'GET_FILES':
            return {
                ...state,
                files: action.payload,
                loading: false,
                isDataLoaded: true
            }
        case 'GET_FILE':
            return {
                ...state,
                file: action.payload,
                loading: false,
            }
        case 'ADD_FILE':
            return {
                ...state,
                newFile: action.payload,
                loading: false,
            }
        case 'SET_IS_UP':
            return {
                ...state,
                isUpFile: action.payload
            }
        default:
            return state
    }
}

export default FileReducer
