import React, {useContext, useEffect, useState} from "react";
import AlertContext from "../../context/alert/AlertContext";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import FileContext from "../../context/files/FileContext";
import {useNavigate} from "react-router-dom";
import {
    addReservation, getAllActiveAmenities,
    getReservationByAmenityId,
    getSchedulerByAmenityId
} from "../../context/reservation/ReservationActions";
import dayjs from "dayjs";
import {dayIndexMap} from "../../utils/constantest";
import Ad from "../ads/Ad";

function SelectReservationDay({ option, setSchedule, activeIndex, setActiveIndex, index, isDisabled }) {

    const handleChangeHour = (e) => {
        e.preventDefault();
        setSchedule(e.target.value);
        setActiveIndex(index);
    };

    return (
        <div className={`flex `} >
            <button disabled={isDisabled} className={`text-sm btn btn-outline ${activeIndex === index ? 'bg-gray-800 text-white' : ''}`} value={option[0] + " - " + option[1]} onClick={handleChangeHour}>
                {option && option[0] + " - " + option[1]}
            </button>
        </div>
    );
}


function AddReservation() {

    const { dispatch } = useContext( FileContext );
    const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
    const { userData, token } = useTokenAndUserData();
    const [days, setDays] = useState([]);
    const [hours, setHours] = useState([]);
    const [minDate, setMinDate] = useState('');
    const disabledDays = ['Monday', 'Wednesday', 'Friday', 'Saturday'];

    const [description, setDescription] = useState('');
    const [dayOfReservation, setDayOfReservation] = useState('');
    const [amenities, setAmenities] = useState([]);
    const [amenityId, setAmenityId] = useState(0);
    const [dayOfWeek, setDayOfWeek] = useState('');
    const [schedule, setSchedule] = useState([]);
    const [hourSelected, setHourSelected] = useState({});
    const [bookedHours, setBookedHours] = useState([]);

    const [activeIndex, setActiveIndex] = useState(-1);
    const [hourS, setHourS] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            if(userData.appId){
                // console.log(userData);
                const amenitiesFound = await getAllActiveAmenities(userData.appId, token);
                setAmenities(amenitiesFound);
            }
        }
        getData();
    }, [userData]);

    useEffect(() => {
        const getData = async () => {

            if(amenityId > 0 ){
                const shc = await getSchedulerByAmenityId(amenityId);
                const d = shc.map(s => s.day);
                setDays(d.map(day => day.dayOfWeek));
                setSchedule(shc);
                setMinDate(dayjs().format('YYYY-MM-DD'));
            }
        }
        getData();

    }, [amenityId]);

    useEffect(() => {
        if(dayOfWeek.toUpperCase().length > 0){
            const sh = schedule.find(s => s.day.dayOfWeek === dayOfWeek.toUpperCase());
            // console.log(sh);
            setHours(generateTimeIntervals(sh.startTime, sh.endTime, (sh.amenity.maxHours * 60)));

        }
    }, [dayOfWeek, dayOfReservation]);


    useEffect(() => {
        const getData = async () => {
            const appId = userData.appId;
            if(amenityId > 0 && appId && dayOfReservation.length > 0 && hours.length > 0 ) {
                const reservations = await getReservationByAmenityId(amenityId, appId, dayOfReservation);
                if(reservations.length > 0){
                    const bh = reservations.map(b => b.scheduleTime);
                    setBookedHours(bh);

                }
            }
        }
        getData();
    }, [amenityId, dayOfReservation, hours]);


    useEffect(() => {
        if(hourS.length > 0){
            let h = hourS.split("-");
            // console.log({
            //     startTime: h[0].trim(),
            //     endTime: h[1].trim()
            // });

            setHourSelected({
                startTime: h[0].trim(),
                endTime: h[1].trim()
            })
        }

    }, [hourS]);

    const handleChangeDescription= (e) => setDescription(e.target.value);
    const handleChangeAmenityId = (e) => {
        setDayOfWeek("");
        setHourS([]);
        setDayOfReservation('');
        setBookedHours([]);
        setHours([]);

        setAmenityId(e.target.value)
    };

    const handleChangeDayOfReservation = (e) => {
        setDayOfWeek("");
        setHourS([]);
        setDayOfReservation('');
        setBookedHours([]);
        setHours([]);

        const selectedDate = dayjs(e.target.value);
        const selectedWeekdayName = selectedDate.day();
        // const indices = days.map(day => day.dayOfWeek);
        const foundEntry = Object.entries(dayIndexMap).find(([key, value]) => value === selectedWeekdayName);
        let [foundKey] = foundEntry;
        foundKey = foundKey.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        // console.log(foundKey);
        if (!days.includes(foundKey.toUpperCase())) {
            e.preventDefault();
            return;
        }

        const day = selectedDate.format('YYYY-MM-DD');
        setDayOfReservation(day);
        setDayOfWeek(foundKey.toUpperCase())
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        if(amenityId === 0 || dayOfWeek.length <= 0 || hourSelected.startTime.length <= 0 || hourSelected.endTime.length <= 0) {
            setAlert('Por favor ingresa todos los datos requeridos', 'error')

        } else {
            alertDispatch({ type: 'SET_LOADING' })

            const appId = userData.appId;

            const creationRequest = {
                description,
                appId,
                amenityId : amenityId,
                dayOfWeek : dayOfWeek,
                startTime: hourSelected.startTime,
                endTime: hourSelected.endTime,
                dayOfReservation:dayOfReservation,
                userRequestedId:userData.userId,
                requestedBy: userData.email,
                createBy : userData.email,
                updatedBy : userData.email
            }
            // console.log(creationRequest);
            const add = await addReservation(creationRequest);

            dispatch({ type: 'GET_FILES', payload: []});

            navigate(`/reservation`);
        }
    }

    function generateTimeIntervals(startTime, endTime, interval) {
        const intervals = [];
        const start = new Date(`1970-01-01T${startTime}`);
        const end = new Date(`1970-01-01T${endTime}`);

        while (start < end) {
            // Calculate the next end time within the specified range
            const nextEnd = new Date(start.getTime() + interval * 60 * 1000);

            if (nextEnd > end) {
                nextEnd.setTime(end.getTime());
            }

            intervals.push([
                start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                nextEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
            ]);

            start.setTime(nextEnd.getTime());
        }

        return intervals;
    }


    return (

        <div className="w-full mx-auto lg:w-10/12">
            <Ad/>
            <div className="text-center hero-content">
                <h1 className='text-3xl card-title'>Agregar Reservación</h1>
            </div>

            <form onSubmit={handleSubmit}>
                <div className='form-control'>

                    <label className='mt-4 text-lg'>
                        Amenidad * :
                        <select
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Tipo de concepto'
                            onChange={handleChangeAmenityId}
                        >
                            <option defaultValue key='' value=''>Selecciona una opción</option>
                            {amenities.map((type) => (
                                <option key={type.id} value={type.id}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                    </label>

                    <label className='mt-4 text-md'>
                        Días disponibles:

                        <div className='mt-4 text-md flex flex-row gap-2 text-gray-600	'>

                            {days.map((day) =>
                                <p key={day}>
                                    {day + " -"}
                                </p>
                            )}
                        </div>
                    </label>

                    <label className='mt-4 text-lg'>
                        Fecha * :
                        <input
                            className="mt-2 w-full pr-6 bg-gray-200 input input-lg text-black"
                            id="date"
                            type='date'
                            placeholder='Fecha'
                            value={dayOfReservation}
                            min={minDate}
                            disabled={disabledDays.includes(dayOfReservation)}
                            onChange={handleChangeDayOfReservation} />
                    </label>

                    <label className='mt-4 text-lg'>
                        Día * :
                        <input
                            className="mt-2 w-full pr-6 bg-gray-200 input input-lg text-black"
                            id="date"
                            type='text'
                            placeholder='Día'
                            value={dayOfWeek}
                            disabled={true}/>

                    </label>

                    <div className="flex flex-wrap gap-3 mt-4">
                        {hours && hours.map((h, index) => (
                            <SelectReservationDay
                                key={index}
                                index={index}
                                option={h}
                                isDisabled={bookedHours.includes(h[0] + ' - ' + h[1])}
                                setSchedule={setHourS}
                                activeIndex={activeIndex}
                                setActiveIndex={setActiveIndex}
                            />
                        ))}
                    </div>

                    <label className='mt-4 text-lg'>
                        Descripción :
                        <input
                            className="mt-2 w-full pr-6 bg-gray-200 input input-lg text-black"
                            id="file_input"
                            type='text'
                            placeholder='Descripción'
                            onChange={handleChangeDescription} />

                    </label>

                    <div className="mt-6 card-actions">
                        <button type='submit' className='btn btn-outline'>
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddReservation
