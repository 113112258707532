import { createContext, useReducer } from 'react'
import collectReducer from "./CollectReducer";

const CollectContext = createContext()

export const CollectProvider = ({ children }) => {

    const initialState = {
        collects: [],
        collect: {},
        newCollect: {},
        balance: {},
        balances: [],
        concepts: [],
        loading: false,
    }

    const [state, dispatch] = useReducer(collectReducer, initialState)

    return <CollectContext.Provider value={{
        ...state,
        dispatch,
    }}>
        {children}
    </CollectContext.Provider>
}

export default CollectContext
