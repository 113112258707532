import {FaCloudDownloadAlt, FaFile, FaStar, FaTimes} from "react-icons/fa";
import {Link} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import {validatePermissions, validateRoles} from "../../context/login/LoginActions";
import {downloadFile, removeFile, searchAllFiles} from "../../context/files/FileActions";
import FileContext from "../../context/files/FileContext";

function FileCard ( { file } ) {

    const { userData, token } = useTokenAndUserData();
    const [toUrl, setToUrl] = useState('');
    const [colorType, setColorType] = useState('');
    const { dispatch } = useContext( FileContext );
    const [appName, setAppName] = useState('');


    useEffect(() => {

        if(validateRoles(userData.permissions, 'isResident')){
            setToUrl(`/files/${file.id}`)
        }else {
            setToUrl(`/files/update/${file.id}`)
        }
        if(userData.app.appName){
            setAppName(userData.app.appName.replace(" ", ""))
        }

    }, [userData]);

    const onClickDownload = async () => {
        const response  = await downloadFile(file.fileName, appName, token);

        if (response.status === 200) {
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = file.fileName;
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }

    const handleDelete = async () => {
        const response = await removeFile(file.fileName, userData.appId, userData.email, appName, token)
        const files = await searchAllFiles(userData.appId, token);
        dispatch({ type: 'GET_FILES', payload: files })
    }

    return (
        <>


            <div className='card shadow-md compact side bg-base-100'>
                <div className="grid grid-cols-5 xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-6 flex-row items-center space-x-4 card-body">

                    <Link to={toUrl} className='col-span-1'>
                        <div>
                            <div className="rounded-full w-16 h-16">
                                <FaFile className='text-5xl' />
                            </div>
                        </div>
                    </Link>

                    <Link to={toUrl} className='col-span-3'>
                        <div>
                            <div className="grid grid-flow-row-dense grid-cols-2 gap-2" >

                                <div className={"grow-0 row-span-2"}>
                                    <h2 className='card-title'>
                                        {file.isPriority &&
                                            <div>
                                                <FaStar className='text-xs dark:text-slate-300' />
                                            </div>

                                        }
                                        {file.title}
                                    </h2>

                                </div>

                                <div className="flex justify-between col-span-1">
                                    <span className={`p-1 text-[10px] grow-0 uppercase tracking-wider rounded-lg bg-opacity-50 dark:text-slate-300 ${colorType}`}>
                                        {file.startDate}
                                    </span>
                                </div>
                            </div>

                            <div className={`text-gray-500 dark:text-slate-400 mr-2 col-span-3`}>

                                {file.description.slice(0,50) + '...'}
                                {/*<p>*/}
                                {/*    {file.updatedBy}*/}
                                {/*</p>*/}
                            </div>

                        </div>
                    </Link>

                    <div>
                        {validatePermissions(userData.userPermissions, 'FILES') &&
                            <div className=" text-sm font-medium text-black dark:text-slate-300">
                                <button
                                    className='close tooltip rounded-full'
                                    data-tip='Remover'
                                    onClick={handleDelete}
                                >
                                    <FaTimes className='text-2xl md:text-3xl object-none object-right-top' color='primary '/>
                                </button>
                            </div>
                        }


                        <div className=" text-sm font-medium text-black dark:text-slate-300" >

                            <button
                                className='close tooltip rounded-full'
                                data-tip='Descargar'
                                onClick={onClickDownload}
                            >
                                <FaCloudDownloadAlt className='text-2xl md:text-3xl object-none object-right-top' color='primary '/>
                            </button>

                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default FileCard;
