const tokenReducer =  (state, action) => {
    switch (action.type) {

        case 'SET_TOKEN_AND_USER_DATA' :
            return {
                ...state,
                userData: action.payload.userData,
                token: action.payload.token,
                expiry : action.payload.expiry,
                isDataLoaded: true
            }

        default:
            return state;
    }
}
export default tokenReducer;
