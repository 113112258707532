
import {BsFillHouseDoorFill} from "react-icons/bs";
import {Link} from "react-router-dom";

function SurveyCard ( { survey } ) {

    return (
        <>
            <div className='card shadow-md compact side bg-base-100'>
                <Link to={`/survey/questions/${survey.id}`} className="w-100%">

                    <div className="grid grid-cols-6 xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-6 flex-row items-center space-x-4 card-body">


                        <div>
                            <div className="rounded-full w-16 h-16 col-span-1">
                                <BsFillHouseDoorFill className='text-6xl md:text-5xl' />
                            </div>
                        </div>

                        <div className={"col-span-3"}>
                            <h2 className='card-title'>
                                {survey.title}
                            </h2>
                            <h3 className='text-base-content text-opacity-40'>{survey.startDay}</h3>
                            <h3 className='text-base-content text-opacity-40'> {survey.finalDay} </h3>

                        </div>

                    </div>
                </Link>
            </div>

        </>
    )
}

export default SurveyCard;
