import VisitorSearch from './VisitorSearch'
import VisitorResults from './VisitorResults'

function Users() {
  return (
    <>
      <VisitorSearch />
      <VisitorResults />
    </>
  )
}

export default Users