import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import {useContext, useEffect, useRef, useState} from "react";
import AlertContext from "../../context/alert/AlertContext";
import {useNavigate, useParams} from "react-router-dom";
import CollectContext from "../../context/collect/CollectContext";
import {
    getTransactionByUnitIdAndByIdAndByAppId
} from "../../context/collect/CollectActions";
import LoginContext from "../../context/login/LoginContext";
import Ad from "../ads/Ad";
import dayjs from "dayjs";


function CollectInfo() {

    const { dispatch} = useContext( CollectContext );
    const { dispatch : loginDispatch} = useContext(LoginContext)
    const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
    const { userData, token } = useTokenAndUserData();

    const [unitName, setUnitName] = useState('');
    const [concept, setConcept] = useState('');
    const [description, setDescription] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [balance, setBalance] = useState(0);
    const [date, setDate] = useState('');
    const [reference, setReference] = useState('');
    const [colorConcept, setColorConcept] = useState('');
    const [operatorConcept, setOperatorConcept] = useState('');
    const [updatedBy, setUpdatedBy] = useState('');


    let { id, nameUnit} = useParams();

    useEffect(() => {

        alertDispatch({ type: 'REMOVE_ALERT'})
        dispatch({ type: 'SET_LOADING' })

        const getData = async () => {

            if(userData.appId){
                let transactionData = await getTransactionByUnitIdAndByIdAndByAppId(nameUnit,userData.appId, id, token);

                setUnitName(transactionData.unitName);
                setDescription(transactionData.description);
                setConcept(transactionData.concept.description);
                setReference(transactionData.reference || "");
                setQuantity(transactionData.quantity);
                setBalance(transactionData.balanceQuantity);
                setDate(transactionData.transactionDate);
                setUpdatedBy(transactionData.updatedBy);


                if(transactionData.concept.operation === 'SUM'){
                    setColorConcept('text-green-600');
                    setOperatorConcept('+');

                }else if(transactionData.concept.operation === 'SUB'){
                    setColorConcept('text-red-600')
                    setOperatorConcept('-');
                }

            }
        }

        getData();

    }, [userData, id, nameUnit]);



    return (
        <div className="w-full mx-auto lg:w-10/12">
            <Ad/>

            <div className="text-center hero-content">
                <h1 className='text-3xl card-title'>Cobranza</h1>
            </div>

            <form >
                <div className='form-control'>


                    <label className='mt-4 text-lg'>
                        Unidad :
                        <input
                            type="text"
                            disabled={true}
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Unidad'
                            value={unitName}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Concepto:
                        <input
                            type="text"
                            disabled={true}
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Concepto'
                            value={concept}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Descripción:
                        <textarea
                            className='mt-2 w-full h-40 pr-4 bg-gray-200 input input-lg text-black'
                            placeholder='Descripción'
                            disabled={true}
                            value={description}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Referencia:
                        <input
                            type='text'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Referencia'
                            disabled={true}
                            value={reference}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Cantidad:
                        <input
                            type='text'
                            className={`mt-2 w-full pr-6 bg-gray-200 input input-lg text-black ${colorConcept}`}
                            placeholder='Cantidad'
                            disabled={true}
                            value={ operatorConcept + " "+ quantity}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Fecha:
                        <input
                            type="text"
                            disabled={true}
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Saldo'
                            value={date}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Balance:
                        <input
                            type="text"
                            disabled={true}
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Balance '
                            value={balance}
                        />
                    </label>

                    {updatedBy &&
                        <label className='mt-4 text-lg'>
                            Actualizado:
                            <input
                                type="text"
                                disabled={true}
                                className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                                placeholder='Actualizado '
                                value={updatedBy}
                            />
                        </label>
                    }


                </div>
            </form>
        </div>
    )
}

export default CollectInfo
