import { useState, useContext, useEffect } from 'react'
import AlertContext from '../../context/alert/AlertContext'

function Reservations() {
  const [keywords, setKeywords] = useState('')

  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })
  
  const { dispatch: alertDispatch } = useContext(AlertContext)

  useEffect(() => {
    alertDispatch({ type: 'REMOVE_ALERT'})
  }, [alertDispatch])

  const handleChange = (e) => setKeywords(e.target.value)

  return (
    <div className="w-full mx-auto lg:w-10/12">
      <div className="text-center hero-content pt-0 pb-0">
        <h1 className='text-3xl card-title'>Reservaciones</h1>
      </div>
      {/*<div className='grid gird-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid_cols-2 mb-8 gap-8'>*/}
      <div className='mb-8 gap-8 mt-9'>
        <div>
          <form>
            <div className='form-control'>
              <div className='relative'>
                <input
                  type='text'
                  className='w-full pr-4 bg-gray-200 text-black input input-md xl:input-lg lg:input-lg md:input-lg'
                  placeholder='Reservación'
                  value={keywords}
                  onChange={handleChange}
                />
                <button className='absolute top-0 right-0 rounded-l-none w-36 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg'>
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className='w-full flex justify-center mb-8 gap-8 mt-9'>
        <button className='mr-6 w-10/12 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg'>
          Crear Reservación
        </button>
      </div>
    </div>
  )
}

export default Reservations
