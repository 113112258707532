import { createContext, useReducer } from 'react'
import visitorReducer from './VisitorReducer'

const VisitorContext = createContext()

export const VisitorProvider = ({ children }) => {
  const initialState = {
    visitors: [],
    currentPage: 1,
    previousPage: 0,
    lastPage: false,
    provider: false,
    visitor: {},
    newVisitor: {},
    addresses: [],
    loading: false,
    isUpVisitor : true
  }

  const [state, dispatch] = useReducer(visitorReducer, initialState)

  return <VisitorContext.Provider value={{
    ...state,
    dispatch,
  }}>
    {children}
  </VisitorContext.Provider>
}

export default VisitorContext
