import {useContext, useEffect, useState} from "react";
import AlertContext from "../../context/alert/AlertContext";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import FileContext from "../../context/files/FileContext";
import {useNavigate, useParams} from "react-router-dom";
import {addAmenity, getAllDays, getAllHours, getAmenityById, updateAmenity} from "../../context/amenity/AmenityActions";
import Select from 'react-select';
import TimeRangePicker from "./TimePicker";
import SelectColor from "./SelectColor";
import {colors} from "../../utils/constantest";
function UpdateAmenity() {

    let { id } = useParams();

    const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
    const { userData, token } = useTokenAndUserData();
    const [selectedDays, setSelectedDays] = useState(null);
    const [name, setName] = useState('');
    const [needApproval, setNeedApproval] = useState(false);
    const [isAvailable, setIsAvailable] = useState(false);
    const [maxHour, setMaxHour] = useState(0);
    const [daysOfWeek, setDaysOfWeek] = useState([]);
    const [schedule, setSchedule] = useState([]);
    const [hours, setHours] = useState([]);
    const [color, setColor] = useState(colors[2]);
    const [daySchedule, setDaySchedule] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            if(userData.appId){
                const amenity = await getAmenityById(userData.appId, id, token);
                setName(amenity.name)

                const d = amenity.schedule.map(s => {
                    return {
                        value: s.day.dayOfWeek,
                        label: s.day.dayOfWeek
                    }
                });

                const sch = amenity.schedule.map(s => {
                    return {
                        day: s.day.dayOfWeek,
                        start: s.start,
                        end : s.end
                    }
                });

                setSchedule(sch);
                setSelectedDays(d);
                setNeedApproval(amenity.needApproval);
                setIsAvailable(amenity.isAvailable);
                setMaxHour(amenity.maxHours)
                setColor(colors.find(c => c.name === amenity.color));
            }
        }
        getData();
    }, [userData]);

    useEffect(() => {
        const getData = async () => {
            const days = await getAllDays();
            const d = days.map(day => {
                return {
                    value: day.dayOfWeek,
                    label: day.dayOfWeek
                }
            });
            setDaysOfWeek(d);


            const h = await getAllHours();
            setHours(h);
        }
        getData();
    }, []);

    const handleChangeName = (e) => setName(e.target.value);
    const handleChangeMaxHour = (e) => {
        const value = e.target.value;
        if(value <= 0 || value >= 11){
            e.target.value = "";
        }
        console.log("")
        setMaxHour(e.target.value)
    };
    const handleChangeNeedApproval = (e) => setNeedApproval(!needApproval);
    const handleChangeIsAvailable = (e) => setIsAvailable(!isAvailable);

    const handleSubmit = async (e) => {
        e.preventDefault()

        if(name.length <= 0 || selectedDays.length <= 0 ) {
            setAlert('Por favor ingresa todos los datos requeridos', 'error')

        } else {
            alertDispatch({ type: 'SET_LOADING' })

            let daysSchedule = daySchedule;

            if (selectedDays.length !== daySchedule.length) {
                daysSchedule = daySchedule.filter(schedule =>
                    selectedDays.some(day => day.value === schedule.day)
                );
            }
            // console.log(daysSchedule);

            const appId = userData.appId;
            const updateRequest = {
                idAmenity: parseInt(id),
                name,
                needApproval,
                appId,
                isAvailable,
                maxHours: maxHour,
                color:color.name,
                daysOfWeek : daysSchedule,
                updatedBy : userData.email
            }

            // console.log(updateRequest);

            const add = await updateAmenity(updateRequest,token);

            // dispatch({ type: 'ADD_FILE', payload: newFile });
            // dispatch({ type: 'GET_FILES', payload: []});
            //
            navigate(`/amenity`);
        }
    }

    return (
        <div className="w-full mx-auto lg:w-10/12">
            <div className="text-center hero-content">
                <h1 className='text-3xl card-title'>Actualizar Amenidad</h1>
            </div>

            <form onSubmit={handleSubmit}>
                <div className='form-control'>

                    <label className='mt-4 text-lg'>
                        Nombre * :
                        <input
                            className="mt-2 w-full pr-6 bg-gray-200 input input-lg text-black"
                            id="file_input"
                            type='text'
                            placeholder='Nombre'
                            value={name}
                            onChange={handleChangeName} />

                    </label>

                    <label className="mt-4 text-lg">
                        Dias de la semana * :
                        <div className="form-multiselect">
                            {daysOfWeek &&
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    name="dias semana"
                                    value={selectedDays}
                                    options={daysOfWeek}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={setSelectedDays}
                                />
                            }
                        </div>
                    </label>

                    {selectedDays && selectedDays.map(day =>
                        <TimeRangePicker key={day.value}
                                         day={day.value}
                                         start={schedule.find(s => s.day === day.value) ? schedule.find(s => s.day === day.value).start : "09:00 AM" }
                                         end={schedule.find(s => s.day === day.value)? schedule.find(s => s.day === day.value).end : "10:00 PM" }
                                         setHourSelect={setDaySchedule}
                                         hourSelected={daySchedule}
                                         hours={hours}/>
                    )}

                    <label className='label cursor-pointer mt-4 '>
                        <span className="text-lg">Disponible:</span>
                        <input
                            type='checkbox'
                            className='toggle'
                            value={isAvailable}
                            checked={isAvailable}
                            onChange={handleChangeIsAvailable}
                        />
                    </label>

                    <SelectColor color={color}  setColor={setColor}/>

                    <label className='label cursor-pointer mt-4 '>
                        <span className="text-lg">Aprobacion:</span>
                        <input
                            type='checkbox'
                            className='toggle'
                            value={needApproval}
                            checked={needApproval}
                            onChange={handleChangeNeedApproval}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Horas permitidas:
                        <input
                            className="mt-2 w-full pr-6 bg-gray-200 input input-lg text-black"
                            type='number'
                            min="1"
                            max="8"
                            placeholder='Hora maxima'
                            value={maxHour}
                            onChange={handleChangeMaxHour} />

                    </label>

                    <div className="mt-6 card-actions">
                        <button type='submit' className='btn btn-outline'>
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default UpdateAmenity
