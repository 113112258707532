import {useContext, useEffect, useRef, useState} from "react";
import AlertContext from "../../context/alert/AlertContext";
import {useNavigate, useParams} from "react-router-dom";
import NoticeContext from "../../context/notice/NoticeContext";
import { findNoticeById, searchAllTypes, updateNotice} from "../../context/notice/NoticeActions";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import Ad from "../ads/Ad";

function UpdateNotice() {
    const { dispatch, types } = useContext( NoticeContext );
    const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
    const { userData, token  } = useTokenAndUserData();
    const [userEmail, setUserEmail] = useState('');
    const params = useParams()


    const [title, setTitle] = useState('');
    const [dateOfPublication, setDateOfPublication] = useState('');
    const [dateOfExpiration, setDateOfExpiration] = useState('');
    const [isPriority, setIsPriority] = useState(false);
    const [type, setType] = useState(0);
    const [typeId, setTypeID] = useState(0);
    const [description, setDescription] = useState('');
    const enableRef = useRef();

    const handleChangeTitle = (e) => setTitle(e.target.value);
    const handleChangeDateOfPublication = (e) => setDateOfPublication(e.target.value);
    const handleChangeDateOfExpiration = (e) => setDateOfExpiration(e.target.value);
    const handleChangeIsPriority = (e) => setIsPriority(!isPriority);
    const handleChangeDescription = (e) => setDescription(e.target.value);
    const handleChangeCategory = (e) => {
        setTypeID(e.target.value)
    };

    const navigate = useNavigate()

    useEffect(() => {

        alertDispatch({ type: 'REMOVE_ALERT'})
        dispatch({ type: 'SET_LOADING' })
        const getNoticeData = async () => {
            let typesOfCategories = await searchAllTypes( token );
            dispatch({type: 'GET_TYPES', payload: typesOfCategories});

        }
        getNoticeData();

    }, [dispatch, alertDispatch]);

    useEffect(() => {

        alertDispatch({ type: 'REMOVE_ALERT'})
        dispatch({ type: 'SET_LOADING' })
        const getNoticeData = async () => {

            const noticeData = await findNoticeById(params.id, token);
            dispatch({ type: 'GET_NOTICES', payload: noticeData })

            setTitle(noticeData.title || '')
            setDateOfPublication(noticeData.announcementStartDate || '')
            setDateOfExpiration(noticeData.announcementFinalDate || '')
            setDescription(noticeData.description || '');
            setType(noticeData.type);
            setTypeID(noticeData.type.typeId);

            if(enableRef.current){
                setIsPriority(noticeData.isPriority);
                enableRef.current.checked  = noticeData.isPriority;

            }
        }

        setUserEmail(userData.email);
        getNoticeData()

    }, [dispatch, params.id, alertDispatch])

    const handleSubmit = async (e) => {
        e.preventDefault()

        if(title === '' ) {
            setAlert('Por favor ingresa todos los datos requeridos', 'error')

        } else {

            alertDispatch({ type: 'SET_LOADING' })

            const newNotice = await updateNotice(params.id,{
                title,
                description,
                isPriority,
                dateOfPublication,
                dateOfExpiration,
                typeId,
                updatedBy : userEmail
            }, token);

            dispatch({ type: 'ADD_NOTICE', payload: newNotice.response });
            dispatch({ type: 'GET_NOTICES', payload: []});

            setTitle('');
            setDateOfPublication('');
            setDateOfExpiration('');
            setIsPriority(false);
            setDescription('');
            navigate(`/home`);
        }
    }

    return (
        <div className="w-full mx-auto lg:w-10/12">
            <Ad/>

            <div className="text-center hero-content">
                <h1 className='text-3xl card-title'>Actualizar Aviso</h1>
            </div>

            <form onSubmit={handleSubmit} >
                <div className='form-control'>

                    <label className='mt-4 text-lg'>
                        Titulo * :
                        <input
                            type='text'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Titulo'
                            value={title}
                            onChange={handleChangeTitle}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Fecha de publicación :
                        <input
                            type='date'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='fecha de publicación'
                            value={dateOfPublication}
                            onChange={handleChangeDateOfPublication}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Fecha de expiración:
                        <input
                            type='date'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Fecha de expiración'
                            value={dateOfExpiration}
                            onChange={handleChangeDateOfExpiration}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Categoria:
                        <select
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Categoria del anuncio'
                            value={type}
                            onChange={handleChangeCategory}
                        >
                            <option defaultValue key={type.typeName} value={type.id}>{type.typeName}</option>
                            {types.map((t) => (
                                <option key={t.typeName} value={t.id}>
                                    {t.typeName}
                                </option>
                            ))}

                        </select>
                    </label>

                    <label className='label cursor-pointer mt-4 '>
                        <span className="text-lg">Destacar:</span>
                        <input
                            type='checkbox'
                            className='toggle'
                            value={isPriority}
                            ref={enableRef}
                            onChange={handleChangeIsPriority}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Descripción:
                        <textarea

                            className='mt-2 w-full h-40 pr-4 bg-gray-200 input input-lg text-black'
                            placeholder='Descripción'
                            value={description}
                            onChange={handleChangeDescription}
                        />
                    </label>

                    <div className="mt-6 card-actions">
                        <button type='submit' className='btn btn-outline'>
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default UpdateNotice
