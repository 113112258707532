const recurrentCollectReducer = (state, action) =>{
    switch(action.type) {

        case 'GET__RECURRENT_COLLECTS':
            return {
                ...state,
                recurrentCollects: action.payload,
                loading: false,
            }
        case 'GET_RECURRENT_COLLECT':
            return {
                ...state,
                recurrentCollect: action.payload,
                loading: false,
            }
        case 'GET_CRON':
            return {
                ...state,
                crons: action.payload,
                loading: false,
            }
        case 'ADD_RECURRENT_COLLECT':
            return {
                ...state,
                newRecurrentCollect: action.payload,
                loading: false,
            }

        case 'SET_IS_UP':
            return {
                ...state,
                isUpRecurrent : action.payload
            }
        default:
            return state
    }
}

export default recurrentCollectReducer
