import {useContext, useEffect, useState} from "react";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import AlertContext from "../../context/alert/AlertContext";
import {useNavigate} from "react-router-dom";
import CollectContext from "../../context/collect/CollectContext";
import {allCollectsByUnitIdAndAppId, searchAllCollectsByAppId} from "../../context/collect/CollectActions";
import {searchAddresses} from "../../context/address/AddressActions";
import {formatValue} from "../../utils/utilsFunctions";
import LoginContext from "../../context/login/LoginContext";

function CollectSearch() {
    // const [keywords, setKeywords] = useState('')

    const { token, userData} = useTokenAndUserData()
    // const { dispatch, visitors: visitorsList, currentPage, previousPage, lastPage } = useContext(CollectContext)
    const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
    const { dispatch} = useContext( CollectContext );
    const { address, dispatch : loginDispatch} = useContext(LoginContext)

    const navigate = useNavigate()
    //
    // useEffect(() => {
    //     alertDispatch({ type: 'REMOVE_ALERT'})
    //     dispatch({ type: 'SET_LOADING' })
    //     const getVisitorsData = async () => {
    //         const visitors = await searchVisitors({ keywords, page: currentPage, token })
    //         if (!visitors.paging.hasMore) {
    //             dispatch({ type: 'GET_LAST_PAGE', payload: true })
    //             // return;
    //         }
    //         dispatch({ type: 'GET_PREV_PAGE', payload: currentPage })
    //         dispatch({ type: 'GET_VISITORS', payload: [...visitorsList, ...visitors.data] })
    //         // setKeywords('')
    //     }
    //
    //     if (!lastPage && previousPage !== currentPage) {
    //         getVisitorsData()
    //     }
    // }, [dispatch, token, alertDispatch, visitorsList, currentPage, previousPage, lastPage, keywords])

    // const handleChange = (e) => setKeywords(e.target.value)

    const [unitId, setUnitId] = useState(0);
    const [appId, setAppId] = useState(0);

    useEffect(() => {

        alertDispatch({ type: 'REMOVE_ALERT'})
        dispatch({ type: 'SET_LOADING' })
        const getCollectsData = async () => {

            if(userData.appId){
                const collectsData = await searchAllCollectsByAppId(userData.appId,token);
                dispatch({ type: 'GET_COLLECTS', payload: collectsData })
            }
            // setKeywords('')
        }

        getCollectsData();

    }, [dispatch, alertDispatch, userData])

    const handleChangeUnit = async (e) => {

        e.preventDefault()
        const collects = await allCollectsByUnitIdAndAppId(e.target.value, appId, token);

        if(Object.prototype.hasOwnProperty.call(collects, 'httpStatus', )) {
            setAlert(collects.details, 'error')
        }
        dispatch({ type: 'GET_COLLECTS', payload: collects })
    }

    return (
        <div className="w-full mx-auto lg:w-10/12">
            <div className="text-center hero-content pt-0 pb-0">
                <h1 className='text-3xl card-title'>Transacciones</h1>
            </div>

            <div className='mb-8 gap-8 mt-9'>
                <div>

                    <div className='form-control'>
                        <label className='mt-4 text-lg'>
                            Buscar por unidad :

                            <select
                                className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                                placeholder='Unidad'
                                value={unitId}
                                onChange={handleChangeUnit}
                            >
                                <option defaultValue key='' value=''>Selecciona una Unidad</option>

                                {address.map((ads) => (
                                    <option key={ads.value} value={ads.value}>
                                        {ads.label}
                                    </option>
                                ))}

                            </select>
                        </label>
                    </div>

                </div>
            </div>

            <div className='w-full flex justify-center mb-8 gap-8 mt-9'>
                <button onClick={() => navigate(`/payments/create`)} className='mr-6 w-10/12 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg'>
                    Agregar Transacccion
                </button>

            </div>
        </div>
    )
}

export default CollectSearch
