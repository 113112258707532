import {useState, useEffect, useContext} from 'react'
import { validateToken } from './LoginActions'
import LoginContext from './LoginContext'
const keyToken = process.env.REACT_APP_SESSION_KEY || 'pjd_jwtoken'

export default function useToken() {
    const getToken = () => {

        const tokenString = localStorage.getItem(keyToken)
        const userToken = JSON.parse(tokenString)

        if(!userToken) { return null }
        if (Object.prototype.hasOwnProperty.call(userToken, 'expiry')) {
            if(new Date().getTime() > userToken.expiry) {
                localStorage.removeItem(keyToken)
                return null
            }
        }
        return userToken.value
    }


    // const getUser = async () => {
    //   const tokenString = localStorage.getItem(keyToken)
    //   const userToken = JSON.parse(tokenString)
    //   if (userToken) {
    //     const getUserData = async () => {
    //       const userData = await validateToken({ token: userToken, type: 'token' })
    //       return userData || {}
    //     }

    //     return await getUserData()
    //   }
    //   return {}
    // }

    const { dispatch } = useContext(LoginContext)
    const [token, setToken] = useState(getToken())
    const [userData, setUserData] = useState({permissions: []})

    useEffect(() => {

        const tokenString = localStorage.getItem(keyToken)
        const userToken = JSON.parse(tokenString)
        if(!userToken) { return null }
        if (Object.prototype.hasOwnProperty.call(userToken, 'expiry')) {
            if(new Date().getTime() > userToken.expiry) {
                localStorage.removeItem(keyToken)
                return null
            }
        }

        if (userToken.value) {
            const getUserData = async () => {

                const userData = await validateToken({ token: userToken.value, type: 'token' })
                // console.log(userData);

                setUserData(userData)
                dispatch({ type: 'AUTH_USER', payload: userData })
                dispatch({ type: 'APP_SETTINGS', payload: userData.app || { settings: {} } })
                return userData || {}
            }

            return getUserData()
        }
        return {}

    }, [setUserData, dispatch])

    const saveToken = (userToken) => {
        let expiry = new Date().getTime() + (4 * 1000 * 60 * 60)
        const data = {
            value: userToken,
            expiry,
        }
        localStorage.setItem(keyToken, JSON.stringify(data))
        setToken(userToken)
    }

    return {
        setToken: saveToken,
        token,
        userData
    }

}
