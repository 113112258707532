import {useRef, useContext, useEffect, useState} from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { FaUser } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import LoginContext from '../../context/login/LoginContext'
import {checkUserHealth, validatePermissions, validateRol} from '../../context/login/LoginActions'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import RecurrentCollectContext from "../../context/RecurrentCollect/RecurrentCollectContext";
import {checkHealthCollect} from "../../context/collect/CollectActions";
import dayjs from "dayjs";
import {checkNoticeHealth} from "../../context/notice/NoticeActions";
import NoticeContext from "../../context/notice/NoticeContext";
import {checkVisitHealth} from "../../context/visitors/VisitorActions";
import VisitorContext from "../../context/visitors/VisitorContext";
import {checkFileHealth} from "../../context/files/FileActions";
import FileContext from "../../context/files/FileContext";
const keyToken = process.env.REACT_APP_SESSION_KEY || 'pjd_jwtoken'

function Navbar({ title }) {
    const { dispatch, settings } = useContext(LoginContext);
    const { dispatch : collectDispatch} = useContext( RecurrentCollectContext );
    const { dispatch : noticeDispatch} = useContext( NoticeContext );
    const { dispatch : visitorDispatch} = useContext(VisitorContext);
    const { dispatch : userDispatch} = useContext(LoginContext);
    const { dispatch : fileDispatch } = useContext( FileContext );
    const [upVisitor, setUpVisitor] = useState(false);
    const [upNotice, setUpNotice] = useState(false);
    const [upRecurrent, setUpRecurrent] = useState(false);
    const [upUserAddress, setUpUser] = useState(false);
    const [upFile, setUpFile] = useState(false);
    const dropdown = useRef()
    const { userData, token } = useTokenAndUserData();

    const handleSingOut = () => {
        localStorage.removeItem(keyToken)
        dispatch({ type: 'LOGOUT_USER', payload: {} })
        window.location = '/'
    }

    const checkIsUpCollect = async () => {
        const status = await checkHealthCollect();
        const isUpStatus = status?.status === 200;
        setUpRecurrent(isUpStatus);
        collectDispatch({ type: 'SET_IS_UP', payload: isUpStatus });
    }

    const checkIsUpNotice = async () => {
        const status = await checkNoticeHealth();
        const isUpStatus  = status?.status === 200;
        setUpNotice(isUpStatus)
        noticeDispatch({ type: 'SET_IS_UP', payload: isUpStatus });
    }

    const checkIsUpVisitor = async () => {
        const status = await checkVisitHealth();
        const isUpStatus  = status?.status === 200;
        setUpVisitor(isUpStatus);
        visitorDispatch({ type: 'SET_IS_UP', payload: isUpStatus });
    }

    const checkIsUpUser = async () => {
        const status = await checkUserHealth();
        const isUpStatus  = status?.status === 200;
        setUpUser(isUpStatus);
        userDispatch({ type: 'SET_IS_UP', payload: isUpStatus });
    }

    const checkIsUpFile = async () => {
        const status = await checkFileHealth();
        const isUpStatus  = status?.status === 200;
        setUpFile(isUpStatus)
        fileDispatch({ type: 'SET_IS_UP', payload: isUpStatus });
    }

    const handleClick = () => {
        checkIsUpNotice();
        checkIsUpCollect();
        checkIsUpVisitor();
        checkIsUpUser();
        checkIsUpFile();

        dropdown.current.classList.toggle('dropdown-open')
        document.activeElement.blur()
    }

    return (
        <nav className='navbar mb-8 shadow-lg bg-neutral text-neutral-content'>
            <div className="navbar-start">
                {token && (
                    <div className="dropdown" ref={dropdown}>
                        <label tabIndex="0" className="btn btn-ghost btn-circle" onClick={handleClick}>
                            <GiHamburgerMenu className="h-5 w-5">
                                <path strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M4 6h16M4 12h16M4 18h7" />
                            </GiHamburgerMenu>
                        </label>
                        <ul tabIndex="0" onClick={handleClick} className="menu bg-neutral text-neutral-content dropdown-content lg:menu-normal bg-base-100 w-56 p-2 rounded-box">
                            {userData.name && (
                                <li>
                                    <Link to='/password' className=''>
                                        <FaUser className='' />{userData.name}
                                    </Link>
                                </li>
                            )}

                            {validatePermissions(userData.userPermissions, 'codes') && (
                                <li>
                                    {upVisitor && upUserAddress ?
                                        <Link to='/visits' className=''>
                                            Accesos
                                        </Link>
                                        :
                                        <p className='text-gray-500 cursor-no-drop' >
                                            Accesos
                                        </p>
                                    }
                                </li>
                            )}

                            {validatePermissions(userData.userPermissions, 'AMENITY') && (
                                <li>
                                    {/*{upFile ?*/}
                                    <Link to='/amenity' className=''>
                                        Amenidades
                                    </Link>
                                    {/*:*/}
                                    {/*<p className='text-gray-500 cursor-no-drop' >*/}
                                    {/*    Documentos*/}
                                    {/*</p>*/}
                                    {/*}*/}
                                </li>

                            )}

                            <li>
                                <Link to='/home' className=''>
                                    Avisos
                                </Link>
                            </li>

                            {/*<li>*/}
                            {/*    <Link to='/ads/create' className=''>*/}
                            {/*        Anuncios*/}
                            {/*    </Link>*/}
                            {/*</li>*/}


                            {/*<li>*/}
                            {/*    <Link to='/payments' className=''>*/}
                            {/*        Pagos*/}
                            {/*    </Link>*/}
                            {/*</li>*/}

                            {validatePermissions(userData.userPermissions, 'PAYMENTS') && (
                                <li>
                                    {upRecurrent ?
                                        <Link to='/recurrent_collects' className=''>
                                            Cobranza recurrente
                                        </Link>
                                        :
                                        <p className='text-gray-500 cursor-no-drop' >
                                            Cobranza recurrente
                                        </p>
                                    }
                                </li>
                            )}

                            <li>
                                {upFile ?
                                    <Link to='/files' className=''>
                                        Documentos
                                    </Link>
                                    :
                                    <p className='text-gray-500 cursor-no-drop' >
                                        Documentos
                                    </p>
                                }
                            </li>

                                {/*<li>*/}
                                {/*    /!*{upFile ?*!/*/}
                                {/*    <Link to='/survey' className=''>*/}
                                {/*        Encuestas*/}
                                {/*    </Link>*/}
                                {/*    /!*:*!/*/}
                                {/*    /!*<p className='text-gray-500 cursor-no-drop' >*!/*/}
                                {/*    /!*    Documentos*!/*/}
                                {/*    /!*</p>*!/*/}
                                {/*    /!*}*!/*/}
                                {/*</li>*/}


                            {validatePermissions(userData.userPermissions, 'REPORTS') && (
                                <li>
                                    {upRecurrent ?
                                        <Link to='/reports' className=''>
                                            Reportes
                                        </Link>
                                        :
                                        <p className='text-gray-500 cursor-no-drop' >
                                            Reportes
                                        </p>
                                    }
                                </li>
                            )}

                            {validatePermissions(userData.userPermissions, 'RESERVATION') && (
                                <li>
                                    {/*{upFile ?*/}
                                    <Link to='/reservation' className=''>
                                        Reservaciones
                                    </Link>
                                    {/*:*/}
                                    {/*<p className='text-gray-500 cursor-no-drop' >*/}
                                    {/*    Documentos*/}
                                    {/*</p>*/}
                                    {/*}*/}
                                </li>
                            )}




                            {/*{settings.RESERVATION && (*/}
                            {/*    <li>*/}
                            {/*        <Link to='/reservations' className=''>*/}
                            {/*            Reservaciones*/}
                            {/*        </Link>*/}
                            {/*    </li>*/}
                            {/*)}*/}



                            {/* {validateRoles(userData.permissions, 'users') && (
                <li>
                  <Link to='/users/create' className=''>
                    Crear Usuario
                  </Link>
                </li>
              )} */}

                            {validatePermissions(userData.userPermissions, 'unit') && (
                                <li>
                                    {upUserAddress ?
                                        <Link to='/addresses' className=''>
                                            Unidades
                                        </Link>
                                        :
                                        <p className='text-gray-500 cursor-no-drop' >
                                            Unidades
                                        </p>
                                    }
                                </li>


                            )}

                            {validatePermissions(userData.userPermissions, 'users') && (
                                <li>
                                    {upUserAddress ?
                                        <Link to='/users' className=''>
                                            Usuarios
                                        </Link>
                                        :
                                        <p className='text-gray-500 cursor-no-drop' >
                                            Usuarios
                                        </p>
                                    }
                                </li>
                            )}

                            {/* {validateRoles(userData.permissions, 'addresses') && (
                <li>
                  <Link to='/address/create' className=''>
                    Agregar Dirección
                  </Link>
                </li>
              )} */}

                            {validatePermissions(userData.userPermissions, 'visitors') && (
                                <li>
                                    {upVisitor ?
                                        <Link to='/visitors' className=''>
                                            Visitantes
                                        </Link>
                                     :
                                        <p className='text-gray-500 cursor-no-drop' >
                                            Visitantes
                                        </p>
                                    }
                                </li>
                            )}

                            {/* {!validateRoles(userData.permissions, 'isResident') && (
                <li>
                  <Link to='/password' className=''>
                    Cambiar Contraseña
                  </Link>
                </li>
              )} */}

                            <li className='bg-white h-0.5 mb-1 mt-1'></li>

                            <li>
                                <Link to='/about' className='' >
                                    Acerca de CotoAdmin
                                </Link>
                            </li>

                            <li>
                                <a className='pt-4 pb-4' href='https://pjdevelopers.com/cotoadmin/avisoprivacidad.html' target="_blank" rel="noreferrer">
                                    Aviso de Privacidad
                                </a>
                            </li>

                            <li>
                                <Link to='/help' className='' >
                                    Ayuda
                                </Link>
                            </li>

                            <li>
                                <a className='' href='https://pjdevelopers.com/cotoadmin/quieroanunciarme.html' target="_blank" rel="noreferrer" >
                                    Quiero anunciarme
                                </a>
                            </li>

                            <li>
                                <Link className='' to='/' onClick={handleSingOut}>
                                    Salir
                                </Link>
                            </li>

                            {/* {validateRoles(userData.permissions, 'visitors') && (
                <li>
                  <Link to='/visitors/create' className=''>
                    Agregar Visitante
                  </Link>
                </li>
              )}
              {validateRoles(userData.permissions, 'searchVisit') && (
                <li>
                  <Link to='/visits' className=''>
                    Accesos
                  </Link>
                </li>
              )}
              {validateRoles(userData.permissions, 'createVisit') && (
                <li>
                  <Link to='/visit/create' className=''>
                    Agendar Visita
                  </Link>
                </li>
              )}
              {!validateRoles(userData.permissions, 'isGuard') && (
                <li>
                  <Link to='/password' className=''>
                    Cambiar Contraseña
                  </Link>
                </li>
              )} */}
                        </ul>
                    </div>
                )}
            </div>

            <div className="navbar-center">
                <label className='text-lg font-bold align-middle'>
                    {title}
                </label>
            </div>

            <div className="navbar-end"></div>
        </nav>
    )
}

Navbar.defaultProps = {
    title: 'CotoAdmin v2.0.0'
}

Navbar.propTypes = {
    title: PropTypes.string,
}

export default Navbar
