import axios from 'axios'
// const userServiceUrl = process.env.REACT_APP_USER_SERVICE || 'http://70.35.204.186:3000/pjd-cotoadmin-user'
const host = process.env.REACT_APP_HOST || window.location.origin
const userServiceUrl = `${host}${process.env.REACT_APP_USER_SERVICE || '/user'}`

// const userServiceUrl = `http://localhost:3004/user`

const db = axios.create({
  baseURL: userServiceUrl,
})

// Search/Get Addresses on the DB
export const searchAddresses = async ({ keywords = '', pageSize = 25, page = 1, token }) => {

  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const params = new URLSearchParams({
    keywords,
    pageSize,
    page,
  })

  const response = await db.get(`/api/v1/addresses?${params}`, config)
  return response.data
}

// Get address details
export const getAddress = async (addressId, token) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const response = await db.get(`/api/v1/addresses/${addressId}`, config)
  return response.data.data

}
export const countAddress = async (appId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const response = await db.get(`/api/v1/addresses/count/${appId}`, config)
  // console.log(response.data);
  return response.data.data
}

// Create new address
export const addAddress = async (data, token) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json'
    }
  }

  const response = await db.post(`/api/v1/addresses`, data, config)
  return response.data.data
}

// Update address
export const updateAddress = async (addressId, data, token) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const response = await db.patch(`/api/v1/addresses/${addressId}`, data, config)
  return response.data.data
}

// Delete address
export const deleteAddress = async (addressId, token) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  if(window.confirm('¿Seguro que deseas eliminar esta unidad?')) {
    const response = await db.delete(`/api/v1/addresses/${addressId}`, config)
    return response.data.data
  }
  return false
}





