
import {useContext, useEffect} from "react";
import {checkNoticeHealth, searchNotices} from "../../context/notice/NoticeActions";
import NoticeContext from "../../context/notice/NoticeContext";
import AlertContext from "../../context/alert/AlertContext";
import NoticeRow from "./NoticeRow";
import NoticeCard from "./NoticeCard";
import Spinner from "../layout/Spinner";
import {validatePermissions} from "../../context/login/LoginActions";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import user from "../users/User";


function NoticeResults() {

    const { dispatch, announcements, loading, isUpNotice } = useContext( NoticeContext );
    const {  dispatch: alertDispatch, setAlert } = useContext(AlertContext);
    const { userData, token  } = useTokenAndUserData();

    useEffect( () => {
        const checkIsUpNotice = async () => {
            const collectStatus = await checkNoticeHealth();
            const isUp  = collectStatus?.status === 200;
            dispatch({ type: 'SET_IS_UP', payload: isUp });
        }
        checkIsUpNotice();

    }, [])

    useEffect(() => {

        alertDispatch({ type: 'REMOVE_ALERT'})
        dispatch({ type: 'SET_LOADING' })

        const getAddressesData = async () => {
            if(userData.appId && token ){
                const notices = await searchNotices(userData.appId, token);
                dispatch({ type: 'GET_NOTICES', payload: notices })
            }
        }

        if(isUpNotice){
            getAddressesData();
        }

    }, [dispatch, alertDispatch, userData])


    if(!loading) {
        return (<div className="p-2 h-screen ">

                {announcements.length > 0 ?
                    <>
                        <div className="overflow-auto rounded-lg shadow hidden md:block relative">
                            <table className="w-full">
                                <thead className="bg-gray-50 border-b-2 border-gray-200 dark:bg-slate-800 ">
                                <tr>
                                    <th className="w-20 p-3 text-lg font-semibold tracking-wide text-left dark:border-slate-600 "></th>
                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Fecha</th>
                                    <th className="p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Títulos</th>
                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Categoria</th>

                                    {validatePermissions(userData.userPermissions, 'NOTICE') &&
                                        <th className="w-32 p-3 text-sm font-semibold tracking-wide text-left dark:text-white dark:border-slate-600"></th>
                                    }
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-100 relative ">

                                {announcements.map(notice => ( <NoticeRow key={notice.id} notice={notice}/>))}

                                </tbody>
                            </table>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:hidden">
                            {announcements.map(notice => ( <NoticeCard key={notice.id} notice={notice}/> ))}
                        </div>
                    </>
                    :
                    <div className="text-center text-lg">
                        <h1 className=''>{isUpNotice ? "No hay avisos." : "Por el momento el servicio no está disponible."}</h1>
                    </div>


                }


            </div>

        )
    } else {
        return (
            <Spinner />
        )
    }

}

export default NoticeResults;
