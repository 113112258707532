import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import VisitorContext from '../../context/visitors/VisitorContext'
import AlertContext from '../../context/alert/AlertContext'
import { searchVisitors } from '../../context/visitors/VisitorActions'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import Ad from "../ads/Ad";

function VisitorSearch() {
  const [keywords, setKeywords] = useState('')

  const { token } = useTokenAndUserData()
  const { dispatch, visitors: visitorsList, currentPage, previousPage, lastPage, isUpVisitor } = useContext(VisitorContext)
  const { dispatch: alertDispatch } = useContext(AlertContext)

  const navigate = useNavigate()

  useEffect(() => {

    alertDispatch({ type: 'REMOVE_ALERT'})
    dispatch({ type: 'SET_LOADING' })
    const getVisitorsData = async () => {

        const visitors = await searchVisitors({ keywords, page: currentPage, token })

          if (!visitors.paging.hasMore) {
            dispatch({ type: 'GET_LAST_PAGE', payload: true })
            // return;
          }
          dispatch({ type: 'GET_PREV_PAGE', payload: currentPage })
          dispatch({ type: 'GET_VISITORS', payload: [...visitorsList, ...visitors.data] })

      // setKeywords('')
    }

    if ((!lastPage && previousPage !== currentPage) && isUpVisitor) {
      getVisitorsData()
    }

  }, [dispatch, token, alertDispatch, visitorsList, currentPage, previousPage, lastPage, keywords])

  const handleChange = (e) => setKeywords(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()
    dispatch({ type: 'GET_VISITORS', payload: []})
    dispatch({ type: 'GET_LAST_PAGE', payload: false })
    dispatch({ type: 'GET_PREV_PAGE', payload: 0 })
    dispatch({ type: 'GET_CURR_PAGE', payload: 1 })
    dispatch({ type: 'SET_LOADING' })
    const visitors = await searchVisitors({ page: 1, keywords, token })

    if (!visitors.paging.hasMore) {
      dispatch({ type: 'GET_LAST_PAGE', payload: true })
      // return;
    }

    dispatch({ type: 'GET_PREV_PAGE', payload: currentPage })
    dispatch({ type: 'GET_VISITORS', payload: visitors.data })
  }

  return (
    <div className="w-full mx-auto lg:w-10/12">

      <Ad/>

      <div className="text-center hero-content pt-0 pb-0">
        <h1 className='text-3xl card-title'>Visitantes</h1>
      </div>

      {/* <div className='grid gird-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid_cols-2 mb-8 gap-8 mt-9'> */}

      {}
      <div className='mb-8 gap-8 mt-9'>
        <div>

          <form onSubmit={handleSubmit}>
            <div className='form-control'>
              <div className='relative'>
                {isUpVisitor ? <>
                      <input
                          type='text'
                          className='w-full pr-4 bg-gray-200 text-black input input-md xl:input-lg lg:input-lg md:input-lg'
                          placeholder='Visitante'
                          value={keywords}
                          onChange={handleChange}
                      />
                      <button type='submit' className='absolute top-0 right-0 rounded-l-none w-36 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg'>
                        Buscar
                      </button>

                    </> :
                    <div className="text-center text-lg">
                      <h1 >Por el momento el servicio no está disponible.</h1>
                    </div>
                }


              </div>
            </div>
          </form>
        </div>

        {/* {visitors.length > 0 && (
          <div>
            <button onClick={() => dispatch({ type: 'CLEAR_VISITORS'})} className='btn btn-ghost btn-lg'>
              Limpiar Busqueda
            </button>
          </div>
        )} */}
      </div>
      {isUpVisitor &&
          <div className='w-full flex justify-center mb-8 gap-8 mt-9'>
            <button onClick={() => navigate(`/visitors/create`)} className='mr-6 w-5/12 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg'>
              Alta Visitante
            </button>

            <button onClick={() => navigate(`/visits`)} className='w-5/12 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg'>
              Ver Accesos
            </button>
          </div>

      }


    </div>
  )
}

export default VisitorSearch
