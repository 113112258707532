import {useContext, useEffect, useState} from "react";
import AlertContext from "../../context/alert/AlertContext";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import {useNavigate} from "react-router-dom";
import {addAmenity} from "../../context/amenity/AmenityActions";
import dayjs from "dayjs";
import {addSurvey} from "../../context/survey/SurveyActions";
function AddSurvey() {

    const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
    const { userData, token } = useTokenAndUserData();

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [dateOfPublication, setDateOfPublication] = useState(dayjs().format('YYYY-MM-DD'));
    const [dateOfExpiration, setDateOfExpiration] = useState(dayjs().add(7, 'day').format('YYYY-MM-DD'));
    const [appId, setAppId] = useState(0);
    const [userEmail, setUserEmail] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {

            setAppId(userData.appId);
            setUserEmail(userData.email);

        }
        getData();
    }, [token]);

    const handleChangeName = (e) => setTitle(e.target.value);
    const handleChangeDateOfPublication = (e) => setDateOfPublication(e.target.value);
    const handleChangeDateOfExpiration = (e) => setDateOfExpiration(e.target.value);
    const handleChangeDescription = (e) => setDescription(e.target.value);

    const handleSubmit = async (e) => {
        e.preventDefault()

        if(title.length <= 0 ) {
            setAlert('Por favor ingresa todos los datos requeridos', 'error')

        } else {
            alertDispatch({ type: 'SET_LOADING' })

            const creationRequest = {
                appId,
                title,
                description,
                startDay: dateOfPublication,
                finalDay: dateOfExpiration,
                createBy : userEmail,
                updatedBy : userEmail,
            }

            console.log(creationRequest);

            const add = await addSurvey(creationRequest);
            console.log(add);

            // dispatch({ type: 'ADD_FILE', payload: newFile });
            // dispatch({ type: 'GET_FILES', payload: []});
            //
            navigate(`/survey`);
        }
    }

    return (
        <div className="w-full mx-auto lg:w-10/12">
            <div className="text-center hero-content">
                <h1 className='text-3xl card-title'>Agregar Encuesta</h1>
            </div>

            <form onSubmit={handleSubmit}>
                <div className='form-control'>

                    <label className='mt-4 text-lg'>
                        Título * :
                        <input
                            className="mt-2 w-full pr-6 bg-gray-200 input input-lg text-black"
                            id="file_input"
                            type='text'
                            placeholder='Nombre'
                            onChange={handleChangeName} />

                    </label>

                    <label className='mt-4 text-lg'>
                        Descripción :
                        <textarea
                            className='mt-2 w-full h-40 pr-4 bg-gray-200 input input-lg text-black'
                            placeholder='Descripción'
                            value={description}
                            onChange={handleChangeDescription}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Fecha de publicación * :
                        <input
                            type='date'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='fecha de publicación'
                            value={dateOfPublication}
                            onChange={handleChangeDateOfPublication}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Fecha de expiración :
                        <input
                            type='date'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Fecha de expiración'
                            value={dateOfExpiration}
                            onChange={handleChangeDateOfExpiration}
                        />
                    </label>

                    <div className="mt-6 card-actions">
                        <button type='submit' className='btn btn-outline'>
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddSurvey
