import React, {useContext} from 'react'
import Spinner from '../layout/Spinner'
import RecurrentCollectContext from "../../context/RecurrentCollect/RecurrentCollectContext";
import RecurrentCollectRow from "./RecurrentCollectRow";
import RecurrentCollectCard from "./RecurrentCollectCard";
import AlertContext from "../../context/alert/AlertContext";

function RecurrentCollectsResult() {

    const { recurrentCollects, loading, isUpRecurrent } = useContext( RecurrentCollectContext );
    const { dispatch: alertDispatch,  } = useContext(AlertContext);

    if(!loading) {
        return (<div className="p-2 h-screen ">

            {recurrentCollects.length > 0 ?
                <>
                    <div className="overflow-auto rounded-lg shadow hidden md:block relative">
                        <table className="w-full">
                            <thead className="bg-gray-50 border-b-2 border-gray-200 dark:bg-slate-800 ">
                            <tr>
                                <th className="w-24 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Fecha</th>
                                <th className="w-24 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Nombre</th>
                                <th className="w-24 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Cantidad</th>
                                <th className="w-24 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Recurencia</th>
                                <th className="w-24 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Unidades</th>
                                {/*<th className="w-24 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Editado por</th>*/}

                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-100 relative ">

                            {recurrentCollects.length > 0 && recurrentCollects.map(recurrentJob => (<RecurrentCollectRow key={recurrentJob.id} recurrentJob={recurrentJob} />))}

                            </tbody>
                        </table>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:hidden">
                        {recurrentCollects.length > 0 && recurrentCollects.map(recurrentJob => ( <RecurrentCollectCard key={recurrentJob.id} recurrentJob={recurrentJob} /> ))}
                    </div>
                </>
                :
                <div className="text-center text-lg">
                    <p className=''>{isUpRecurrent ? "No hay cobros " : "Por el momento el servicio no está disponible"}</p>
                </div>
            }


            </div>

        )
    } else {
        return (
            <Spinner />
        )
    }
}

export default RecurrentCollectsResult
