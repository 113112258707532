
import {useContext, useEffect} from "react";
import AlertContext from "../../context/alert/AlertContext";
import Spinner from "../layout/Spinner";
import {validatePermissions} from "../../context/login/LoginActions";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import FileContext from "../../context/files/FileContext";
import {searchAllFiles} from "../../context/files/FileActions";
import FileRow from "./FileRow";
import FileCard from "./FileCard";


function FileResults() {

    const { dispatch, files, loading , isUpFile} = useContext( FileContext );
    const {  dispatch: alertDispatch, setAlert } = useContext(AlertContext);
    const { userData, token } = useTokenAndUserData();
    //
    // useEffect( () => {
    //     const checkIsUpNotice = async () => {
    //         const files = await searchAllFiles();
    //         const isUp  = collectStatus?.status === 200;
    //         dispatch({ type: 'SET_IS_UP', payload: isUp });
    //     }
    //
    //     checkIsUpNotice();
    //
    // }, [])

    useEffect(() => {

        alertDispatch({ type: 'REMOVE_ALERT'})
        dispatch({ type: 'SET_LOADING' })

        const getData = async () => {
            if(userData.appId){
                const files = await searchAllFiles(userData.appId, token);
                dispatch({ type: 'GET_FILES', payload: files })
            }
        }

        getData();

    }, [dispatch, alertDispatch, userData])

    if(!loading) {
        return (<div className="p-2 h-screen ">

                {files.length > 0 && isUpFile ?
                    <>
                        <div className="overflow-auto rounded-lg shadow hidden md:block relative">
                            <table className="w-full">
                                <thead className="bg-gray-50 border-b-2 border-gray-200 dark:bg-slate-800 ">
                                <tr>
                                    <th className="w-20 p-3 text-lg font-semibold tracking-wide text-left dark:border-slate-600 "></th>
                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Fecha</th>
                                    <th className="p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Título</th>
                                    {/*<th className="w-24 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Actualizado</th>*/}

                                    <th className="w-32 p-3 text-sm font-semibold tracking-wide text-left dark:text-white dark:border-slate-600"></th>

                                    {validatePermissions(userData.userPermissions, 'FILES') &&
                                        <th className="w-32 p-3 text-sm font-semibold tracking-wide text-left dark:text-white dark:border-slate-600"></th>
                                    }
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-100 relative ">

                                {files.map(file => ( <FileRow key={file.id} file={file}/>))}

                                </tbody>
                            </table>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:hidden">
                            {files.map(file => ( <FileCard key={file.id} file={file}/> ))}
                        </div>
                    </>
                    :
                    <div className="text-center text-lg">
                        <h1 className=''>{isUpFile ? "No hay documentos." : "Por el momento el servicio no está disponible."}</h1>
                    </div>


                }


            </div>

        )
    } else {
        return (
            <Spinner />
        )
    }

}

export default FileResults;
