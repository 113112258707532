import {createContext, useReducer} from 'react'
import FileReducer from "./FileReducer";

const FileContext = createContext()

export const FileProvider = ({ children }) => {

    const initialState = {
        files: [],
        file: {},
        n:true,
        loading: false
    }

    const [state, dispatch] = useReducer(FileReducer, initialState)

    return <FileContext.Provider value={{
        ...state,
        dispatch,
    }}>
        {children}
    </FileContext.Provider>
}

export default FileContext
