import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import {useContext, useEffect, useState} from "react";
import AlertContext from "../../context/alert/AlertContext";
import {useNavigate} from "react-router-dom";
import NoticeContext from "../../context/notice/NoticeContext";
import {addNotice, searchAllTypes} from "../../context/notice/NoticeActions";
import dayjs from "dayjs";
import Ad from "../ads/Ad";


function AddNotice() {

    const { dispatch, types } = useContext( NoticeContext );
    const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
    const { userData, token } = useTokenAndUserData();
    const [userId, setUserId] = useState(0);
    const [appId, setAppId] = useState(0);
    const [userEmail, setUserEmail] = useState('');


    useEffect(() => {

        alertDispatch({ type: 'REMOVE_ALERT'})
        dispatch({ type: 'SET_LOADING' })

        const getNoticeData = async () => {
            let typesOfCategories = await searchAllTypes(token);
            dispatch({type: 'GET_TYPES', payload: typesOfCategories});

        }
        setUserId(userData.userId);
        setAppId(userData.appId);
        setUserEmail(userData.email);

        getNoticeData();

    }, [dispatch, alertDispatch, userData]);


    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isPriority, setIsPriority] = useState(false);
    const [dateOfPublication, setDateOfPublication] = useState(dayjs().format('YYYY-MM-DD'));
    const [dateOfExpiration, setDateOfExpiration] = useState(dayjs().add(7, 'day').format('YYYY-MM-DD'));
    const [typeId, setType] = useState(0);
    // const defaultValue = dayjs().format('YYYY-MM-DD');
    // console.log(defaultValue);

    const handleChangeTitle = (e) => setTitle(e.target.value);
    const handleChangeDateOfPublication = (e) => setDateOfPublication(e.target.value);
    const handleChangeDateOfExpiration = (e) => setDateOfExpiration(e.target.value);
    const handleChangeIsPriority = (e) => setIsPriority(!isPriority);
    const handleChangeDescription = (e) => setDescription(e.target.value);
    const handleChangeCategory = (e) => {
        setType(e.target.value)
    };

    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()

        if(title === '' || dateOfPublication === '' || dateOfExpiration === '' || typeId === 0 ) {
            setAlert('Por favor ingresa todos los datos requeridos', 'error')

        } else {

            alertDispatch({ type: 'SET_LOADING' })

            console.log({
                userId,
                appId,
                createBy : userEmail,
                title,
                description,
                isPriority,
                dateOfPublication,
                dateOfExpiration,
                typeId
            });

            const newNotice = await addNotice({
                userId,
                appId,
                createBy : userEmail,
                title,
                description,
                isPriority,
                dateOfPublication,
                dateOfExpiration,
                typeId
            }, token)

            if(newNotice.httpStatus === 'BAD_REQUEST') {
                setAlert("Hubo un error al crear el aviso, contacta al administrador", 'error')

            }else{
                dispatch({ type: 'ADD_NOTICE', payload: newNotice });
                dispatch({ type: 'GET_NOTICES', payload: []});

                setTitle('');
                setDateOfPublication('');
                setDateOfExpiration('');
                setIsPriority(false);
                setDescription('');

                navigate(`/home`);
            }
        }
    }

    return (
        <div className="w-full mx-auto lg:w-10/12">
            <Ad/>
            <div className="text-center hero-content">
                <h1 className='text-3xl card-title'>Agregar Aviso </h1>
            </div>

            <form onSubmit={handleSubmit}>
                <div className='form-control'>

                    <label className='mt-4 text-lg'>
                        Título * :
                        <input
                            type='text'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Titulo'
                            value={title}
                            onChange={handleChangeTitle}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Fecha de publicación * :
                        <input
                            type='date'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='fecha de publicación'
                            value={dateOfPublication}
                            onChange={handleChangeDateOfPublication}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Fecha de expiración * :
                        <input
                            type='date'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Fecha de expiración'
                            value={dateOfExpiration}
                            onChange={handleChangeDateOfExpiration}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Categoria * :
                        <select
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Categoria del anuncio'
                            value={typeId}
                            onChange={handleChangeCategory}
                        >
                            <option defaultValue key='' value=''>Selecciona una opción</option>

                            {types.map((type) => (

                                <option key={type.typeName} value={type.typeId}>
                                    {type.typeName}
                                </option>

                            ))}
                        </select>
                    </label>

                    <label className='label cursor-pointer mt-4 '>
                        <span className="text-lg">Destacar:</span>
                        <input
                            type='checkbox'
                            className='toggle'
                            value={isPriority}
                            onChange={handleChangeIsPriority}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Descripción:
                        <textarea
                            type='text'
                            className='mt-2 w-full h-40 pr-4 bg-gray-200 input input-lg text-black'
                            placeholder='Descripción'
                            value={description}
                            onChange={handleChangeDescription}
                        />
                    </label>

                    <div className="mt-6 card-actions">
                        <button type='submit' className='btn btn-outline'>
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddNotice
