import {useContext, useEffect, useState} from "react";
import AdContext from "../../context/ads/AdsContext";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";

function Ad() {

    const { ads } = useContext(AdContext)
    const [logo, setLogo] = useState('');
    const [url, setUrl] = useState('');

    useEffect(() => {
        const host = process.env.REACT_APP_HOST || window.location.origin
        const imagePath = `${host}${process.env.REACT_APP_IMAGES_PATH}`

        if (ads.length > 0) {

            const numRandom = Math.floor(Math.random() * ads.length);
            const url = ads[numRandom].url;
            setUrl(url);
            const imageUrl = `${imagePath}${ads[numRandom].fileName}`;
            setLogo(imageUrl);

        }
    }, [ads]);

    return (
        <>
            {logo && (

                <a href={url}  target="_blank" rel="noopener noreferrer">
                    <div className={`list-image-none mb-3 md:list-image-[${logo}]`}>
                        <img
                            className='text-center mx-auto'
                            src={logo}
                            alt='Loading...'
                        />
                    </div>
                </a>

            )}
        </>

    )
}

export default Ad;
