import {useEffect, useState} from "react";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import dayjs from "dayjs";
import {Link} from "react-router-dom";

function CollectRow( { collect }) {

    return (
        <>
            <tr className="bg-white dark:bg-slate-700">


                <td className="p-3 text-lg text-gray-700   dark:text-slate-400">
                    <Link to={`/collects/transaction/${collect.id}/${collect.unitId}`} className="w-100%">
                        {collect.transactionDate}
                    </Link>
                </td>

                <td className="p-3 text-lg text-gray-700  dark:text-slate-400">
                    <Link to={`/collects/transaction/${collect.id}/${collect.unitId}`} className="w-100%">
                    {collect.concept.description}
                    </Link>
                </td>


                <td  className={`p-3 text-lg whitespace-nowrap `}>
                    <Link to={`/collects/transaction/${collect.id}/${collect.unitId}`} className="w-100%">
                    {collect.concept.operation === 'SUB' ? "-" : "+"} {collect.quantity}
                    </Link>
                </td>

                <td className={`p-3 text-lg whitespace-nowrap `}>
                    {collect.description}
                </td>

            </tr>

        </>

    );
}

export default CollectRow;
