import { createContext, useReducer } from 'react'
import loginReducer from './LoginReducer'

const LoginContext = createContext()

export const LoginProvider = ({ children }) => {
  const initialState = {
    isAuthenticated: false,
    userData: {},
    settings: {},
    users: [],
    currentPage: 1,
    previousPage: 0,
    lastPage: false,
    user: {},
    tempPass: null,
    newUser: {},
    address: [],
    permissions: [],
    token: '', 
    message: '',   
    loading: false,
    isUpUser:false
  }

  const [state, dispatch] = useReducer(loginReducer, initialState)


  return <LoginContext.Provider value={{
    ...state,
    dispatch,
  }}>
    {children}
  </LoginContext.Provider>
}

export default LoginContext
