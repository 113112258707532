import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { MdOutlineQrCode2 } from 'react-icons/md'
import { deleteVisit } from '../../context/visits/VisitActions'
import { FaTimes } from 'react-icons/fa'
import VisitContext from '../../context/visits/VisitContext'
import PropTypes from 'prop-types'
import {validatePermissions, validateRoles} from '../../context/login/LoginActions'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";

function VisitItem({ visit: { visitCode, visitorName, visitorPlates, startDate, address } }) {
  const { token, userData } = useTokenAndUserData()
  const { visits, dispatch } = useContext(VisitContext)
  
  const handleDelete = async () => {
    if(await deleteVisit(visitCode, token)) {
      dispatch({ type: 'GET_VISITS', payload: visits.filter((item) => item.visitCode !== visitCode ) })
    }
  }
  
  return (
    <div className='card shadow-md compact side bg-base-100'>
      <div className="grid grid-cols-5 flex-row items-center space-x-4 card-body">
        <Link to={`/visit/${visitCode}`} className='col-span-1'>
          <div>
            <div className="rounded-full w-16 h-16">
              <MdOutlineQrCode2 className='text-6xl md:text-5xl' />
            </div>
          </div>
        </Link>

        {validatePermissions(userData.userPermissions, 'ACCESS')? (
          <>
            <Link to={`/visit/${visitCode}`} className='col-span-3'>
              <div>
                <h2 className='card-title'>{visitCode}</h2>
                <h3 className='text-base-content text-opacity-40'>{visitorName}</h3>
                <h3 className='text-base-content text-opacity-40'>{visitorPlates && (`${visitorPlates} /`)} {startDate}</h3>
                <h3 className='text-base-content text-opacity-40'>{address}</h3> 
              </div>
            </Link>

            <div className='col-span-1 justify-end xl:col-span-1 lg:col-span-1 md:col-span-1'>
              <button onClick={handleDelete}
                className='close tooltip rounded-full'
                data-tip='Cancelar QR'>
                  <FaTimes className='text-4xl md:text-3xl' color='primary '/>
              </button>
            </div>
          </>
        ) : (
          <Link to={`/visit/${visitCode}`} className='col-span-4'>
            <div>
              <h2 className='card-title'>{address}</h2>
              <h2 className='card-subtitle'>{visitorName}</h2>
              <h3 className='text-base-content text-opacity-40'>{visitCode}</h3>
              <h3 className='text-base-content text-opacity-40'>{visitorPlates && (`${visitorPlates} /`)} {startDate}</h3>
            </div>
          </Link>
        )}
      </div>
    </div>
  )
}

VisitItem.propTypes = {
  visit: PropTypes.object.isRequired,
}

export default VisitItem
