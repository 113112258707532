import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import {useContext, useEffect, useRef, useState} from "react";
import AlertContext from "../../context/alert/AlertContext";
import {useNavigate} from "react-router-dom";
import CollectContext from "../../context/collect/CollectContext";
import {addCollect, getAllConcepts, getBalanceByUnitId} from "../../context/collect/CollectActions";
import LoginContext from "../../context/login/LoginContext";
import {searchAddresses} from "../../context/address/AddressActions";
import {formatValue} from "../../utils/utilsFunctions";
import {MultiSelect} from "react-multi-select-component";
import Ad from "../ads/Ad";
import dayjs from "dayjs";


function AddCollect() {

    const { dispatch, concepts} = useContext( CollectContext );
    const { address, dispatch : loginDispatch} = useContext(LoginContext)
    const { token } = useTokenAndUserData();
    const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
    const { userData } = useTokenAndUserData();
    const [userId, setUserId] = useState(0);
    const [appId, setAppId] = useState(0);
    const [unit, setUnit] = useState([])

    const [unitId, setUnitId] = useState(0);
    const [unitName, setUnitName] = useState('');
    const [user, setUser] = useState('');
    const [date, setDate] = useState('');
    const [conceptId, setConceptId] = useState(0);
    const [description, setDescription] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [balanceBefore, setBalanceBefore] = useState(0);
    const [balanceAfter, setBalanceAfter] = useState(0);
    const [reference, setReference] = useState('');

    const balanceBeforeRef = useRef(null);
    const balanceAfterRef = useRef(null);

    useEffect(() => {

        alertDispatch({ type: 'REMOVE_ALERT'})
        dispatch({ type: 'SET_LOADING' })

        const getData = async () => {
            let getConcepts = await getAllConcepts(token);
            let addressData = await searchAddresses({keywords: '', pageSize: 2000, token})

            addressData = addressData.data.map((item) => ({
                value: item.addressId,
                label: `${formatValue(item.addressUnit, true)}${formatValue(item.addressBuilding)}${formatValue(item.addressIntNum)}`
            }));

            loginDispatch({ type: 'GET_ADDRESS', payload: addressData });
            dispatch({type: 'GET_CONCEPTS', payload: getConcepts});
        }

        const getBalance = async () => {

            if(unit.length === 1 ){
                const blc = await getBalanceByUnitId(unit[0].value, appId, token);
                // console.log(blc);

                if(Object.prototype.hasOwnProperty.call(blc, 'balance', )) {
                    balanceBeforeRef.current.value = blc.balance;
                    setBalanceBefore(blc.balance)
                }
            }else if(unit.length >= 1 ){

                balanceBeforeRef.current.value = 0;
                setBalanceBefore(0)
                balanceAfterRef.current.value = 0;
                setBalanceAfter(0);

            }
        };

        setUserId(userData.userId);
        setAppId(userData.appId);
        setUser(userData.email);

        getBalance();
        getData();

    }, [dispatch, loginDispatch, token, alertDispatch, userData, unit]);

    const handleChangeConcept = (e) => setConceptId(e.target.value);
    const handleChangeDescription = (e) => setDescription(e.target.value);
    const handleChangeQuantity= (e) => {
        setQuantity(e.target.value)

        if(unit.length === 1 ){
            if(balanceAfterRef.current){
                balanceAfterRef.current.value = Number(balanceBefore) + Number(e.target.value);
                setBalanceAfter(Number(balanceBefore) + Number(e.target.value))
            }
        }
    };
    const handleChangeReference = (e) => setReference(e.target.value);

    const handleChangeAddress = (e) => {
        setUnit(e);
    };

    const handleChangeDate = (e) => setDate(dayjs(e.target.value).format('DD/MM/YYYY'));

    const navigate = useNavigate()

    const handleSubmit = async (e) => {

        e.preventDefault()

        const units = unit.map((item) => ({
            unitId: item.value,
            unitName: item.label
        }));


        if(unit.length <= 1 ) {
            setAlert('Por favor ingresa Unidades', 'error')
        } else {

            alertDispatch({ type: 'SET_LOADING' })

            // console.log({
            //     createdBy : user,
            //     updatedBy : user,
            //     units,
            //     appId,
            //     conceptId,
            //     description,
            //     quantity,
            //     transactionDate:date,
            //     reference
            // });

            const newCollect = await addCollect({
                createdBy : user,
                updatedBy : user,
                units,
                appId,
                conceptId,
                description,
                quantity,
                transactionDate:date,
                reference
            }, token);

            dispatch({ type: 'ADD_NOTICE', payload: newCollect });
            dispatch({ type: 'GET_NOTICES', payload: []});

            setUnitId(0);
            setUnitName('');
            setConceptId(0);
            setDescription('');
            setQuantity(0);
            setReference('');

            navigate(`/payments`);
        }
    }

    return (
        <div className="w-full mx-auto lg:w-10/12">
            <Ad/>

            <div className="text-center hero-content">
                <h1 className='text-3xl card-title'>Cobranza</h1>
            </div>

            <form onSubmit={handleSubmit}>
                <div className='form-control'>

                    <label className='mt-4 text-lg'>
                        Unidades * :
                        <MultiSelect
                            className='mt-2 pl-2 pr-0 pt-2.5 bg-gray-200 input input-lg text-black'
                            options={address}
                            value={unit}
                            onChange={handleChangeAddress}
                            labelledBy={'Selecciona una opción'}
                        />

                    </label>

                    <label className='mt-4 text-lg'>
                        Concepto:
                        <select
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Tipo de concepto'
                            value={conceptId}
                            onChange={handleChangeConcept}
                        >
                            <option defaultValue key='' value=''>Selecciona una opción</option>

                            {concepts.map((type) => (

                                <option key={type.id} value={type.id}>
                                    {type.description}
                                </option>
                            ))}
                        </select>
                    </label>

                    <label className='mt-4 text-lg'>
                        Fecha:
                        <input
                            type='date'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Fecha'
                            value={date}
                            onChange={handleChangeDate}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Descripción:
                        <textarea

                            className='mt-2 w-full h-40 pr-4 bg-gray-200 input input-lg text-black'
                            placeholder='Descripción'
                            value={description}
                            onChange={handleChangeDescription}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Referencia:
                        <input
                            type='text'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Referencia'
                            value={reference}
                            onChange={handleChangeReference}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Cantidad:
                        <input
                            type='number'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Cantidad'
                            value={quantity}
                            min="0"
                            onChange={handleChangeQuantity}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Balance Antes:
                        <input
                            type="text"
                            disabled={true}
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Balance Antes'
                            ref={balanceBeforeRef}
                            value={balanceBefore}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Balance Despues:
                        <input
                            type='number'
                            disabled={true}
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Balance Despues'
                            ref={balanceAfterRef}
                            value={balanceAfter}
                        />
                    </label>

                    <div className="mt-6 card-actions">
                        <button type='submit' className='btn btn-outline'>
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddCollect
