import VisitSearch from "./VisitSearch"
import VisitResults from "./VisitResults"

function Visits() {
  return (
    <>
      <VisitSearch />
      <VisitResults />
    </>
  )
}

export default Visits