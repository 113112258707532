import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import LoginContext from '../../context/login/LoginContext'
import AlertContext from '../../context/alert/AlertContext'
import { searchUsers } from '../../context/login/LoginActions'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import Ad from "../ads/Ad";

function UserSearch() {
  const [keywords, setKeywords] = useState('')

  const { dispatch, users: usersList, currentPage, previousPage, lastPage } = useContext(LoginContext)
  const { token } = useTokenAndUserData()
  const { dispatch: alertDispatch } = useContext(AlertContext)
  const navigate = useNavigate()

  // useEffect(() => {
  //   alertDispatch({ type: 'REMOVE_ALERT'})
  //   dispatch({ type: 'SET_LOADING' })
  //
  //   const getUsersData = async () => {
  //
  //     const users = await searchUsers({ keywords, page: currentPage, token })
  //     if (!users.paging.hasMore) {
  //       dispatch({ type: 'GET_LAST_PAGE', payload: true })
  //       // return;
  //     }
  //     dispatch({ type: 'GET_PREV_PAGE', payload: currentPage })
  //     dispatch({ type: 'GET_USERS', payload: [...usersList, ...users.data] })
  //     // setKeywords('')
  //   }
  //
  //   if (!lastPage && previousPage !== currentPage ) {
  //     getUsersData()
  //   }
  //
  // }, [dispatch, token, alertDispatch, usersList, currentPage, previousPage, lastPage, keywords])

  // Separate the functionality of getting users into a function
  const getUsersData = async () => {
      const users = await searchUsers({ keywords, page: currentPage, token })
      if (!users.paging.hasMore) {
        dispatch({ type: 'GET_LAST_PAGE', payload: true })
        // return;
      }
      dispatch({ type: 'GET_PREV_PAGE', payload: currentPage })
      dispatch({ type: 'GET_USERS', payload: [...usersList, ...users.data] })

  }

  // Call getUsersData on initialization
  useEffect(() => {

    alertDispatch({ type: 'REMOVE_ALERT'})
    dispatch({ type: 'SET_LOADING' })

    // console.log(currentPage)

    if (!lastPage && previousPage !== currentPage ) {
      getUsersData()
    }
  }, [dispatch, token, alertDispatch, usersList, currentPage, previousPage, lastPage, keywords])


  const handleChange = (e) => setKeywords(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()
    dispatch({ type: 'GET_USERS', payload: []})
    dispatch({ type: 'GET_LAST_PAGE', payload: false })
    dispatch({ type: 'GET_PREV_PAGE', payload: 0 })
    dispatch({ type: 'GET_CURR_PAGE', payload: 1 })
    dispatch({ type: 'SET_LOADING' })
    const users = await searchUsers({ page: 1, keywords, token })
    if (!users.paging.hasMore) {
      dispatch({ type: 'GET_LAST_PAGE', payload: true })
      // return;
    }
    dispatch({ type: 'GET_PREV_PAGE', payload: currentPage })
    dispatch({ type: 'GET_USERS', payload: users.data })
  }

  return (

    <div className="w-full mx-auto lg:w-10/12">

      <Ad/>

      <div className="text-center hero-content pt-0 pb-0">
        <h1 className='text-3xl card-title'>Usuarios</h1>
      </div>
      {/*<div className='grid gird-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid_cols-2 mb-8 gap-8'>*/}
      <div className='mb-8 gap-8 mt-9'>
        <div>
          <form onSubmit={handleSubmit}>
            <div className='form-control'>
              <div className='relative'>
                <input
                  type='text'
                  className='w-full pr-4 bg-gray-200 text-black input input-md xl:input-lg lg:input-lg md:input-lg'
                  placeholder='Usuario'
                  value={keywords}
                  onChange={handleChange}
                />
                <button type='submit' className='absolute top-0 right-0 rounded-l-none w-36 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg'>
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className='w-full flex justify-center mb-8 gap-8 mt-9'>
        <button onClick={() => navigate(`/users/create`)} className='mr-6 w-10/12 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg'>
          Crear Usuario
        </button>
      </div>
    </div>
  )
}

export default UserSearch
