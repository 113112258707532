import {createContext, useEffect, useReducer} from 'react'
import ReservationReducer from "./ReservationReducer";

const ReservationContext = createContext()

export const ReservationProvider = ({ children }) => {

    const initialState = {
        reservations: []
    }

    const [state, dispatch] = useReducer(ReservationReducer, initialState)
    const {reservations, month, selectYear} = state;

    const setReservations = (rsv) => {
        dispatch({type: 'GET_RESERVATIONS', payload: rsv});
    }

    const setMonthAndYear = (m,y) => {
        dispatch({type: 'SET_MONTH', payload: m});
        dispatch({type: 'SET_YEAR', payload: y});
    }

    return <ReservationContext.Provider value={{
        reservations,
        setReservations,
        month,
        setMonthAndYear,
        selectYear,
        ...state,
        dispatch,
    }}>
        {children}
    </ReservationContext.Provider>
}

export default ReservationContext
