
import { useContext, useEffect } from 'react'
import AlertContext from '../context/alert/AlertContext'

function Help() {
  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })

  const { dispatch: alertDispatch } = useContext(AlertContext)

  useEffect(() => {
    alertDispatch({ type: 'REMOVE_ALERT' })
  }, [alertDispatch])

  return (
    <>
      <div>

        <div>
        <p className='mb-4 text-2xl font-light'>
          Seleccione el tipo de ayuda.
        </p>
        </div>

        <div className="mt-4 card-actions">
          <a href='https://docs.google.com/document/d/1PybTHJCs8SGtpFJgbSZrHa7GBwvNWDgFBeTk0RT5IG8/edit?usp=sharing' target="_blank" rel="noreferrer">
            <button className='btn btn-outline'>Preguntas Frecuentes</button>
          </a>
        </div>

        <div className="mt-4 card-actions">
          <a href='mailto:cotoadmin@pjdevelopers.com?subject=Bellaterra: Defecto'>
            <button className='btn btn-outline'>Reportar Defecto</button>
          </a>
        </div>

        <div className="mt-4 card-actions">
          <a className='text-gray-400' href='mailto:cotoadmin@pjdevelopers.com?subject=Bellaterra: Mejora'>
            <button className='btn btn-outline'>Solicitar Mejora</button>
          </a>
        </div>

        <div className="mt-4 card-actions">
          <a className='text-gray-400' href='mailto:cotoadmin@pjdevelopers.com?subject=Bellaterra: Ayuda'>
            <button className='btn btn-outline'>Otro</button>
          </a>
        </div>
      </div>
    </>

  )
}

export default Help
