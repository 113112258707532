
import {BsFillHouseDoorFill} from "react-icons/bs";

function QuestionCard ( { question } ) {

    return (
        <>
            <div className='card shadow-md compact side bg-base-100'>
                {/*<Link to={`/survey/questions/${question.id}`} className="w-100%">*/}

                    <div className="grid grid-cols-6 xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-6 flex-row items-center space-x-4 card-body">


                        <div>
                            <div className="rounded-full w-16 h-16 col-span-1">
                                <BsFillHouseDoorFill className='text-6xl md:text-5xl' />
                            </div>
                        </div>

                        <div className={"col-span-3"}>
                            <h2 className='card-title'>
                                {question.question}
                            </h2>
                            <h3 className='text-base-content text-opacity-40'>{question.questionType}</h3>
                        </div>

                        <div className={"col-span-3"}>
                            <h2 >
                                {question.answers && question.answers.map(answer =>
                                    <h2 className='card-title' key={answer.id}>
                                        {answer.answer}
                                    </h2>

                                )}
                            </h2>
                        </div>

                    </div>
                {/*</Link>*/}
            </div>

        </>
    )
}

export default QuestionCard;
