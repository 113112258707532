import {useEffect, useState} from "react";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import { FiDollarSign } from "react-icons/fi"
import {Link} from "react-router-dom";
import dayjs from "dayjs";

function RecurrentCollectRow({ recurrentJob }) {

    const [colorConcept, setColorConcept] = useState('');
    const [operatorConcept, setOperatorConcept] = useState('');
    const [units, setUnits] = useState(0);
    const [date, setDate] = useState();

    useEffect(() => {

        setDate(dayjs(recurrentJob.createdOn).format('YYYY-MM-DD'))

        if(recurrentJob.concept.operation === 'SUM'){
            setColorConcept('text-green-600');
            setOperatorConcept('+');
        }else if(recurrentJob.concept.operation === 'SUB'){
            setColorConcept('text-red-600')
            setOperatorConcept('-');
        }
        const unitsJobs = recurrentJob.unitJob.map(unitJob => unitJob.unitId);

        setUnits(unitsJobs.length);

    }, []);

    return (
        <>
                <tr className={`bg-white dark:bg-slate-700 w-full text-base`}>

                    <td className="p-3 text-lg  whitespace-nowrap text-base ">
                        <Link to={`/recurrent_collects/${recurrentJob.id}/update`} className="w-100%">
                            {date}
                        </Link>
                    </td>

                    <td className="p-3 text-lg whitespace-nowrap text-md ">
                        <Link to={`/recurrent_collects/${recurrentJob.id}/update`} className="w-100% text-base">
                            {recurrentJob.title ? recurrentJob.title.slice(0,25)  : recurrentJob.concept.description.slice(0,20)}
                        </Link>
                    </td>

                        <td className={`p-3 text-lg whitespace-nowrap  `}>
                        <Link to={`/recurrent_collects/${recurrentJob.id}/update`} className={`w-100% text-base ${colorConcept}`}>
                            {operatorConcept} {recurrentJob.quantity}
                        </Link>
                    </td>

                    <td className="p-3 text-lg whitespace-nowrap ">
                        <Link to={`/recurrent_collects/${recurrentJob.id}/update`} className="w-100% text-base">
                            {recurrentJob.cron.description}
                        </Link>

                    </td>

                    <td  className={`p-3 whitespace-nowrap  text-base`}>
                        {units}
                    </td>

                    {/*<td  className={`p-3 whitespace-nowrap  text-sm`}>*/}
                    {/*    {recurrentJob.updatedBy}*/}
                    {/*</td>*/}

                </tr>



        </>

    );
}

export default RecurrentCollectRow;
