import axios from 'axios';
import app from "../../App";
// const userServiceUrl = process.env.REACT_APP_USER_SERVICE || 'http://70.35.204.186:3000/pjd-cotoadmin-user'
const host = process.env.REACT_APP_HOST || window.location.origin
const service = `${host}${process.env.REACT_APP_COLLECT_SERVICE || '/collect'}`

// const service = `http://localhost:8090/collect`

const db = axios.create({
    baseURL: service,
})

export const addCollect = async (data, token) => {
    const response = await db.post(`/api/v1/create`, data, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    )
    return response.data.response
}

export const searchAllCollectsByAppId = async (id, token) => {
    try {
        const response = await db.get(`/api/v1/`+id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        );
        // console.log(response.data.response);
        return response.data.response;
    } catch (error) {
        throw new Error('Failed to retrieve collects.');
    }
}

export const getTransactionByUnitIdAndByIdAndByAppId = async (unitId, appId, id, token) => {
    try {
        // console.log('Getting transaction' + unitId + '' + appId + '' + id);
        const response = await db.get(`/api/v1/transaction/`, {
            params: {
                unitId: unitId,
                appId: appId,
                id:id

            },
            headers: {
                Authorization: `Bearer ${token}`
            }});
        // console.log(response.data.response);
        return response.data.response;
    } catch (error) {
        throw new Error('Failed to retrieve collects.');
    }
}


export const searchAllBalances = async (id, unitName, token) =>{
    console.log(id);
    console.log(unitName);
    const response = await db.get(`/api/v1/report_balance/${id}`, {
        params: {
            unitName: unitName

        },
        headers: {
            Authorization: `Bearer ${token}`
        }});
    // console.log(response.data);
    return response.data.response;
}

export const downloadFile = async (id, appName, token) =>{

    const response = await db.get(`/api/v1/downloadFile/${id}`, {
        params: {
            appName: appName

        },
        responseType: 'blob',
        headers: {
            Authorization: `Bearer ${token}`
        }// Specify the response type as 'blob'
    });
    // console.log(response.data);
    return response;
}


export const allCollectsByUnitIdAndAppId = async(unitId, appId, token) => {
    try {
        const response = await db.get(`/api/v1/collect/${unitId}/${appId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        // console.log(response);
        return response.data.response;
    } catch (error) {
        return error.response.data;
    }
}
export const getAllConcepts = async (token) =>{
    const response = await db.get(`/api/v1/concepts`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    // console.log(response);
    return response.data.response;
}

export const getBalanceByUnitId = async (unitId, appId, token) =>{
    try {
        const response = await db.get(`/api/v1/balance/${unitId}/${appId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.response;
    } catch (error) {
        return "no hay balance relacionados";
    }

}

export const checkHealthCollect = async () => {

    try {
        const response = await db.get(`/health`);
        return response;
    } catch (error) {
        return error;
    }
}

//
// export const updateNotice = async (id, data) => {
//
//     console.log(data);
//     const response = await db.patch(`/api/v1/update/`+id, data)
//     // console.log(response.data);
//     return response.data.response;
// }
//
// export const deleteNotice = async (id) => {
//
//     if(window.confirm('¿Seguro que deseas eliminar este Aviso?')) {
//
//         const response = await db.delete(`/api/v1/announcement/${id}`);
//
//         return response.data.response;
//     }
// }
