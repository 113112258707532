import axios from 'axios';
// const userServiceUrl = process.env.REACT_APP_USER_SERVICE || 'http://70.35.204.186:3000/pjd-cotoadmin-user'
const host = process.env.REACT_APP_HOST || window.location.origin
const userServiceUrl = `${host}${process.env.REACT_APP_NOTICE_SERVICE || '/notice'}`

// const userServiceUrl = `http://localhost:8090/notice`


const db = axios.create({
    baseURL: userServiceUrl,
})

export const searchNotices = async (appId, token) => {

    const response = await db.get(`/api/v1/announcements/` + appId, {
        headers: {
            Authorization: `Bearer ${token}`
        }})
    // console.log(response.data.response);
    return response.data.response;
}

export const addNotice = async (data, token) => {

    try{
        const response = await db.post(`/api/v1/create`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }})
        return response.data.response
    }catch (error){
        return error.response.data;
    }

}

export const searchAllTypes = async (token) => {
    try {
        const response = await db.get(`/api/v1/types`, {
            headers: {
                Authorization: `Bearer ${token}`
            }});
        // console.log(response.data.response);
        return response.data.response;
    } catch (error) {
        console.log(error);
        throw new Error('Failed to retrieve types.');
    }
}
export const findNoticeById = async (id, token) =>{
    const response = await db.get(`/api/v1/announcement/`+id, {
        headers: {
            Authorization: `Bearer ${token}`
        }});
    // console.log(response.data);
    return response.data.response;
}

export const updateNotice = async (id, data, token) => {

    try {
        const response = await db.post(`/api/v1/update/`+id, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }});

        if (response.status >= 200 && response.status < 300) {
            return response.data;
        } else {
            throw new Error('Request failed with status ' + response.status);
        }
    } catch (e) {
        return e;
        // throw new Error('Failed Update.');
    }

}

export const deleteNotice = async (id, token) => {

    if(window.confirm('¿Seguro que deseas eliminar este Aviso?')) {

        const response = await db.delete(`/api/v1/announcement/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }});

        return response.data.response;
    }
}

export const checkNoticeHealth = async () => {
    try {
        return await db.get(`/health`);
    } catch (error) {
        return error;
    }
}
