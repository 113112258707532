import UserSearch from './UserSearch'
import UserResults from './UserResults'

function Users() {

  return (
    <>
      <UserSearch />
      <UserResults />
    </>
  )
}

export default Users
