const ReservationReducer = (state, action) =>{
    switch(action.type) {

        case 'GET_RESERVATIONS':
            return {
                ...state,
                reservations: action.payload
            }

        case 'SET_MONTH':
            return {
                ...state,
                month: action.payload
            }

        case 'SET_YEAR':
            return {
                ...state,
                selectYear: action.payload
            }


        default:
            return state
    }
}

export default ReservationReducer
