import React, {useContext, useEffect, useState} from 'react'
import Spinner from '../../layout/Spinner'
import {useNavigate, useParams} from "react-router-dom";
import useTokenAndUserData from "../../../context/token/useTokenAndUserData";
import Ad from "../../ads/Ad";
import {getAllAmenities} from "../../../context/amenity/AmenityActions";
import QuestionCard from "./QuestionCard";
import {getAllQuestionsBySurvey} from "../../../context/survey/SurveyActions";

function QuestionsSurveyResult() {

    const navigate = useNavigate()
    let { id } = useParams();
    const { userData,token} = useTokenAndUserData()

    const [questions, setQuestions] = useState([]);

    useEffect(() => {

        const getData = async () => {
            if(userData.appId){
                const questions = await getAllQuestionsBySurvey(id,userData.appId);
                console.log(questions)
                setQuestions(questions);
            }
        }
        getData();

    }, [userData])

    // if(!questions) {

        return (

            <>
                <Ad/>

                <div className="text-center hero-content pt-0 pb-0 mb-5" >
                    <h1 className='text-3xl card-title'>Preguntas </h1>
                </div>

                <div className='w-full flex justify-center mb-8 gap-8 mt-9'>
                    <button onClick={() => navigate(`/survey/questions/${id}/create`)} className='mr-6 w-10/12 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg'>
                        Agregar pregunta
                    </button>

                </div>

                {questions && questions.length> 0 &&

                    <div className="p-2 h-screen ">
                        {/*<div className="overflow-auto rounded-lg shadow hidden md:block relative mt-2">*/}
                        {/*    <table className="w-full">*/}
                        {/*        <thead className="bg-gray-50 border-b-2 border-gray-200 dark:bg-slate-800 ">*/}
                        {/*        <tr>*/}
                        {/*            <th className="w-24 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Pregunta</th>*/}
                        {/*            <th className="w-24 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Typo</th>*/}
                        {/*            /!*<th className="w-16 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Final </th>*!/*/}
                        {/*            /!*<th className="w-16 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Disponible</th>*!/*/}
                        {/*            /!*<th className="w-24 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Actualizado por</th>*!/*/}
                        {/*        </tr>*/}
                        {/*        </thead>*/}
                        {/*        <tbody className="divide-y divide-gray-100 relative ">*/}
                        {/*        {questions.map(amenity => ( <SurveyRow key={amenity.id} amenity={amenity}/>))}*/}
                        {/*        </tbody>*/}
                        {/*    </table>*/}
                        {/*</div>*/}

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 ">
                            {questions.map(amenity => ( <QuestionCard key={amenity.id} amenity={amenity}/> ))}
                        </div>
                    </div>

                    // <div className="text-center text-lg">
                    //     <p className=''> {!existCollects && "No hay cobros relacionados"}</p>
                    // </div>

                }
            </>
        )

    // } else {
    //     return (
    //         <Spinner />
    //     )
    // }

}

export default QuestionsSurveyResult
