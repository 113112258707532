import { createContext, useReducer } from 'react'
import visitReducer from './VisitReducer'

const VisitContext = createContext()

export const VisitProvider = ({ children }) => {
  const initialState = {
    visits: [],
    currentPage: 1,
    previousPage: 0,
    lastPage: false,
    visit: {},
    visitors: [],
    addresses: [],
    loading: false,
  }

  const [state, dispatch] = useReducer(visitReducer, initialState)

  return <VisitContext.Provider value={{
    ...state,
    dispatch,
  }}>
    {children}
  </VisitContext.Provider>
}

export default VisitContext
