const noticeReducer = (state, action) =>{
    switch(action.type) {

        case 'GET_NOTICES':
            return {
                ...state,
                announcements: action.payload,
                loading: false,
            }
        case 'GET_NOTICE':
            return {
                ...state,
                announcement: action.payload,
                loading: false,
            }
        case 'ADD_NOTICE':
            return {
                ...state,
                newAnnouncement: action.payload,
                loading: false,
            }
        case 'GET_TYPES':
            return {
                ...state,
                types: action.payload,
                loading: false,
            }
        case 'SET_IS_UP':
            return {
                ...state,
                isUpNotice : action.payload
            }
        default:
            return state
    }
}

export default noticeReducer
