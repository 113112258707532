import axios from 'axios';
// const userServiceUrl = process.env.REACT_APP_USER_SERVICE || 'http://70.35.204.186:3000/pjd-cotoadmin-user'
const host = process.env.REACT_APP_HOST || window.location.origin
const service = `${host}${process.env.REACT_APP_AMENITY_SERVICE || '/amenity/api/v1/'}`

// const service = 'http://localhost:8090/amenity/api/v1/'

const instance = axios.create({
    baseURL: service,
})

export const addReservation = async (request, token) => {
    const response = await instance.post(`reservation/create`, request, {
        headers: {
            'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
        }
    });
    return response.data.response
}

export const approvalReservation = async (request, token) => {
    const response = await instance.post(`reservation/approval`, request, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.response
}

export const cancelReservation = async (request, token) => {
    if(window.confirm('¿Seguro que deseas cancelar la reservacion?')) {
        const response = await instance.post(`reservation/cancel`, request, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.response
    }

}

//solo admins
export const rejectReservation = async (request, token) => {
    if(window.confirm('¿Seguro que deseas rechazar la reservacion?')) {
        const response = await instance.post(`reservation/reject`, request, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.response
    }

}

export const getAllAmenities = async (appId, token) => {
    try {
        // console.log('Searching tags')
        const response = await instance.get(`amenity/all`,{
            params:{appId:appId},
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        });
        // console.log(response.data.response);
        return response.data.response;
    } catch (error) {
        console.log(error);
        throw new Error('Failed to retrieve days.');
    }
}

export const getAllActiveAmenities = async (appId, token) => {
    try {
        // console.log('Searching tags')
        const response = await instance.get(`amenity/all_amenities`,{
            params:{appId:appId},
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        });
        // console.log(response.data.response);
        return response.data.response;
    } catch (error) {
        console.log(error);
        throw new Error('Failed to retrieve days.');
    }
}

export const getSchedulerByAmenityId = async (id, token) => {
    try {

        const response = await instance.get(`schedule/all`,{
            params:{amenityId:id},
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        });

        return response.data.response;

    } catch (error) {
        console.log(error);
        throw new Error('Failed to retrieve hours.');
    }
}

export const getReservationById = async (id, appId, token) => {
    try {

        const response = await instance.get(`reservation/get_reservation`,{
            params:{
                id:id,
                appId
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        });

        return response.data.response;

    } catch (error) {
        console.log(error);
        throw new Error('Failed to retrieve reservations.');
    }
}

export const getReservationByAmenityId = async (id, appId, dayOfWeek, token) => {
    try {

        const response = await instance.get(`reservation/booked_reservations`,{
            params:{
                amenityId:id,
                appId, dayOfWeek
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        });

        return response.data.response;

    } catch (error) {
        console.log(error);
        throw new Error('Failed to retrieve reservations.');
    }
}

export const getReservations = async (appId, month, year, token) => {
    try {

        const response = await instance.get(`reservation/all_reservations`,{
            params:{
                appId,
                month,
                year
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.response;

    } catch (error) {
        console.log(error);
        throw new Error('Failed to retrieve reservations.');
    }
}

