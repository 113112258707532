import React, {useContext, useEffect, useState} from 'react'
import Spinner from '../layout/Spinner'
import RecurrentCollectContext from "../../context/RecurrentCollect/RecurrentCollectContext";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import {downloadFile, searchAllBalances} from "../../context/collect/CollectActions";
import ReportCard from "./ReportCard";
import Ad from "../ads/Ad";

function ReportResult() {

    const { loading, isUpRecurrent } = useContext( RecurrentCollectContext );
    const { userData } = useTokenAndUserData();
    const [reportBalance, setReportBalance] = useState();


    useEffect(() => {
        const getCollectsData = async () => {

            // console.log(userData.appId );
            // console.log(userData);
            // console.log(isUpRecurrent);
            if(userData.appId && userData.app.appName && isUpRecurrent){
                const report = await searchAllBalances(userData.appId, userData.app.appName);
                setReportBalance(report);
            }
        }
        getCollectsData();


    }, [userData, isUpRecurrent])


    const onClickDownloadFile = async () => {
        const response = await downloadFile(userData.appId, userData.app.appName);

        if (response.status === 200) {
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = userData.app.appName + '.xlsx';
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }


    if(!loading) {
        return (<div className="p-2 h-screen ">

                <Ad/>

                <div className="text-center hero-content pt-0 pb-0 mb-5">
                    <h1 className='text-3xl card-title'>Reportes</h1>
                </div>

                {reportBalance ?
                    <>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <ReportCard key={reportBalance.appName} report={reportBalance} onClickDownload={onClickDownloadFile} />
                        </div>

                    </>
                    :
                    <div className="text-center text-lg">
                        <p className=''>{isUpRecurrent ? "No hay reportes" : "Por el momento el servicio no está disponible"}</p>
                    </div>
                }

            </div>

        )
    } else {
        return (
            <Spinner />
        )
    }
}

export default ReportResult
