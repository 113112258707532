import {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from "dayjs";
import useTokenAndUserData from "../../../context/token/useTokenAndUserData";
import AlertContext from "../../../context/alert/AlertContext";
import {getTypes} from "../../../context/survey/SurveyActions";
import Ad from "../../ads/Ad";
function AddQuestion() {

    const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
    const { userData, token } = useTokenAndUserData();
    let { id } = useParams();

    const [question, setQuestion] = useState('');
    const [typeQuestion, setTypeQuestion] = useState('');
    const [types, setTypes] = useState('');
    const [answers, setAnswers] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            const types = await getTypes();
            console.log(types);
            setTypes(types);

        }
        getData();
    }, [token]);

    const handleChangeQuestion = (e) => setQuestion(e.target.value);
    const handleChangeCategory = (e) => {
        setTypeQuestion(e.target.value)
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        if(question.length <= 0 ) {
            setAlert('Por favor ingresa todos los datos requeridos', 'error')

        } else {
            alertDispatch({ type: 'SET_LOADING' })

            const creationRequest = {
                surveyId: id,
                question,
                questionType: typeQuestion,
                answers : answers
            }

            console.log(creationRequest);

            // const add = await addAmenity(creationRequest,token);
            // console.log(add);

            // dispatch({ type: 'ADD_FILE', payload: newFile });
            // dispatch({ type: 'GET_FILES', payload: []});
            //
            navigate(`/surveys`);
        }
    }

    return (
        <div className="w-full mx-auto lg:w-10/12">
            <Ad/>
            <div className="text-center hero-content">
                <h1 className='text-3xl card-title'>Agregar pregunta</h1>
            </div>

            <form onSubmit={handleSubmit}>
                <div className='form-control'>

                    <label className='mt-4 text-lg'>
                        Pregunta * :
                        <input
                            className="mt-2 w-full pr-6 bg-gray-200 input input-lg text-black"
                            id="file_input"
                            type='text'
                            placeholder='Nombre'
                            onChange={handleChangeQuestion} />

                    </label>

                    <label className='mt-4 text-lg'>
                        Tipo :

                        <select
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Categoria del anuncio'
                            // value={typeId}
                            onChange={handleChangeCategory}
                        >
                            <option defaultValue key='' value=''>Selecciona una opción</option>

                            {types &&
                                Object.entries(types).map(([key, value]) => (
                                    <option key={key} value={key}>
                                        {value}
                                    </option>
                            ))}
                        </select>
                    </label>


                    <div className="mt-6 card-actions">
                        <button type='submit' className='btn btn-outline'>
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddQuestion
