import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import { useContext } from 'react'

import Notices from './pages/Notices'
import Login from './pages/Login'
import About from './pages/About'
import Help from './pages/Help'
// import Home from './pages/Home'
// import NotFound from './pages/NotFound'

import Addresses from './components/address/Addresses'
import Address from './components/address/Address'
import AddAddress from './components/address/AddAddress'
import UpdateAddress from './components/address/UpdateAddress'

import Visits from './components/visits/Visits'
import Visit from './components/visits/Visit'
import AddVisit from './components/visits/AddVisit'
import UpdateVisitor from './components/visitors/UpdateVisitor'

import Users from './components/users/Users'
import User from './components/users/User'
import AddUser from './components/users/AddUser'
import UpdateUser from './components/users/UpdateUser'
import UpdatePass from './components/users/UpdatePass'
import RestorePass from './components/users/RestorePass'

import Visitors from './components/visitors/Visitors'
import Visitor from './components/visitors/Visitor'
import AddVisitor from './components/visitors/AddVisitor'

import Reservations from './components/reservations/Reservations'
import Payments from './components/collects/Payments'

import PrivateRoute from './components/PrivateRoute'
import Navbar from './components/layout/Navbar'
// import Footer from './components/layout/Footer'

import Alert from './components/layout/Alert'
import { AddressProvider } from './context/address/AddressContext'
import { VisitProvider } from './context/visits/VisitContext'
import { VisitorProvider } from './context/visitors/VisitorContext'
import { AlertProvider } from './context/alert/AlertContext'
import { validateRoles } from './context/login/LoginActions'
import LoginContext from './context/login/LoginContext'
import {TokenProvider} from "./context/token/TokenContext";
import useToken from "./context/login/useToken";
import AddNotice from "./components/notice/AddNotice";
import {NoticeProvider} from "./context/notice/NoticeContext";
import UpdateNotice from "./components/notice/UpdateNotice";
import NoticeItem from "./components/notice/NoticeItem";
import {CollectProvider} from "./context/collect/CollectContext";
import {RecurrentCollectProvider} from "./context/RecurrentCollect/RecurrentCollectContext";
import RecurrentCollects from "./components/RecurrentCollects/RecurrentCollects";
import AddRecurrentCollect from "./components/RecurrentCollects/AddRecurentCollect";
import AddCollectToUnit from "./components/collects/AddCollectToUnit";
import AddCollect from "./components/collects/AddCollect";
import CollectsResult from "./components/collects/CollectsResult";
import UpdateRecurrentCollect from "./components/RecurrentCollects/UpdateRecurrentColect";
import {AdsProvider} from "./context/ads/AdsContext";
import AddAd from "./components/ads/AddAd";
import UpdateAd from "./components/ads/UpdateAd";
import CollectInfo from "./components/collects/CollectInfo";
import Report from "./components/report/DownloadReports";
import Files from "./components/Files/Files";
import AddFile from "./components/Files/AddFile";
import {FileProvider} from "./context/files/FileContext";
import UpdateFile from "./components/Files/UpdateFile";
import AmenityResult from "./components/amenity/AmenityResult";
import AddAmenity from "./components/amenity/AddAmenity";
import ReservationResult from "./components/reservations/ReservationResult";
import AddReservation from "./components/reservations/AddReservation";
import ReservationApproval from "./components/reservations/ReservationApproval";
import ReservationRejectInfo from "./components/reservations/ReservationRejectInfo";
import ReservationCancel from "./components/reservations/CancelReservation";
import {ReservationProvider} from "./context/reservation/ReservationContext";
import UpdateAmenity from "./components/amenity/UpdateAmenity";
import SurveyResult from "./components/survey/SurveyResult";
import AddSurvey from "./components/survey/AddSurvey";
import QuestionsSurveyResult from "./components/survey/question/QuestionsSurveyResult";
import AddQuestion from "./components/survey/question/AddQuestion";

function App() {
  const { settings } = useContext(LoginContext)
  const { userData, token, setToken, setUser } = useToken()

  return (
      <TokenProvider>
        <AdsProvider>
        <AddressProvider>
          <VisitorProvider>
            <VisitProvider>
              <AlertProvider>
                <NoticeProvider>
                  <CollectProvider>
                    <RecurrentCollectProvider>
                      <FileProvider>
                        <ReservationProvider>
                      <Router>
                        <div className='flex flex-col h-screen'>
                          <Navbar />
                          <main className='container mx-auto px-3 pb-12'>
                            <Alert />
                            <Routes>
                              <Route path='/' element={ <Login setToken={setToken} setUser={setUser} /> } />
                              {token && (
                                  <>

                                    {/* USERS ROUTES */}
                                    <Route path='/home' element={<PrivateRoute />}>
                                      <Route path='/home' element={<Notices />} />
                                    </Route>

                                    <Route path='/notice' element={<PrivateRoute />}>
                                      <Route path='/notice' element={<Notices />} />
                                    </Route>

                                    <Route path='/notice/update/:id' element={<PrivateRoute />}>
                                      <Route path='/notice/update/:id' element={ <UpdateNotice />} />
                                    </Route>

                                    <Route path='/notice/:id' element={<PrivateRoute />}>
                                      <Route path='/notice/:id' element={ <NoticeItem />} />
                                    </Route>

                                    <Route path='/notice/create' element={<PrivateRoute />}>
                                      <Route path='/notice/create' element={<AddNotice />} />
                                    </Route>

                                    <Route path='/users' element={<PrivateRoute />}>
                                      <Route path='/users' element={<Users />} />
                                    </Route>
                                    <Route path='/users/:userId' element={<PrivateRoute />}>
                                      <Route path='/users/:userId' element={ <User />} />
                                    </Route>
                                    <Route path='/users/:userId/:pass' element={<PrivateRoute />}>
                                      <Route path='/users/:userId/:pass' element={ <User />} />
                                    </Route>
                                    <Route path='/users/create' element={<PrivateRoute />}>
                                      <Route path='/users/create' element={ <AddUser />} />
                                    </Route>
                                    <Route path='/users/:userId/update' element={<PrivateRoute />}>
                                      <Route path='/users/:userId/update' element={ <UpdateUser />} />
                                    </Route>

                                    {/* FILES ROUTES */}
                                    <Route path='/files' element={<PrivateRoute />}>
                                      <Route path='/files' element={<Files />} />
                                    </Route>
                                    <Route path='/files/create' element={<PrivateRoute />}>
                                      <Route path='/files/create' element={<AddFile />} />
                                    </Route>
                                    <Route path='/files/update/:fileId' element={<PrivateRoute />}>
                                      <Route path='/files/update/:fileId' element={<UpdateFile />} />
                                    </Route>


                                    {/* ADDRESS ROUTES */}
                                    <Route path='/addresses' element={<PrivateRoute />}>
                                      <Route path='/addresses' element={<Addresses />} />
                                    </Route>
                                    <Route path='/address/:addressId' element={<PrivateRoute />}>
                                      <Route path='/address/:addressId' element={ <Address />} />
                                    </Route>
                                    <Route path='/address/create' element={<PrivateRoute />}>
                                      <Route path='/address/create' element={ <AddAddress />} />
                                    </Route>
                                    <Route path='/address/:addressId/update' element={<PrivateRoute />}>
                                      <Route path='/address/:addressId/update' element={ <UpdateAddress />} />
                                    </Route>
                                    {/* VISITS ROUTES */}
                                    <Route path='/visits' element={<PrivateRoute />}>
                                      <Route path='/visits' element={<Visits />} />
                                    </Route>
                                    <Route path='/visit/create' element={<PrivateRoute />}>
                                      <Route path='/visit/create' element={ <AddVisit />} />
                                    </Route>
                                    <Route path='/visit/:visitCode' element={<PrivateRoute />}>
                                      <Route path='/visit/:visitCode' element={ <Visit />} />
                                    </Route>
                                    {/* VISITORS ROUTES */}
                                    <Route path='/visitors' element={<PrivateRoute />}>
                                      <Route path='/visitors' element={<Visitors />} />
                                    </Route>
                                    <Route path='/visitors/:visitorId' element={<PrivateRoute />}>
                                      <Route path='/visitors/:visitorId' element={ <Visitor />} />
                                    </Route>
                                    <Route path='/visitors/create' element={<PrivateRoute />}>
                                      <Route path='/visitors/create' element={ <AddVisitor />} />
                                    </Route>
                                    <Route path='/visitors/:visitorId/update' element={<PrivateRoute />}>
                                      <Route path='/visitors/:visitorId/update' element={ <UpdateVisitor />} />
                                    </Route>

                                    {/* ---------------- collects   ----------------*/}
                                    <Route path='/collects' element={<PrivateRoute />}>
                                      <Route path='/collects' element={<Payments />} />
                                    </Route>

                                    <Route path='/collects/create' element={<PrivateRoute />}>
                                      <Route path='/collects/create' element={<AddCollect />} />
                                    </Route>

                                    <Route path='/collects/:id/:nameUnit' element={<PrivateRoute />}>
                                      <Route path='/collects/:id/:nameUnit' element={<CollectsResult />} />
                                    </Route>

                                    <Route path='/collects/:id/:nameUnit/create' element={<PrivateRoute />}>
                                      <Route path='/collects/:id/:nameUnit/create' element={<AddCollectToUnit />} />
                                    </Route>

                                    <Route path='/collects/transaction/:id/:nameUnit' element={<PrivateRoute />}>
                                      <Route path='/collects/transaction/:id/:nameUnit' element={<CollectInfo />} />
                                    </Route>

                                    {/* ---------------- Recurrent payments   ----------------*/}
                                    <Route path='/recurrent_collects' element={<PrivateRoute />}>
                                      <Route path='/recurrent_collects' element={<RecurrentCollects />} />
                                    </Route>

                                    <Route path='/recurrent_collects/create' element={<PrivateRoute />}>
                                      <Route path='/recurrent_collects/create' element={<AddRecurrentCollect />} />
                                    </Route>

                                    <Route path='/recurrent_collects/:id/update' element={<PrivateRoute />}>
                                      <Route path='/recurrent_collects/:id/update' element={<UpdateRecurrentCollect />} />
                                    </Route>

                                    {/* ---------------- Amenity  ----------------*/}
                                    <Route path='/amenity' element={<PrivateRoute />}>
                                      <Route path='/amenity' element={<AmenityResult />} />
                                    </Route>

                                    <Route path='/amenity/create' element={<PrivateRoute />}>
                                      <Route path='/amenity/create' element={<AddAmenity />} />
                                    </Route>

                                    <Route path='/amenity/update/:id' element={<PrivateRoute />}>
                                      <Route path='/amenity/update/:id' element={<UpdateAmenity />} />
                                    </Route>

                                    {/* ---------------- Reservations ---------------- */}

                                    <Route path='/reservation' element={<PrivateRoute />}>
                                      <Route path='/reservation' element={<ReservationResult />} />
                                    </Route>

                                    <Route path='/reservation/create' element={<PrivateRoute />}>
                                      <Route path='/reservation/create' element={<AddReservation />} />
                                    </Route>

                                    <Route path='/reservations/approved/:id' element={<PrivateRoute />}>
                                      <Route path='/reservations/approved/:id' element={<ReservationApproval />} />
                                    </Route>

                                    <Route path='/reservations/reject/:id' element={<PrivateRoute />}>
                                      <Route path='/reservations/reject/:id' element={<ReservationRejectInfo />} />
                                    </Route>

                                    <Route path='/reservations/cancel/:id' element={<PrivateRoute />}>
                                      <Route path='/reservations/cancel/:id' element={<ReservationCancel />} />
                                    </Route>
                                    {/* ---------------- Ads   ----------------*/}
                                    {/*<Route path='/recurrent_collects' element={<PrivateRoute />}>*/}
                                    {/*  <Route path='/recurrent_collects' element={<RecurrentCollects />} />*/}
                                    {/*</Route>*/}

                                    <Route path='/ads/create' element={<PrivateRoute />}>
                                      <Route path='/ads/create' element={<AddAd />} />
                                    </Route>

                                    <Route path='/ads/:id/update' element={<PrivateRoute />}>
                                      <Route path='/ads/:id/update' element={<UpdateAd />} />
                                    </Route>

                                    {/* ---------------- Report   ----------------*/}
                                    <Route path='/reports' element={<PrivateRoute />}>
                                      <Route path='/reports' element={<Report />} />
                                    </Route>

                                    {/* ---------------- Survey  ----------------*/}
                                    <Route path='/survey' element={<PrivateRoute />}>
                                      <Route path='/survey' element={<SurveyResult />} />
                                    </Route>

                                    <Route path='/survey/create' element={<PrivateRoute />}>
                                      <Route path='/survey/create' element={<AddSurvey />} />
                                    </Route>

                                    <Route path='/survey/questions/:id' element={<PrivateRoute />}>
                                      <Route path='/survey/questions/:id' element={<QuestionsSurveyResult />} />
                                    </Route>

                                    <Route path='/survey/questions/:id/create' element={<PrivateRoute />}>
                                      <Route path='/survey/questions/:id/create' element={<AddQuestion />} />
                                    </Route>



                                    {/* NEW MODULE ROUTES TO TEST SETTING FUNTIONALITY */}
                                    {/*{settings.RESERVATION && (*/}
                                    {/*    */}
                                    {/*)}*/}


                                    {/* END NEW MODULE ROUTES TO TEST SETTING FUNTIONALITY */}
                                    {/* OTHER ROUTES */}
                                    {!validateRoles(userData.permissions, 'isGuard') && (
                                        <Route path='/password' element={<PrivateRoute />}>
                                          <Route path='/password' element={ <UpdatePass />} />
                                        </Route>
                                    )}
                                  </>
                              )}
                              {/* OTHER ROUTES */}
                              <Route path='/help' element={ <Help />} />
                              <Route path='/about' element={ <About />} />
                              <Route path='/password/:userToken/restore' element={ <RestorePass />} />
                              {/* <Route path='/notfound' element={ <NotFound />} />
                    <Route path='/*' element={ <NotFound />} /> */}

                              <Route path='/*' element={ <Login setToken={setToken} setUser={setUser} />} />

                            </Routes>
                          </main>
                          {/* <Footer /> */}
                        </div>
                      </Router>
                        </ReservationProvider>

                      </FileProvider>
                    </RecurrentCollectProvider>
                    </CollectProvider>
                </NoticeProvider>
              </AlertProvider>
            </VisitProvider>
          </VisitorProvider>
        </AddressProvider>
        </AdsProvider>
      </TokenProvider>
  );
}

export default App;
