export function formatValue(value, first) {
    const prefix = first ? '' : ' - '
    if(value === null || value === '' || !value)
    {
        return ''
    }
    return `${prefix}${value}`
}

export function getColorType(value){

    switch (value) {
        case 'noticia':
            return "text-blue-800 bg-blue-200";

        case 'recordatorio':
            return "text-green-800 bg-green-200";

        case 'emergencia':
            return "text-red-800 bg-red-200";

        case 'evento':
            return "text-indigo-800 bg-indigo-200";

        case 'mantenimiento':
            return "text-lime-800 bg-lime-200";

        default:
            return "text-blue-800 bg-blue-200"
    }

}

export function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}


