import axios from 'axios'
// const visitServiceUrl = process.env.REACT_APP_VISIT_SERVICE || 'http://70.35.204.186:3000/pjd-cotoadmin-visit'
// const userServiceUrl = process.env.REACT_APP_USER_SERVICE || 'http://70.35.204.186:3000/pjd-cotoadmin-user'
const host = process.env.REACT_APP_HOST || window.location.origin
const visitServiceUrl = `${host}${process.env.REACT_APP_VISIT_SERVICE || '/visit'}`
const userServiceUrl = `${host}${process.env.REACT_APP_USER_SERVICE || '/user'}`

const db = axios.create({
  baseURL: visitServiceUrl,
})

const userService = axios.create({
  baseURL: userServiceUrl,
})

// Search code on the DB
export const searchVisits = async ({ keywords = '', pageSize = 10, page = 1, token }) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const params = new URLSearchParams({
    keywords,
    pageSize,
    page,
  })

  const response = await db.get(`/api/v1/visits?${params}`, config)
  return response.data
}

// Get visit details
export const getVisit = async (visitCode, token) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  try {
    const response = await db.get(`/api/v1/visits/${visitCode}`, config)
    // console.log(response.data);
    return response.data.data
  } catch (error) {
    return { visitCode }
  }
}

// Create new visit
export const addVisit = async (data, token) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json'
    }
  }

  const response = await db.post(`/api/v1/visits`, data, config)
  return response.data.data || response.data.message
}

// Create new visit
export const updateVisit = async (visitCode, data, token) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const response = await db.patch(`/api/v1/visits/${visitCode}`, data, config)
  return response.data.data
}

// Get Visitors and addresses
export const getVisitorsAndAddress = async (token) => {
  const params = new URLSearchParams({
    pageSize: 2000
  })

  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const [visitors, addresses] = await Promise.all([
    db.get(`/api/v1/visitors?${params}`, config),
    userService.get(`/api/v1/addresses?${params}`, config)
  ])

  return {
    visitors: visitors.data.data,
    addresses: addresses.data.data,
  }
}

// Get Visitors of the user
export const getVisitors = async (token) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const response = await db.get(`/api/v1/visitors`, config)
  return response.data.data
}

// Get Addresses of the user
export const getAddresses = async (token) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const response = await db.get(`/api/v1/addresses`, config)
  return response.data.data
}

// Delete visit
export const deleteVisit = async (visitCode, token) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  if(window.confirm('¿Seguro que deseas remover el QR?')) {    
    const response = await db.delete(`/api/v1/visits/${visitCode}`, config)
    return response.data.data
  }
  return false
}
