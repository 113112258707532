import {createRef, useState} from 'react'
import { useScreenshot, createFileName } from 'use-react-screenshot'
import { MdDownload } from 'react-icons/md'
import { FaUser } from 'react-icons/fa'
import { useEffect, useContext } from 'react'
import { useParams /*, useNavigate*/ } from 'react-router-dom'
import LoginContext from '../../context/login/LoginContext'
import AlertContext from '../../context/alert/AlertContext'
import Spinner from '../layout/Spinner'
import { getUser, updateUser /*, getTempPassword, updateAddress*/ } from '../../context/login/LoginActions'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import Ad from "../ads/Ad";

function User() {
  // const navigate = useNavigate()
  const { user, loading, dispatch } = useContext(LoginContext)
  const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
  const { userData,token} = useTokenAndUserData()
  const [isAdmin, setIsAdmin] = useState(false);
  // const navigate = useNavigate()
  const ref = createRef(null)
  const [image, takeScreenShot] = useScreenshot({
    type: 'image/jpeg',
    quality: 1.0
  })

  const params = useParams()

  function formatValue(value) {
    return `${value.replaceAll('null', '')}`
  }

  useEffect(() => {
    alertDispatch({ type: 'REMOVE_ALERT'})
    dispatch({ type: 'SET_LOADING' })

    const app_id = userData.appId;

    setIsAdmin(userData.permissions.includes('ADMIN'))
    const getUserData = async () => {

      const userData = await getUser(params.userId, token, app_id)
      // console.log(userData);
      dispatch({ type: 'GET_USER', payload: userData })
      // dispatch({ type: 'SET_PASS', payload: params.pass })
    }

    getUserData()
  }, [dispatch, params.userId, token, alertDispatch, userData])

  // const handleUpdateActive = async (e) => {
  //   e.preventDefault()

  //   if(params.addressId !== '') {
  //     dispatch({ type: 'SET_LOADING' })
  //     const addressData = await updateAddress(params.addressId, { active: !address.active }, token)
      
  //     dispatch({ type: 'GET_ADDRESS', payload: {
  //         ...address,
  //         active: !address.active
  //       }
  //     })

  //     // navigate(`/addresses`)
  //   }
  // }

  const handleResetPass = async (e) => {
    e.preventDefault()

    dispatch({ type: 'SET_LOADING' })
    // const userData = await getTempPassword()

    const newUser = await updateUser(params.userId, {
      userPass: 'userPass',
    }, token)

    if (Object.prototype.hasOwnProperty.call(newUser, 'userEmail')) {
      dispatch({ type: 'GET_USER', payload: newUser })
      setAlert(`La nueva contraseña ha sido enviada al correo: ${newUser.userEmail}`, 'info')
    } else if(Object.prototype.hasOwnProperty.call(newUser, 'error')) {
      setAlert(`El usuario no fue notificado porque hubo un error en el servicio de notificaciones. Favor de contactar a soporte.`, 'error2')
    } else {
      setAlert(newUser, 'error')
    }
  }

  const download = (image, { name = 'img', extension = 'jpg' } = {}) => {
    const a = document.createElement('a')
    a.href = image
    a.download = createFileName(extension, name)
    a.click()
  }

  const handleShare = async (e) => {
    e.preventDefault()
    takeScreenShot(ref.current).then(download)
  }

  // const handleUdateActiveButton = (active, permissions) => {
  //   if(active & validateRoles(permissions, 'addresses')) return true
  //   return false
  // }

  const {
    // userId,
    // appId,
    userName,
    userEmail,
    userRole,
    userRoleName,
    comment,
    active,
    addressesName,
    userPermissions
    // createdOn,
    // createdBy,
    // updatedOn,
    // updatedBy
    } = user

  if(loading) {
    return <Spinner />
  } else {
    return (<>
      <div className="w-full mx-auto lg:w-10/12">
        <Ad/>

      <div className="mb-4">
        {params.pass && (
          <button onClick={handleShare} className='btn btn-outline' >
            <MdDownload className='mr-2'/> Descargar Información
          </button>
        )}

        {false && (<img src={image} alt={'Screenshot'} />)}
      </div>

        <div ref={ref} className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 pt-4 pb-4 md:grid-cols-3 mb-2 md:gab-8 flex">
          <div className="flex align-items-center justify-center custom-card-image mb-6 md:mb-0">
            <div className="rounded-lg">
              <figure>
                <FaUser className='w-60 h-60' />
              </figure>
            </div>
          </div>
  
          <div className="col-span-2 px-8">
            <div className="mb-6">
              <h1 className='text-3xl card-title'>
              {userName}
              </h1>
              <p>{userEmail && (`Correo: ${userEmail}`)}</p>
              {params.pass && (<p>Contraseña Temporal: {params.pass}</p>)}
              <p>{ userRoleName }</p>


              {userRole !== 'ADMIN' && addressesName && (
                addressesName.length > 0 && (
                <>
              {/* <p>Direcciones: { addressesName.join(', ') }</p> */}
                <p>Unidades:</p>
              {addressesName.map((address) => (
                <p key={address} className='mx-4'>{formatValue(address)}</p>
                ))}
                </>
                )
                )}


              {userPermissions && (
                  <>
                    <p>Permisos:</p>
                    {userPermissions.map((permission) => (
                        <p key={permission.permissionId} className='mx-4'>{permission.permissionDescription}</p>
                    ))}
                  </>
                )
              }

              <p>{ comment }</p>
              {/* <div className="mt-4 card-actions">
                {handleUpdateActiveButton(active, userData.permissions) && (
                  <button onClick={handleUpdateActive} className='btn btn-outline'>
                    Cambiar Estado
                  </button>
                )}
              </div> */}

              {!params.pass && (
                <div className="mt-4 card-actions">
                  <button onClick={handleResetPass} className='btn btn-outline'>
                    Generar nueva contraseña
                  </button>
                </div>
              )}
            </div>
  
            <div className="grid grid-rows-2 xl:grid-rows-1 lg:grid-rows-1 md:grid-rows-1 sd:grid-rows-1 py-5 mb-6 rounded-lg shadow-md bg-base-100 stats">
              {active && (
                <div className='stat'>
                  <div className="stat-title text-md">Estado</div>
                  <div className="text-lg stat-value">{active ? ('Activo') : ('Inactivo')}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>   
    </>)
  }
}

export default User
