import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import VisitContext from '../../context/visits/VisitContext'
import AlertContext from '../../context/alert/AlertContext'
import { searchVisits } from '../../context/visits/VisitActions'
import {validatePermissions} from '../../context/login/LoginActions'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import Ad from "../ads/Ad";
import VisitorContext from "../../context/visitors/VisitorContext";

function VisitSearch() {
  const [keywords, setKeywords] = useState('')

  const { userData, token } = useTokenAndUserData()
  const { dispatch, visits: visitsList, currentPage, previousPage, lastPage } = useContext(VisitContext)
  const { isUpVisitor } = useContext(VisitorContext)
  const { dispatch: alertDispatch } = useContext(AlertContext)

  const navigate = useNavigate()

  useEffect(() => {
    alertDispatch({ type: 'REMOVE_ALERT'})
    dispatch({ type: 'SET_LOADING' })
    const getVisitsData = async () => {
      const visits = await searchVisits({ keywords, page: currentPage, token })
      console.log(visits);

      if (!visits.paging.hasMore) {
        dispatch({ type: 'GET_LAST_PAGE', payload: true })
        // return;
      }
      dispatch({ type: 'GET_PREV_PAGE', payload: currentPage })
      dispatch({ type: 'GET_VISITS', payload: [...visitsList, ...visits.data] })
      // setKeywords('')
    }

    if (!lastPage && previousPage !== currentPage && isUpVisitor) {
      getVisitsData()
    }

  }, [dispatch, token, alertDispatch, visitsList, currentPage, previousPage, lastPage, keywords])

  const handleChange = (e) => setKeywords(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()
    dispatch({ type: 'GET_VISITS', payload: []})
    dispatch({ type: 'GET_LAST_PAGE', payload: false })
    dispatch({ type: 'GET_PREV_PAGE', payload: 0 })
    dispatch({ type: 'GET_CURR_PAGE', payload: 1 })
    dispatch({ type: 'SET_LOADING' })
    const visits = await searchVisits({ page: 1, keywords, token })
    if (!visits.paging.hasMore) {
      dispatch({ type: 'GET_LAST_PAGE', payload: true })
      // return;
    }
    dispatch({ type: 'GET_PREV_PAGE', payload: currentPage })
    dispatch({ type: 'GET_VISITS', payload: visits.data })
  }

  return (
    <div className="w-full mx-auto lg:w-10/12">

      <Ad/>

      <div className="text-center hero-content pt-0 pb-0">
        <h1 className='text-3xl card-title'>Accesos</h1>
      </div>

      <div className='mb-8 gap-8 mt-9'>
        <div>
          <form onSubmit={handleSubmit}>
            <div className='form-control'>
              <div className='relative'>
                {isUpVisitor ?
                    <>
                      <input
                          type='text'
                          className='w-full pr-4 bg-gray-200 text-black input input-md xl:input-lg lg:input-lg md:input-lg'
                          placeholder='Codigo'
                          value={keywords}
                          onChange={handleChange}
                      />

                      <button type='submit' className='absolute top-0 right-0 rounded-l-none w-36 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg' >
                        Buscar
                      </button> </>
                    :
                    <div className="text-center text-lg">
                      <h1 >Por el momento el servicio no está disponible.</h1>
                    </div>
                }
              </div>


            </div>
          </form>
        </div>

        {/* {visits.length > 0 && (
          <div>
            <button onClick={() => dispatch({ type: 'CLEAR_VISITS'})} className='btn btn-ghost btn-lg'>
              Limpiar Busqueda
            </button>
          </div>
        )} */}
      </div>


      {validatePermissions(userData.userPermissions, 'ACCESS') && (
        <div className='w-full flex justify-center mb-8 gap-8 mt-9'>
          {isUpVisitor &&
              <>

                <button onClick={() => navigate(`/visitors/create`)} className='mr-6 w-5/12 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg' >
                  Alta Visitante
                </button>

                <button onClick={() => navigate(`/visitors`)} className='w-5/12 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg'>
                  Ver Visitantes
                </button>

              </>

          }

        </div>
      )}
    </div>
  )
}

export default VisitSearch
