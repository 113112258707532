import {useContext, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {FaDollarSign, FaTimes} from 'react-icons/fa'
import { BsFillHouseDoorFill } from 'react-icons/bs'
import { deleteAddress } from '../../context/address/AddressActions'
import AddressContext from '../../context/address/AddressContext'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import RecurrentCollectContext from "../../context/RecurrentCollect/RecurrentCollectContext";

function AddressItem({ address: { addressId, addressUnit, addressBuilding, addressIntNum, addressPhone, users } }) {
  const { isUpRecurrent } = useContext( RecurrentCollectContext );
  const { addresses, dispatch } = useContext(AddressContext)
  const [unitName, setUnitName] = useState('');
  const { token } = useTokenAndUserData()

  const navigate = useNavigate()

  const handleDelete = async () => {
    if(await deleteAddress(addressId, token)) {
      dispatch({ type: 'GET_ADDRESSES', payload: addresses.filter((item) => item.addressId !== addressId ) })
    }
  }

  useEffect(() => {
    setUnitName(addressUnit + ` ${addressBuilding}` + ` ${addressIntNum}`);

  }, []);


  return (
    <div className='card shadow-md compact side bg-base-100'>

      <div className="grid grid-cols-6 xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-6 flex-row items-center space-x-4 card-body">
        <Link to={`/address/${addressId}/update`} className='col-span-1'>
          <div>
            <div className="rounded-full w-16 h-16">
              <BsFillHouseDoorFill className='text-6xl md:text-5xl' />
            </div>
          </div>
        </Link>

        <Link to={`/address/${addressId}/update`} className='col-span-3'>
          <div>
            <h2 className='card-title'>
            {unitName}
            </h2>
            <h3 className='text-base-content text-opacity-40'>{addressPhone}</h3>
          </div>
        </Link>

        <div className="flex flex-row gap-4">

          <div className='flex col-span-2 justify-end xl:col-span-1 lg:col-span-1 md:col-span-1'>

            {isUpRecurrent ? <button onClick={() => navigate(`/collects/${addressId}/${unitName}`)}
              className='edite tooltip rounded-full'
              data-tip='Agregar transaction'>
              <FaDollarSign className='text-4xl md:text-3xl' color='primary ' />
              </button>
            : <button disabled={true}
                      className='edite tooltip rounded-full'
                      data-tip='Cobranza recurrente no disponible'>
                  <FaDollarSign className='text-4xl md:text-3xl' color='' />
                </button>
            }


          </div>

          {users <= 0 && (
              <div className='flex col-span-2 justify-end '>
                <button onClick={handleDelete}
                        className='close tooltip rounded-full'
                        data-tip='Remover Unidad'>
                  <FaTimes className='text-4xl md:text-3xl' color='primary '/>
                </button>
              </div>
          )}

        </div>


      </div>
    </div>
  )
}
export default AddressItem
