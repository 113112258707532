import { HiIdentification } from 'react-icons/hi'
import { useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import VisitorContext from '../../context/visitors/VisitorContext'
import AlertContext from '../../context/alert/AlertContext'
import Spinner from '../layout/Spinner'
import { getVisitor/*, updateVisitor*/ } from '../../context/visitors/VisitorActions'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";

function Visitor() {
  const { token } = useTokenAndUserData()
  const { visitor, loading, dispatch } = useContext(VisitorContext)
  const { dispatch: alertDispatch } = useContext(AlertContext)

  const params = useParams()

  useEffect(() => {
    alertDispatch({ type: 'REMOVE_ALERT'})
    dispatch({ type: 'SET_LOADING' })
    const getVisitorData = async () => {
      const visitorData = await getVisitor(params.visitorId, token)
      dispatch({ type: 'GET_VISITOR', payload: visitorData })
    }

    getVisitorData()
  }, [dispatch, params.visitorId, token, alertDispatch])

  const validateAdditionalComments = (blacklist, blacklistComment) => {
    if(blacklist && blacklistComment) return true
    return false
  }

  // const handleUpdateActive = async (e) => {
  //   e.preventDefault()

  //   if(params.visitorId !== '') {
  //     dispatch({ type: 'SET_LOADING' })
  //     const addressData = await updateVisitor(params.visitorId, { active: !visitor.active }, token)
      
  //     dispatch({ type: 'GET_VISITOR', payload: {
  //         ...visitor,
  //         active: !visitor.active
  //       }
  //     })

  //     // navigate(`/visitors`)
  //   }
  // }

  // const handleUdateActiveButton = (active, permissions) => {
  //   if(active & validateRoles(permissions, 'visitors')) return true
  //   return false
  // }

  const {
    // visitorId,
    // appId,
    visitorName,
    visitorPlates,
    // visitorTrustLevel,
    description,
    recurrent,
    provider,
    blacklist,
    blacklistComment,
    active,
    // createdOn,
    // createdBy,
    // updatedOn,
    // updatedBy
    } = visitor

  if(loading) {
    return <Spinner />
  } else {
    return (<>
      <div className="w-full mx-auto lg:w-10/12">
        <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 mt-4 md:grid-cols-3 mb-2 md:gab-8 flex">
          <div className="flex align-items-center justify-center custom-card-image mb-6 md:mb-0">
            <div className="rounded-lg">
              <figure>
                <HiIdentification className='w-60 h-60' />
              </figure>
            </div>
          </div>
  
          <div className="col-span-2 px-8">
            <div className="mb-6">
              <h1 className='text-3xl card-title'>
              {visitorName}
              </h1>
              {visitorPlates && (<p>Placas: {visitorPlates}</p>)}
              {provider && ( <p>Proveedor</p>)}
              {recurrent && ( <p>Visitante Recurrente</p>)}
              {blacklist && ( <p>Black List</p>)}
              {validateAdditionalComments(blacklist, blacklistComment) && ( <p>{ blacklistComment }</p> )}
              {description && ( <p>{ description }</p>)}

              {/* <div className="mt-4 card-actions">
                {handleUdateActiveButton(active, userData.permissions) && (
                  <button onClick={handleUpdateActive} className='btn btn-outline'>
                    Cambiar Estado
                  </button>
                )}
              </div> */}
            </div>
  
            <div className="grid grid-rows-2 xl:grid-rows-1 lg:grid-rows-1 md:grid-rows-1 sd:grid-rows-1 py-5 mb-6 rounded-lg shadow-md bg-base-100 stats">
              {active && (
                <div className='stat'>
                  <div className="stat-title text-md">Estado</div>
                  <div className="text-lg stat-value">{active ? ('Activo') : ('Inactivo')}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>   
    </>)
  }
}

export default Visitor
