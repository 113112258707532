import {BsFillFileEarmarkExcelFill} from "react-icons/bs";

function ReportCard ({ report, onClickDownload} ) {

    return (
        <>
            <div className='card shadow-md compact side bg-base-100 cursor-pointer'  onClick={onClickDownload}>

                <div className="grid grid-cols-6 xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-6 flex-row items-center space-x-4 card-body">

                    <div className='col-span-1'>
                        <div className="rounded-full w-16 h-16">
                            <BsFillFileEarmarkExcelFill className='text-6xl md:text-5xl' />
                        </div>
                    </div>

                    <div  className='col-span-3'>
                        <h1 className='card-title'>
                            Saldos por unidad
                        </h1>
                        {/*<h3 className='text-base-content text-opacity-40'>{report.appName}</h3>*/}
                        {/*<h3 className='text-base-content text-opacity-40'> {report.date} </h3>*/}
                        {/*<h3 className='text-base-content text-opacity-60'> {recurrentJob.updatedBy} </h3>*/}

                    </div>



                </div>
            </div>
        </>
    )
}

export default ReportCard;
