import dayjs from "dayjs";
import 'dayjs/locale/en';
import {useContext, useEffect, useState} from "react";
import {colors, dayIndexMap, monthIndexMap} from "../../utils/constantest";
import {useNavigate} from "react-router-dom";
import {FaRegCheckCircle, FaRegClock, FaRegTimesCircle} from "react-icons/fa";
import {validatePermissions} from "../../context/login/LoginActions";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import {
    approvalReservation,
    cancelReservation,
    getReservations,
    rejectReservation
} from "../../context/reservation/ReservationActions";
import ReservationContext from "../../context/reservation/ReservationContext"; // Import the English locale for dayjs

function Reservation({amenity}){

    const navigate = useNavigate()
    const { userData } = useTokenAndUserData();
    const { setReservations, month, selectYear } = useContext(ReservationContext);

    const containerStyle = {
        border: '2px solid',
        borderColor: colors.find(c => c.name == amenity.amenity.color).bgColor
    };

    const spanStyle = {
        backgroundColor: colors.find(c => c.name == amenity.amenity.color).bgSpan
    };

    // const handleReject = async (e) => {
    //     e.preventDefault()
    //
    //     const appId = userData.appId;
    //
    //     console.log(selectYear);
    //     console.log(month);
    //
    //     const request = {
    //         id:amenity.id,
    //         appId,
    //         userRequestedId:userData.userId,
    //         cancelBy : userData.email,
    //         updatedBy : userData.email
    //     }
    //     const add = await approvalReservation(request);
    //
    //     if(userData.appId) {
    //         const rsvts = await getReservations(userData.appId, month, selectYear);
    //         setReservations(rsvts.reservationsPerMonth);
    //     }
    //
    // }

    const handleCancel = async (e) => {
        e.preventDefault()

        const appId = userData.appId;

        const request = {
            id:amenity.id,
            appId,
            userRequestedId:userData.userId,
            cancelBy : userData.email,
            updatedBy : userData.email
        }
        const add = await cancelReservation(request);

        if(userData.appId) {
            const rsvts = await getReservations(userData.appId, month, selectYear);
            setReservations(rsvts.reservationsPerMonth);
        }

    }

    return (
        <>
            <div className="flex flex-col gap-2 mt-1" key={amenity.id}>
                <div className="flex flex-row rounded-lg justify-between " style={containerStyle}>
                    <span className="flex-none w-1.5 rounded-l-lg " style={spanStyle}></span>

                    <div className="w-full shrink p-2 flex flex-row justify-between">
                        <div>
                            <h2 className='card-title '>
                                {amenity.amenity.name}
                            </h2>
                            <h3 className='text-base-content '> {amenity.fromTime.substring(0,5)} - {amenity.toTime.substring(0,5)}</h3>

                        </div>

                        {amenity.status === 'WAITING' && amenity.userRequestedId !== userData.userId &&
                            <div className="mr-2 p-2 flex gap-2 items-center " > {/* Aquí agregamos la sección del estado */}
                                <FaRegClock color={'orange'}/>
                                Pendiente
                            </div>
                        }

                        {/*{amenity.status === 'WAITING' &&*/}
                        {/*    validatePermissions(userData.userPermissions, 'RESERVATION_APPROVED') &&*/}
                        {/*    <div className="mr-2"> /!* Aquí agregamos la sección del estado *!/*/}
                        {/*        <button onClick={() => navigate('/reservations/approved/'+ amenity.id)} className='btn btn-outline'>*/}
                        {/*            Approbar / Rechazar*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*}*/}

                        {(amenity.status === 'WAITING' && validatePermissions(userData.userPermissions, 'RESERVATION_APPROVED') && amenity.userRequestedId !== userData.userId) &&

                            <div className="mr-2"> {/* Aquí agregamos la sección del estado */}
                                <button onClick={() => navigate('/reservations/approved/'+ amenity.id)} className='btn btn-outline'>
                                    Aprobar/Rechazar
                                </button>
                            </div>
                        }

                        {/*{((amenity.status === 'WAITING' || amenity.status === 'NO_APPROVAL_NEEDED') && amenity.userRequestedId === userData.userId) &&*/}

                        {/*    <div className="mr-2"> /!* Aquí agregamos la sección del estado *!/*/}
                        {/*        <button onClick={handleSubmit} className='btn btn-outline'>*/}
                        {/*            Cancelar*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*}*/}

                        {((amenity.status === 'WAITING' || amenity.status === 'NO_APPROVAL_NEEDED') && amenity.userRequestedId === userData.userId ) &&
                            <div className="mr-2"> {/* Aquí agregamos la sección del estado */}
                                <button onClick={handleCancel} className='btn btn-outline'>
                                    Cancelar
                                </button>
                            </div>
                        }

                        {amenity.status === 'CANCEL' && (
                            (validatePermissions(userData.userPermissions, 'RESERVATION_APPROVED') || amenity.userRequestedId === userData.userId) ? (
                                <div className="mr-2 p-2 flex gap-2 items-center cursor-pointer"
                                     onClick={() => navigate('/reservations/reject/'+ amenity.id)}>
                                    <FaRegTimesCircle color={'red'}/>
                                    Cancelado
                                </div>
                            ) : (
                                <div className="mr-2 p-2 flex gap-2 items-center">
                                    <FaRegTimesCircle color={'red'}/>
                                    Cancelado
                                </div>
                            )
                        )}

                        {amenity.status === 'REJECTED' && (
                            validatePermissions(userData.userPermissions, 'RESERVATION_APPROVED') ? (
                                <div className="mr-2 p-2 flex gap-2 items-center cursor-pointer" onClick={() => navigate('/reservations/reject/'+ amenity.id)}>
                                    <FaRegTimesCircle color={'red'}/>
                                    Rechazado
                                </div>
                            ) : (
                                amenity.userRequestedId !== userData.userId && (
                                    <div className="mr-2 p-2 flex gap-2 items-center">
                                        <FaRegTimesCircle color={'red'}/>
                                        Rechazado
                                    </div>
                                )
                            )
                        )}

                        {/*{ amenity.status === 'CANCEL' && (!validatePermissions(userData.userPermissions, 'RESERVATION_APPROVED') || amenity.userRequestedId !== userData.userId) &&*/}

                        {/*    <div className="mr-2 p-2 flex gap-2 items-center"*/}
                        {/*         > /!* Aquí agregamos la sección del estado *!/*/}
                        {/*        <FaRegTimesCircle color={'red'}/>*/}
                        {/*        Cancelado*/}

                        {/*    </div>*/}

                        {/*}*/}

                        {/*{amenity.status === 'REJECTED' && (!validatePermissions(userData.userPermissions, 'RESERVATION_APPROVED') || amenity.userRequestedId !== userData.userId) &&*/}

                        {/*    <div className="mr-2 p-2 flex gap-2 items-center" >*/}
                        {/*        <FaRegTimesCircle color={'red'}/>*/}
                        {/*        Rechazado*/}
                        {/*    </div>*/}
                        {/*}*/}


                        {/*{amenity.status === 'REJECTED' &&*/}
                        {/*    <div className="mr-2 p-2 flex gap-2 items-center cursor-pointer " onClick={() => navigate('/reservations/reject/'+ amenity.id)}> /!* Aquí agregamos la sección del estado *!/*/}
                        {/*        <FaRegTimesCircle color={'red'}/>*/}
                        {/*        Rechazado*/}
                        {/*    </div>*/}
                        {/*}*/}

                        {amenity.status === 'APPROVED'&&
                            <div className="mr-2 p-2 flex gap-2 items-center "> {/* Aquí agregamos la sección del estado */}
                                <FaRegCheckCircle color={'green'}/>
                                Aprobado

                            </div>
                        }

                    </div>

                </div>
            </div>
        </>
    );

}

function ReservationDay ( {date, amenities}) {

    const [day, setDay] = useState("");
    const [dayNum, setDayNum] = useState(0);
    const [month, setMonth] = useState("");

    useEffect(() => {

        const foundDay = Object.entries(dayIndexMap).find(([key, value]) => value === dayjs(date).day());
        const [key] = foundDay;
        setDay(key)

        const foundEntry = Object.entries(monthIndexMap).find(([key, value]) => value === dayjs(date).month());
        const [m] = foundEntry;
        setMonth(m);

        const num = dayjs(date).date();
        setDayNum(num);

    }, [amenities]);

    const containerStyle = {
        backgroundColor: '#3D4451',
        color: '#FFFFFF', // Text color
        // Add more CSS properties if needed
    };

    //ordernar por amenidad y luego por hora

    return (
        <>
            <div className="flex flex-col gap-4" key={day+dayNum}>

                <div className='flex flex-col'>

                    <div className="w-full text-lg p-3 rounded-lg " style={containerStyle}>
                        <h2 className="mx-2"> {day} {dayNum} {month} </h2>
                    </div>

                    {amenities && amenities.map(amenity =>
                       <Reservation amenity={amenity} />
                    )}

                </div>

            </div>

        </>
    )


}

export default ReservationDay;
