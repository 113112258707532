import {useContext, useEffect, useState} from "react";
import AlertContext from "../../context/alert/AlertContext";
import dayjs from "dayjs";
import {MultiSelect} from "react-multi-select-component";
import {addAd, searchAllTags} from "../../context/ads/AdsActions";
import AdContext from "../../context/ads/AdsContext";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";

function AddAd() {

    const { dispatch, tags} = useContext( AdContext );
    const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
    const { token } = useTokenAndUserData();

    useEffect(() => {
        alertDispatch({ type: 'REMOVE_ALERT'})
        dispatch({ type: 'SET_LOADING' })

        const getAdData = async () => {
            // console.log('Searching tags')
            if(token){
                let t = await searchAllTags(token);

                t = t.map((item) => ({
                    value: item.id,
                    label: `${item.name}`
                }));

                dispatch({type: 'GET_TAGS', payload: t});
            }
        }

        getAdData();

    }, [dispatch, alertDispatch]);


    const [file, setFile] = useState(null);
    const [startDate, setDateOfPublication] = useState(dayjs().format('YYYY-MM-DD'));
    const [finishDate, setDateOfExpiration] = useState(dayjs().add(7, 'day').format('YYYY-MM-DD'));
    const [tagsArray, setTags] = useState([]);
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');

    const handleChangeFile= (e) => {

        const img = e.target.files[0];

        if (img) {
            var image = new Image();
            image.src = URL.createObjectURL(img);

            image.onload = function () {
                var width = this.width;
                var height = this.height;

                // Realiza la validación según tus requisitos
                if (width < 1000 || height < 150 || width > 1250 || height > 200 ) {
                    alert('La imagen no cumple con los requisitos de tamaño.');
                    e.target.value = "";
                } else {
                    setFile(e.target.files[0])
                }
            };

        }
    };
    const handleChangeDateOfPublication = (e) => setDateOfPublication(e.target.value);
    const handleChangeDateOfExpiration = (e) => setDateOfExpiration(e.target.value);
    const handleChangeTags = (e) => setTags(e);
    const handleChangeDescription = (e) => setDescription(e.target.value);
    const handleChangeUlr = (e) => setUrl(e.target.value);

    const handleSubmit = async (e) => {
        e.preventDefault()

        if(file === null || tagsArray.length === 0 ) {
            setAlert('Por favor ingresa todos los datos requeridos', 'error')

        } else {

            alertDispatch({ type: 'SET_LOADING' })

            const start = dayjs(startDate).format('DD/MM/YYYY');
            const date = dayjs(finishDate).format('DD/MM/YYYY');

            console.log({
                file,
                description,
                tags,
                start,
                date,
                url
            })
            const tagsId = tagsArray.map((item) => item.value);

            // console.log(tagsId);

            const formData = new FormData();
            formData.append("file", file);
            formData.append("adDto", JSON.stringify({ description, tagsId, start, date, url }));

            const newAd = await addAd(formData, token);

            dispatch({ type: 'ADD_AD', payload: newAd });
            dispatch({ type: 'GET_ADS', payload: []});

            setFile('');
            setDateOfPublication('');
            setDateOfExpiration('');
            setTags([]);
            setDescription('');
            setUrl('')

            window.location.reload();
        }
    }

    return (
        <div className="w-full mx-auto lg:w-10/12">
            <div className="text-center hero-content">
                <h1 className='text-3xl card-title'>Agregar Anuncio</h1>
            </div>

            <form onSubmit={handleSubmit}>
                <div className='form-control'>

                    <label className='mt-4 text-lg'>
                        File * :
                        <input
                            className="block mt-2 w-full text-md text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                            id="file_input"
                            type="file"
                            placeholder='file'
                            accept="image/jpg, image/jpeg"
                            onChange={handleChangeFile} />

                    </label>

                    <label className='mt-4 text-lg'>
                        Fecha de publicación :
                        <input
                            type='date'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='fecha de publicación'
                            value={startDate}
                            onChange={handleChangeDateOfPublication}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Fecha de expiración:
                        <input
                            type='date'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Fecha de expiración'
                            value={finishDate}
                            onChange={handleChangeDateOfExpiration}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Tags * :
                        <MultiSelect
                            className='mt-2 pl-2 pr-0 pt-2.5 bg-gray-200 input input-lg text-black'
                            options={tags}
                            value={tagsArray}
                            onChange={handleChangeTags}
                            labelledBy={'Selecciona los tags'}
                        />

                    </label>

                    <label className='mt-4 text-lg'>
                        URL:
                        <input
                            type='text'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='http://www.mianuncio.com'
                            value={url}
                            onChange={handleChangeUlr}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Descripción:
                        <textarea
                            className='mt-2 w-full h-40 pr-4 bg-gray-200 input input-lg text-black'
                            placeholder='Descripción'
                            value={description}
                            onChange={handleChangeDescription}
                        />
                    </label>


                    <div className="mt-6 card-actions">
                        <button type='submit' className='btn btn-outline'>
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddAd
