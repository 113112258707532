import {FaRegNewspaper, FaStar, FaTimes, FaUser} from "react-icons/fa";
import {Link} from "react-router-dom";
import {deleteNotice} from "../../context/notice/NoticeActions";
import {useContext, useEffect, useState} from "react";
import NoticeContext from "../../context/notice/NoticeContext";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import {validatePermissions, validateRoles} from "../../context/login/LoginActions";
import {types} from "../../utils/constantest";
import {getColorType} from "../../utils/utilsFunctions";

function NoticeCard ( { notice } ) {

    const { dispatch, announcements } = useContext( NoticeContext );
    const { userData, token } = useTokenAndUserData();
    const [toUrl, setToUrl] = useState('');
    const [colorType, setColorType] = useState('');

    const handleDelete = async () => {

        if(await deleteNotice(notice.id, token)) {
            dispatch({ type: 'GET_NOTICES', payload: announcements.filter((item) => item.id !== notice.id) })
        }

    }

    useEffect(() => {

        if(types.length > 0 ){
            setColorType(getColorType(notice.type.typeName));
        }

        if(validateRoles(userData.permissions, 'isResident')){
            setToUrl(`/notice/${notice.id}`)
        }else {
            setToUrl(`/notice/update/${notice.id}`)
        }

    }, []);

    return (
        <>


            <div className='card shadow-md compact side bg-base-100'>
                <div className="grid grid-cols-5 xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-6 flex-row items-center space-x-4 card-body">

                    <Link to={toUrl} className='col-span-1'>
                        <div>
                            <div className="rounded-full w-16 h-16">
                                <FaRegNewspaper className='text-5xl' />
                            </div>
                        </div>
                    </Link>

                    <Link to={toUrl} className='col-span-3'>
                        <div>
                            <div className="grid grid-flow-row-dense grid-cols-2 gap-2" >

                                <div className={"grow-0 row-span-2"}>
                                    <h2 className='card-title'>
                                        {notice.isPriority &&
                                            <div>
                                                <FaStar className='text-xs dark:text-slate-300' />
                                            </div>

                                        }
                                        {notice.title}
                                    </h2>

                                </div>

                                <div className="flex justify-between col-span-1">

                                    <span className={`p-1 text-[10px] grow-0 uppercase tracking-wider rounded-lg bg-opacity-50 dark:text-slate-300 ${colorType}`}>
                                        {notice.type.typeName}
                                    </span>

                                </div>

                            </div>

                            <div className={`text-gray-500 dark:text-slate-400 mr-2 col-span-3`}>

                                {notice.description.slice(0,50) + '...'}

                                <p>
                                    {notice.announcementStartDate}
                                </p>
                            </div>

                        </div>
                    </Link>

                    {validatePermissions(userData.userPermissions, 'NOTICE') &&
                        <div className=" text-sm font-medium text-black dark:text-slate-300">
                            <button
                                className='close tooltip rounded-full'
                                data-tip='Remover Aviso'
                                onClick={handleDelete}
                            >
                                <FaTimes className='text-2xl md:text-3xl object-none object-right-top' color='primary '/>
                            </button>
                        </div>
                    }

                </div>
            </div>


            {/*<div className="bg-white p-4 rounded-lg shadow dark:bg-slate-700 ">*/}
            {/*    <Link to={toUrl}>*/}
            {/*        <div className="flex justify-between item-center">*/}

            {/*            <div className="flex justify-between">*/}

                            {/*{notice.isPriority &&*/}
                            {/*    <FaStar className='text-xl md:text-md dark:text-slate-300 mr-5' />*/}
                            {/*}*/}
            {/*                <div className="text-gray-500 dark:text-slate-400 mr-5">{notice.announcementStartDate}</div>*/}

            {/*                <div>*/}
            {/*                    <p className={`p-1.5 mr-5 text-xs uppercase tracking-wider rounded-lg bg-opacity-50 dark:text-slate-300 ${colorType}`}>*/}
            {/*                        {notice.type.typeName}*/}
            {/*                    </p>*/}
            {/*                </div>*/}

            {/*            </div>*/}

            {/*            {validatePermissions(userData.userPermissions, 'NOTICE') &&*/}
            {/*                <div className=" text-sm font-medium text-black flex justify-center dark:text-slate-300">*/}
            {/*                    <button*/}
            {/*                        className='close tooltip rounded-full'*/}
            {/*                        data-tip='Remover Aviso'*/}
            {/*                        onClick={handleDelete}*/}
            {/*                    >*/}
            {/*                        <FaTimes className='text-2xl md:text-3xl' color='primary '/>*/}
            {/*                    </button>*/}
            {/*                </div>*/}
            {/*            }*/}

            {/*        </div>*/}
            {/*    </Link>*/}

            {/*    <Link to={toUrl}>*/}
            {/*        <div className="text-3xl text-gray-700 mt-4 dark:bg-slate-700 dark:text-slate-300">*/}
            {/*            {notice.title}*/}
            {/*        </div>*/}
            {/*        <p className="mt-4 dark:bg-slate-700 dark:text-slate-300">*/}
            {/*            {notice.description.slice(0,100) + '...'}*/}
            {/*        </p>*/}
            {/*    </Link>*/}

            {/*</div>*/}

        </>
    )
}

export default NoticeCard;
