import {useContext } from "react";
import TokenContext from "./TokenContext";

export default function useTokenAndUserData () {

    const {token, userData} = useContext( TokenContext );

    return {
        token, userData
    }

}
