import AlertContext from "../../context/alert/AlertContext";
import {useContext, useEffect} from "react";
import RecurrentCollectSearch from "./RecurrentCollectSearch";
import RecurrentCollectsResult from "./RecurrentCollectsResult";

function RecurrentCollects() {

    window.scrollTo({
        top: 0,
        behavior: 'auto',
    })

    const { dispatch: alertDispatch } = useContext(AlertContext)

    useEffect(() => {
        alertDispatch({ type: 'REMOVE_ALERT'})
    }, [alertDispatch])


    return (
        <>
            <RecurrentCollectSearch />
            <RecurrentCollectsResult />
        </>
    )
}

export default RecurrentCollects
