import axios from 'axios';
// const userServiceUrl = process.env.REACT_APP_USER_SERVICE || 'http://70.35.204.186:3000/pjd-cotoadmin-user'
const host = process.env.REACT_APP_HOST || window.location.origin
const service = `${host}${process.env.REACT_APP_COLLECT_SERVICE || '/collect'}`

// const service = `http://localhost:8090/collect`

const db = axios.create({
    baseURL: service,
})

const url = '/api/v1/job';

export const addRecurrentCollect = async (data, token) => {

    const response = await db.post(`${url}/create`, data  , {
        headers: {
            Authorization: `Bearer ${token}`
        }})
    // console.log(response.data);
    return response.data.response
}

export const updateRecurrentCollect = async (data, token) => {

    const response = await db.post(`${url}/update`, data, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    // console.log(response.data);
    return response.data.response
}

export const searchAllRecurrentCollectsByAppId = async (id, token) => {
    try {
        const response = await db.get(`${url}/get_jobs`,{
            params: {
                appId: id
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response;
    } catch (error) {
        console.log(error.message);
        return error;
    }
}

export const searchRecurrentCollectByIdAndAppId = async (id, appId, token) => {
    try {
        const response = await db.get(`${url}/get_collect`, {
            params: {
                id: id, appId :appId
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response);

        return response.data.response;

    } catch (error) {
        console.log(error);
        throw new Error('Failed to retrieve collect.');
    }
}

export const allRecurrentCollectsByUnitIdAndAppId = async(unitId, appId, token) => {
    try {
        const response = await db.get(`${url}/${unitId}/${appId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        // console.log(response);
        return response.data.response;
    } catch (error) {
        return error.response.data;
    }
}

export const getAllCron = async(token) => {
    try {
        const response = await db.get(`${url}/cron`, {
            headers: {
                Authorization: `Bearer ${token}`
            }});
        // console.log(response);
        return response.data.response;
    } catch (error) {
        return error.response.data;
    }
}

