const addressReducer = (state, action) =>{
  switch(action.type) {
    case 'GET_ADDRESSES':
      return {
        ...state,
        addresses: action.payload,
        loading: false,
      }
    case 'GET_ADDRESS':
      return {
        ...state,
        address: action.payload,
        loading: false,
      }
    case 'GET_PREV_PAGE':
      return {
        ...state,
        previousPage: action.payload,
      }
    case 'GET_CURR_PAGE':
      return {
        ...state,
        currentPage: action.payload,
      }
    case 'GET_LAST_PAGE':
      return {
        ...state,
        lastPage: action.payload,
      }
    case 'ADD_ADDRESS':
      return {
        ...state,
        newAddress: action.payload,
        loading: false,
      }
    case 'CLEAR_ADDRESS':
      return {
        ...state,
        addresses: [],
      }
    default:
      return state
  }
}

export default addressReducer