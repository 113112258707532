import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import {useContext, useEffect, useRef, useState} from "react";
import AlertContext from "../../context/alert/AlertContext";
import {useNavigate, useParams} from "react-router-dom";
import CollectContext from "../../context/collect/CollectContext";
import {addCollect, getAllConcepts, getBalanceByUnitId} from "../../context/collect/CollectActions";
import LoginContext from "../../context/login/LoginContext";
import {searchAddresses} from "../../context/address/AddressActions";
import {formatValue} from "../../utils/utilsFunctions";
import dayjs from "dayjs";

function AddCollectToUnit() {

    const { dispatch, concepts} = useContext( CollectContext );
    const { dispatch : loginDispatch} = useContext(LoginContext)
    const { token, userData } = useTokenAndUserData();
    const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
    const [appId, setAppId] = useState(0);

    const [conceptId, setConceptId] = useState(0);
    const [user, setUser] = useState('');
    const [description, setDescription] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [balanceBefore, setBalanceBefore] = useState(0);
    const [balanceAfter, setBalanceAfter] = useState(0);
    const [reference, setReference] = useState('');

    const balanceBeforeRef = useRef(null);
    const balanceAfterRef = useRef(null);

    let { id, nameUnit } = useParams();

    useEffect(() => {

        alertDispatch({ type: 'REMOVE_ALERT'})
        dispatch({ type: 'SET_LOADING' })

        const getData = async () => {
            let getConcepts = await getAllConcepts(token);
            let addressData = await searchAddresses({keywords: '', pageSize: 2000, token})

            addressData = addressData.data.map((item) => ({
                value: item.addressId,
                label: `${formatValue(item.addressUnit, true)}${formatValue(item.addressBuilding)}${formatValue(item.addressIntNum)}`
            }));

            loginDispatch({ type: 'GET_ADDRESS', payload: addressData });
            dispatch({type: 'GET_CONCEPTS', payload: getConcepts});
        }

        const getBalance = async () => {

            const blc = await getBalanceByUnitId(id, userData.appId, token)
            // console.log(blc);
            if(Object.prototype.hasOwnProperty.call(blc, 'balance', )) {
                balanceBeforeRef.current.value = blc.balance;
                setBalanceBefore(blc.balance)
            }
        };

        setAppId(userData.appId);
        setUser(userData.email);
        getBalance();
        getData();

    }, [dispatch, loginDispatch, alertDispatch, userData]);


    const handleChangeDescription = (e) => setDescription(e.target.value);
    const handleChangeQuantity= (e) => {

        const inputValue = e.target.value;

        if (!isNaN(inputValue)) {
            setQuantity(inputValue);

            if(conceptId){

                const concept = concepts.find(cpt => cpt.id == conceptId)

                if(concept.operation === 'SUM'){

                    balanceAfterRef.current.value = Number(balanceBefore) + Number(e.target.value);
                    setBalanceAfter(Number(balanceBefore) + Number(e.target.value))

                }else if(concept.operation === 'SUB'){

                    balanceAfterRef.current.value = Number(balanceBefore) - Number(e.target.value);
                    setBalanceAfter(Number(balanceBefore) - Number(e.target.value))

                }
            }
        }
    };

    const handleChangeConcept = (e) => {
        setConceptId(e.target.value);
        const concept = concepts.find(cpt => cpt.id == e.target.value)

        if(concept.operation === 'SUM'){

            balanceAfterRef.current.value = Number(balanceBefore) + Number(quantity);
            setBalanceAfter(Number(balanceBefore) + Number(quantity))

        }else if(concept.operation === 'SUB'){

            balanceAfterRef.current.value = Number(balanceBefore) - Number(quantity);
            setBalanceAfter(Number(balanceBefore) - Number(quantity))

        }

    }
    const handleChangeReference = (e) => setReference(e.target.value);

    const handleChangeDate = (e) => setDate(dayjs(e.target.value).format('YYYY-MM-DD'));


    const navigate = useNavigate()

    const handleSubmit = async (e) => {

        e.preventDefault()

        const unit = {unitId:id, unitName : nameUnit}

        if(!conceptId) {
            setAlert('Por favor ingresa un concepto', 'error')
        } else {

            alertDispatch({ type: 'SET_LOADING' })
            //
            // console.log({
            //     createdBy : user,
            //     updatedBy : user,
            //     unit,
            //     appId,
            //     conceptId,
            //     description,
            //     quantity,
            //     transactionDate:date,
            //     reference,
            //     unitName:nameUnit
            // })

            const newCollect = await addCollect({
                createdBy : user,
                updatedBy : user,
                unit,
                appId,
                conceptId,
                description,
                quantity,
                transactionDate:date,
                reference,
                unitName:nameUnit
            }, token);

            dispatch({ type: 'ADD_NOTICE', payload: newCollect });
            dispatch({ type: 'GET_NOTICES', payload: []});

            setConceptId(0);
            setDescription('');
            setQuantity(0);
            setReference('');

            navigate(`/collects/${id}/${nameUnit}`);
        }
    }

    return (
        <div className="w-full mx-auto lg:w-10/12">
            <div className="text-center hero-content">
                <h1 className='text-3xl card-title'>Cobranza Unidad {nameUnit}</h1>
            </div>

            <form onSubmit={handleSubmit}>
                <div className='form-control'>

                    <label className='mt-4 text-lg'>
                        Fecha *:
                        <input
                            type='date'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Fecha'
                            value={date}
                            onChange={handleChangeDate}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Cantidad *:
                        <input
                            type='text' // Use text type to allow decimal input
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Cantidad'
                            value={quantity}
                            onChange={handleChangeQuantity}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Concepto *:
                        <select
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Tipo de concepto'
                            value={conceptId}
                            onChange={handleChangeConcept}
                        >
                            <option defaultValue key='' value=''>Selecciona una opción</option>

                            {concepts.map((type) => (

                                <option key={type.id} value={type.id}>
                                    {type.description}
                                </option>
                            ))}
                        </select>
                    </label>

                    <label className='mt-4 text-lg'>
                        Descripción:
                        <textarea

                            className='mt-2 w-full h-40 pr-4 bg-gray-200 input input-lg text-black'
                            placeholder='Descripción'
                            value={description}
                            onChange={handleChangeDescription}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Referencia:
                        <input
                            type='text'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Referencia'
                            value={reference}
                            onChange={handleChangeReference}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Balance antes:
                        <input
                            type="text"
                            disabled={true}
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Balance antes'
                            ref={balanceBeforeRef}
                            value={balanceBefore}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Balance después:
                        <input
                            type='number'
                            disabled={true}
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Balance después'
                            ref={balanceAfterRef}
                            value={balanceAfter}
                        />
                    </label>

                    <div className="mt-6 card-actions">
                        <button type='submit' className='btn btn-outline'>
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddCollectToUnit


