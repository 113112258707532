const collectReducer = (state, action) =>{
    switch(action.type) {

        case 'GET_COLLECTS':
            return {
                ...state,
                collects: action.payload,
                loading: false,
            }
        case 'GET_COLLECT':
            return {
                ...state,
                collect: action.payload,
                loading: false,
            }
        case 'ADD_COLLECT':
            return {
                ...state,
                newCollect: action.payload,
                loading: false,
            }
        case 'GET_BALANCES':
            return {
                ...state,
                balances: action.payload,
                loading: false,
            }

        case 'GET_BALANCE':
            return {
                ...state,
                balance: action.payload,
                loading: false,
            }
        case 'GET_CONCEPTS':
            return {
                ...state,
                concepts: action.payload,
                loading: false,
            }
        default:
            return state
    }
}

export default collectReducer
