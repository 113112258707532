import { useContext } from 'react'
import AlertContext from '../../context/alert/AlertContext'

function Alert() {
  const { alert, dispatch } = useContext(AlertContext)

  return alert !== null && (
    <>
      {alert.type === 'error' && (
        <div className="alert alert-warning shadow-lg">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
            <span>{alert.msg}</span>
          </div>
          <div className="flex-none ">
            <button onClick={() => dispatch({ type: 'REMOVE_ALERT'})}  className="btn btn-sm border-warning-content btn-warning">Ok</button>
          </div>
        </div>

      )}
      {alert.type === 'error2' && (
        <div className="alert alert-error shadow-lg">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
            <span>{alert.msg}</span>
          </div>
          <div className="flex-none ">
            <button onClick={() => dispatch({ type: 'REMOVE_ALERT'})}  className="btn btn-sm border-error-content btn-error">Ok</button>
          </div>
        </div>

      )}
      {alert.type === 'info' && (
        <div className="alert alert-success shadow-lg">
          <div>
          <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
            <span>{alert.msg}</span>
          </div>
          <div className="flex-none">
            <button onClick={() => dispatch({ type: 'REMOVE_ALERT'})}  className="btn btn-sm border-warning-content btn-success">Ok</button>
          </div>
        </div>
      )}
    </>
  )
}

export default Alert