import { createContext, useReducer } from 'react'
import noticeReducer from "./NoticeReducer";


const NoticeContext = createContext()

export const NoticeProvider = ({ children }) => {

    const initialState = {
        announcements: [],
        announcement: {},
        newAnnouncement: {},
        types: [],
        isUpNotice:false,
        loading: false,
    }

    const [state, dispatch] = useReducer(noticeReducer, initialState)

    return <NoticeContext.Provider value={{
        ...state,
        dispatch,
    }}>
        {children}
    </NoticeContext.Provider>
}

export default NoticeContext
