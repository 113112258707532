import { useContext, useEffect } from 'react'
import AlertContext from '../context/alert/AlertContext'

function About() {
  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })

  const { dispatch: alertDispatch } = useContext(AlertContext)

  useEffect(() => {
    alertDispatch({ type: 'REMOVE_ALERT'})
  }, [alertDispatch])

  return (
    <>
      <div>
        <h1 className='text-6xl mb-4'>BellaterrApp</h1>
        <p className='mb-4 text-2xl font-light'>
          Administración integral de fraccionamientos.
        </p>
        <p className='text-lg-bold text-gray-400'>
          Version: <span className='text-gray-400'>1.0.0</span>
        </p>
        <p className='text-lg-bold text-gray-400'>
          Desarrollado por: <a className='text-gray-400'href='https://pjdevelopers.com/'>
          pjdevelopers.com
          </a>
        </p>
      </div>
      {/* <div className="mt-12 w-100">
        <p className='mb-4 text-2xl font-light'>
          FAQ
        </p>
        <iframe
          title='faq'
          height={1200}
          className='w-full mx-auto'
          src="https://docs.google.com/document//pub?embedded=true" />
      </div> */}
    </>
    
  )
}

export default About