import React, {useContext, useEffect, useState} from 'react'
import Spinner from '../layout/Spinner'
import CollectContext from "../../context/collect/CollectContext";
import {useNavigate} from "react-router-dom";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import AlertContext from "../../context/alert/AlertContext";
import Ad from "../ads/Ad";
import {getReservations} from "../../context/reservation/ReservationActions";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";
import ReservationDay from "./ReservationDay";
import {monthIndexMap} from "../../utils/constantest";
import dayjs from "dayjs";
import ReservationContext from "../../context/reservation/ReservationContext";

function ReservationResult() {

    const navigate = useNavigate()
    const { userData, token } = useTokenAndUserData()
    const { loading } = useContext( CollectContext );
    const { reservations, setReservations, setMonthAndYear} = useContext(ReservationContext);
    const [month, setMonth] = useState(getMonthName(dayjs().month()));
    const [monthNum, setMonthNum] = useState(dayjs().month());
    const [year, setYear] = useState(dayjs().year());
    // const [reservations, setReservations] = useState([]);

    useEffect(() => {

        const getData = async () => {

            if(userData.appId) {
                const rsvts = await getReservations(userData.appId, monthNum, year, token);
                setMonthAndYear(monthNum, year);
                setReservations(rsvts.reservationsPerMonth);
            }
        }
        getData();

    }, [userData, month, monthNum, year]);

    const changeNextMonth = () => {
        const newMonth = dayjs().month(monthNum).add(1, 'month');
        const monthName = getMonthName(newMonth.month());
        setMonth(monthName);
        setMonthNum(newMonth.month());
        if (newMonth.month() === 0) {
            setYear(year + 1);
        }
    };

    const changePreviousMonth = () => {
        const newMonth = dayjs().month(monthNum).subtract(1, 'month');
        const monthName = getMonthName(newMonth.month());
        setMonth(monthName);
        setMonthNum(newMonth.month());
        if (newMonth.month() === 11) {
            setYear(year - 1);
        }
    };

    function getMonthName(mn){
        const foundEntry = Object.entries(monthIndexMap).find(([key, value]) => value === mn);
        const [m] = foundEntry;
        return m;
    }

    if(!loading) {

        return (
            <>
                <Ad/>

                <div className="text-center hero-content pt-0 pb-0 mb-5" >
                    <h1 className='text-3xl card-title'> Reservaciones </h1>
                </div>

                <div className='w-full flex justify-center mb-8 mt-9'>
                    <button onClick={() => navigate(`/reservation/create`)} className=' w-10/12 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg'>
                        Reservar amenidad
                    </button>
                </div>

                <div className="flex flex-row justify-around gap-4 lg:gap-10 items-center" >

                    <button onClick={changePreviousMonth} disabled={(monthNum === dayjs().month() && year === dayjs().year())}>
                        <FaChevronLeft className='text-xl md:text-xl' color={(monthNum === dayjs().month() && year === dayjs().year()) ? 'gray' : 'black' }/>
                    </button>

                    <div className="flex flex-none w-56 lg:w-80 justify-center">
                        <h1 className=' text-2xl lg:text-3xl'> {month} {year} </h1>
                    </div>

                    <button onClick={changeNextMonth} >
                        <FaChevronRight className='text-xl md:text-xl' />
                    </button>

                </div>

                {reservations && reservations.length > 0 ? reservations.map((res, index) => (
                    <div className="mt-4" key={index}>
                        <ReservationDay key={index} date={res.dayOfReservation} amenities={res.reservations}/>
                    </div>
                ))

                :
                    <div className="mt-4 flex flex-none justify-center">
                        <h1 className='text-xl'> No hay eventos </h1>
                    </div>
                }


            </>
        )


    } else {
        return (
            <Spinner />
        )
    }

}

export default ReservationResult
