import {FaStar, FaTimes} from "react-icons/fa";
import {Link} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import NoticeContext from "../../context/notice/NoticeContext";
import {deleteNotice} from "../../context/notice/NoticeActions";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import {validatePermissions, validateRoles} from "../../context/login/LoginActions";
import {types} from "../../utils/constantest";
import {colorType, getColorType} from "../../utils/utilsFunctions";

function NoticeRow( { notice }) {

    const { dispatch, announcements } = useContext( NoticeContext );
    const { userData, token  } = useTokenAndUserData();
    const [toUrl, setToUrl] = useState('');
    const [colorType, setColorType] = useState('');

    const handleDelete = async () => {
        if(await deleteNotice(notice.id, token)) {
            dispatch({ type: 'GET_NOTICES', payload: announcements.filter((item) => item.id !== notice.id ) })
        }
    }

    useEffect(() => {

        if(types.length > 0 ){
            setColorType(getColorType(notice.type.typeName));
        }

        if(validateRoles(userData.permissions, 'isResident')){
            setToUrl(`/notice/${notice.id}`)
        }else {
            setToUrl(`/notice/update/${notice.id}`)
        }

    }, []);

    return (
        <>
            <tr className="bg-white dark:bg-slate-700">

                    <td className="p-3 text-lg text-gray-700 whitespace-nowrap dark:text-slate-400">
                        <Link to={toUrl}>
                            {notice.isPriority && <FaStar className='text-xl md:text-md' /> }
                        </Link>
                    </td>

                    <td className="p-3 text-lg text-gray-700 whitespace-nowrap  dark:text-slate-400">
                        <Link to={toUrl}>{notice.announcementStartDate} </Link>
                        </td>

                    <td className="p-3 text-lg text-gray-700 whitespace-nowrap  dark:text-slate-400">
                        <Link to={toUrl}> {notice.title} </Link>
                    </td>

                    <td className="p-3 text-lg text-gray-700 whitespace-nowrap  dark:text-slate-400">
                        <Link to={toUrl}>
                            <span className={`p-1.5 text-xs font-medium uppercase tracking-wider rounded-lg bg-opacity-50 ${colorType}`}>
                                {notice.type.typeName}
                            </span>
                        </Link>
                    </td>


                {validatePermissions(userData.userPermissions, 'NOTICE') &&
                    <td className="p-3 text-lg text-gray-700 whitespace-nowrap dark:text-slate-400">
                        <div className=''>
                            <button
                                className='close tooltip rounded-full'
                                data-tip='Remover Aviso'
                                onClick={handleDelete}
                            >
                                <FaTimes className='text-4xl md:text-3xl' color='primary '/>
                            </button>
                        </div>
                    </td>
                }

            </tr>

        </>

    );
}

export default NoticeRow;
