import axios from 'axios';
import useTokenAndUserData from "../token/useTokenAndUserData";
const host = process.env.REACT_APP_HOST || window.location.origin
const service = `${host}${process.env.REACT_APP_FILE_SERVICE || '/files'}`

// const service = `http://localhost:8080/file`


const db = axios.create({
    baseURL: service
})

export const addFile = async (formData, token) => {

    const response = await db.post(`/api/v1/load_file`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    });

    // console.log(response.data);
    return response.data.response
}

export const updateFile = async (formData, token) => {

    const response = await db.post(`/api/v1/update_file`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    });

    // console.log(response.data);
    return response.data.response
}
export const searchAllFiles = async (appId, token) => {
    try {
        // console.log('Searching tags')
        const response = await db.get(`/api/v1/all_files/app`,  {
            params:{appId:appId},
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        // console.log(response.data.response);
        return response.data.response;
    } catch (error) {
        console.log(error);
        throw new Error('Failed to retrieve files.');
    }
}


export const downloadFile= async (fileCode, appName, token) => {
    try {
        // console.log('Searching tags')
        const response = await db.get(`/api/v1/downloadFile/`,  {
            params:{fileCode:fileCode, appName },
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        // console.log(response.data.response);
        return response;

    } catch (error) {
        console.log(error);
        throw new Error('Failed to retrieve files.');
    }
}


export const removeFile= async (fileCode,appId, updatedBy, appName, token) => {
    try {

        const response = await db.get(`/api/v1/removeFile/`,  {
            params:{
                fileCode:fileCode,
                appId,
                updatedBy,
                appName
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        // console.log(response.data.response);
        return response;

    } catch (error) {
        console.log(error);
        throw new Error('Failed to retrieve files.');
    }
}

export const findFile = async (id, appId, token) => {
    try {
        console.log({
            id:id,
            appId
        })
        const response = await db.get(`/api/v1/find_file/`,  {
            params:{
                id,
                appId
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.response;

    } catch (error) {
        console.log(error);
        throw new Error('Failed to retrieve files.');
    }
}

export const checkFileHealth = async () => {
    try {
        return await db.get(`/health`);
    } catch (error) {
        return error;
    }
}



