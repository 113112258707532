import axios from 'axios';
// const userServiceUrl = process.env.REACT_APP_USER_SERVICE || 'http://70.35.204.186:3000/pjd-cotoadmin-user'
const host = process.env.REACT_APP_HOST || window.location.origin
const service = `${host}${process.env.REACT_APP_AMENITY_SERVICE || '/amenity/api/v1/'}`

// const service = 'http://localhost:8090/amenity/api/v1/'

const instance = axios.create({
    baseURL: service,
})

export const addAmenity = async (request, token) => {
    const response = await instance.post(`amenity/create`, request, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.response
}

export const updateAmenity = async (request, token) => {
    const response = await instance.post(`amenity/update`, request, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.response
}

export const getAmenityById = async (appId, id, token) => {
    const response = await instance.get(`amenity/get_amenity`, {
        params:{
            appId,
            id
        },
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.response
}
export const getAllDays = async (token) => {
    try {
        // console.log('Searching tags')
        const response = await instance.get(`day/all`,{
            headers: {
                'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
            }
        });
        // console.log(response.data.response);
        return response.data.response;
    } catch (error) {
        throw new Error('Failed to retrieve days.');
    }
}

export const getAllHours = async (token) => {
    try {
        const response = await instance.get(`hour/all`,{
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.response;

    } catch (error) {
        throw new Error('Failed to retrieve hours.');
    }
}

export const getAllAmenities = async (appId, token) => {
    try {
        const response = await instance.get(`amenity/all`,{
            params:{appId:appId},
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.response;

    } catch (error) {
        throw new Error('Failed to retrieve hours.');
    }
}

