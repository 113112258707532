import { createContext, useReducer } from 'react'
import addressReducer from './AddressReducer'

const AddressContext = createContext()

export const AddressProvider = ({ children }) => {
  const initialState = {
    addresses: [],
    currentPage: 1,
    previousPage: 0,
    lastPage: false,
    address: {},
    loading: false,
  }

  const [state, dispatch] = useReducer(addressReducer, initialState)

  return <AddressContext.Provider value={{
    ...state,
    dispatch,
  }}>
    {children}
  </AddressContext.Provider>
}

export default AddressContext
