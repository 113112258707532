import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { FaTimes,  FaUser } from 'react-icons/fa'
import { deleteUser } from '../../context/login/LoginActions'
import LoginContext from '../../context/login/LoginContext'
import PropTypes from 'prop-types'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";

function UserItem({ user: { userId, userName, userEmail/*, userRole, comment, active*/ } }) {
  const { users, dispatch } = useContext(LoginContext)
  const { token } = useTokenAndUserData()

  // const navigate = useNavigate()

  const handleDelete = async () => {
    if(await deleteUser(userId, token)) {
      dispatch({ type: 'GET_USERS', payload: users.filter((item) => item.userId !== userId ) })
    }
  }
    
  
  return (
    <div className='card shadow-md compact side bg-base-100'>
      <div className="grid grid-cols-6 xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-6 flex-row items-center space-x-4 card-body">

        <Link to={`/users/${userId}/update`} className='col-span-1'>
          <div>
            <div className="rounded-full w-16 h-16">
              <FaUser className='text-6xl md:text-5xl' />
            </div>
          </div>
        </Link>

        <Link to={`/users/${userId}/update`} className='col-span-3'>
          <div>
            <h2 className='card-title'>
              {userName}
            </h2>
            <h3 className='text-base-content text-opacity-40'>{userEmail}</h3>
          </div>
        </Link>

        <div className='flex col-span-2 justify-end '>
          <button onClick={handleDelete}
                  className='close tooltip rounded-full'
                  data-tip='Remover Residente'>
            <FaTimes className='text-4xl md:text-3xl' color='primary '/>
          </button>
        </div>

        {/*<div className='flex col-span-2 justify-end xl:col-span-1 lg:col-span-1 md:col-span-1'>*/}
        {/*  <button onClick={() => navigate(`/users/${userId}/update`)} */}
        {/*    className='edite tooltip rounded-full'*/}
        {/*    data-tip='Editar Residente'>*/}
        {/*      <FaEdit className='text-4xl md:text-3xl' color='primary ' />*/}
        {/*  </button>*/}
        {/*</div>*/}


      </div>
    </div>
  )
}

UserItem.propTypes = {
  user: PropTypes.object.isRequired,
}

export default UserItem
