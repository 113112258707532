import {useContext, useEffect, useState} from "react";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import AlertContext from "../../context/alert/AlertContext";
import {useNavigate} from "react-router-dom";
import {allCollectsByUnitIdAndAppId} from "../../context/collect/CollectActions";
import {searchAddresses} from "../../context/address/AddressActions";
import {formatValue} from "../../utils/utilsFunctions";
import LoginContext from "../../context/login/LoginContext";
import {searchAllRecurrentCollectsByAppId} from "../../context/RecurrentCollect/RecurrentCollectActions";
import RecurrentCollectContext from "../../context/RecurrentCollect/RecurrentCollectContext";
import Ad from "../ads/Ad";

function RecurrentCollectSearch() {
    const { token, userData} = useTokenAndUserData()
    const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
    const { dispatch, isUpRecurrent} = useContext( RecurrentCollectContext );
    const { dispatch : loginDispatch} = useContext(LoginContext)
    const navigate = useNavigate()

    const [appId, setAppId] = useState(0);

    useEffect(() => {

        alertDispatch({ type: 'REMOVE_ALERT'})
        dispatch({ type: 'SET_LOADING' })
        const getCollectsData = async () => {

            if(userData.appId && isUpRecurrent){
                const collectsData = await searchAllRecurrentCollectsByAppId(userData.appId, token);

                 if (collectsData.status === 200){
                    const data = collectsData.data.response;
                     console.log(data);
                    dispatch({ type: 'GET__RECURRENT_COLLECTS', payload: data })
                }else if(collectsData.response && collectsData.response.status !== 200){
                     console.log("error");
                 }

            }
        }

        if(isUpRecurrent){
            getCollectsData();
        }


    }, [dispatch, alertDispatch, userData, isUpRecurrent])

    // useEffect(() => {
    //
    //     alertDispatch({ type: 'REMOVE_ALERT'})
    //     dispatch({ type: 'SET_LOADING' })
    //
    //     const getData = async () => {
    //
    //         let addressData = await searchAddresses({keywords: '', pageSize: 2000, token})
    //
    //         addressData = addressData.data.map((item) => ({
    //             value: item.addressId,
    //             label: `${formatValue(item.addressUnit, true)}${formatValue(item.addressBuilding)}${formatValue(item.addressIntNum)}`
    //         }));
    //
    //         loginDispatch({ type: 'GET_ADDRESS', payload: addressData });
    //
    //     }
    //     setAppId(userData.appId);
    //
    //     getData();
    //
    //
    // }, [dispatch, loginDispatch, token, alertDispatch, userData]);


    const handleChangeUnit = async (e) => {

        e.preventDefault()
        const collects = await allCollectsByUnitIdAndAppId(e.target.value, appId, token);

        if(Object.prototype.hasOwnProperty.call(collects, 'httpStatus', )) {
            setAlert(collects.details, 'error')
        }
        dispatch({ type: 'GET_COLLECTS', payload: collects })
    }

    return (
        <div className="w-full mx-auto lg:w-10/12">

            <Ad/>

            <div className="text-center hero-content pt-0 pb-0">
                <h1 className='text-3xl card-title'>Cobranza recurrente</h1>
            </div>

            <div className='w-full flex justify-center mb-8 gap-8 mt-9'>
                <button onClick={() => navigate(`/recurrent_collects/create`)} className="mr-6 w-10/12 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg"
                disabled={!isUpRecurrent}>
                    Agregar transacción
                </button>

            </div>
        </div>
    )
}

export default RecurrentCollectSearch
