import {createContext, useEffect, useReducer} from 'react'
import recurrentCollectReducer from "./RecurrentCollectReducer";
import {checkHealthCollect} from "../collect/CollectActions";

const RecurrentCollectContext = createContext()
export const RecurrentCollectProvider = ({ children }) => {

    const initialState = {
        recurrentCollects: [],
        crons: [],
        recurrentCollect: {},
        newRecurrentCollect: {},
        isUpRecurrent : false,
        loading: false,
    }

    const [state, dispatch] = useReducer(recurrentCollectReducer, initialState)

    return <RecurrentCollectContext.Provider value={{
        ...state,
        dispatch,
    }}>
        {children}
    </RecurrentCollectContext.Provider>
}

export default RecurrentCollectContext
