import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import VisitContext from '../../context/visits/VisitContext'
import AlertContext from '../../context/alert/AlertContext'
import { addVisit, getVisitorsAndAddress } from '../../context/visits/VisitActions'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import Ad from "../ads/Ad";

function AddVisit() {
  const { token, userData} = useTokenAndUserData()
  const { dispatch, visitors, addresses } = useContext(VisitContext)
  const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)

  const url = window.location.search
  let params = new URLSearchParams(url)
  const idVisitor =  params.get('visitorId')

  const navigate = useNavigate()

  const [visitorId, setVisitorId] = useState(parseInt(idVisitor) || '')
  const [addressId, setAddressId] = useState('')
  const [address, setAddress] = useState('')
  const [visitorPlates, setPlates] = useState('')
  const [enablePlates, setPlatesEnablement] = useState(true)
  const [date, setDate] = useState(formatDate(new Date()))
  const [time, setTime] = useState(formatTime(new Date()))
  const [comment, setComment] = useState('')

  const idAddress = addresses[0] ? addresses[0].addressId : ''

  let addressName = ''
  if(idAddress){
    const item = (addresses.find((address) => address.addressId = idAddress))
    addressName = `${formatValue(item.addressUnit, true)}${formatValue(item.addressBuilding)}${formatValue(item.addressIntNum)}`
  }

  function formatValue(value, first) {
    const prefix = first ? '' : ' - '
    if(value === null || value === '' || !value)
    {
      return ''
    }
    return `${prefix}${value}`
  }

  useEffect(() => {
    alertDispatch({ type: 'REMOVE_ALERT'})
    dispatch({ type: 'SET_LOADING' })
    const getVisitData = async () => {
      const addressData = await getVisitorsAndAddress(token)
      dispatch({ type: 'GET_VISTORS_AND_ADDRESSES', payload: addressData })
    }

    getVisitData()
    setAddressId(idAddress)
    setAddress(addressName)
    window.scrollTo(0, 0)
  }, [dispatch, token, idAddress, addressName, setAddressId, setAddress, alertDispatch])

  useEffect( () => {

  },[userData])
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-');
  }

  function formatTimestamp(date, time) {
    return `${date} ${time}:00`;
  }

  function formatTime(date) {
    return [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
    ].join(':');
  }

  const minDate = formatDate(new Date())

  const handleChangeVisitId = (e) => {
    const idSelected = parseInt(e.target.value, 10)
    setVisitorId(idSelected)
    let visitorSelected = visitors.filter((item) => item.visitorId === idSelected)
    setPlatesEnablement(visitorSelected[0].provider ? false : true)
    setPlates(visitorSelected[0].visitorPlates || '')
  }

  const handleChangeAddressId = (e) => {
    const idSelected = parseInt(e.target.value, 10)
    setAddressId(idSelected)
    const item = (addresses.find((address) => address.addressId = idSelected))
    let address = `${formatValue(item.addressUnit, true)}${formatValue(item.addressBuilding)}${formatValue(item.addressIntNum)}`
    setAddress(address)
  }

  const handleChangePlates = (e) => setPlates(e.target.value)

  const handleChangeDate = (e) => {
    if (e.target.value === '') {
      setDate(formatDate(new Date()))
    } else {
      setDate(e.target.value)
    }
  }

  const handleChangeTime = (e) => {
    if (e.target.value === '') {
      setTime(formatTime(new Date()))
    } else {
      setTime(e.target.value)
    }
  }

  const handleChangeComment = (e) => setComment(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if(visitorId === '' || addressId === '' || date === 'date' ) {
      setAlert('Por favor ingresa todos los datos requeridos', 'error')
    } else {
      dispatch({ type: 'SET_LOADING' })
      const newVisit = await addVisit({
        visitorId,
        addressId,
        address,
        visitorPlates,
        date: formatTimestamp(date, time),
        comment
      }, token)

      if(Object.prototype.hasOwnProperty.call(newVisit, 'visitCode')) {
        dispatch({ type: 'ADD_VISIT', payload: newVisit })
        dispatch({ type: 'GET_VISITS', payload: []})
        dispatch({ type: 'GET_LAST_PAGE', payload: false })
        dispatch({ type: 'GET_PREV_PAGE', payload: 0 })
        dispatch({ type: 'GET_CURR_PAGE', payload: 1 })

        setVisitorId('')
        setAddressId('')
        setAddress('')
        setPlates('')
        setDate(formatDate(new Date()))
        setTime(formatTime(new Date()))
        setComment('')

        navigate(`/visit/${newVisit.visitCode}`)
      } else {
        setAlert(newVisit, 'error')
      }
    }
  }

  return (
    <div className="w-full mx-auto lg:w-10/12">

      <Ad/>

      <div className="text-center hero-content">
        <h1 className='text-3xl card-title'>Agendar Visita</h1>
      </div>

      <form onSubmit={handleSubmit}>
        <div className='form-control'>
          <label className='mt-4 text-lg lg:text-md md:text-md'>
            Selecciona un Visitante * :
            <select
              type='select'
              className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
              placeholder='Unidad'
              value={visitorId}
              onChange={handleChangeVisitId}
            >
              <option disabled defaultValue key='' value=''>Selecciona una opción</option>
              {visitors.map((visitor) => (
                <option key={visitor.visitorId} value={visitor.visitorId}>{visitor.visitorName}</option>
              ))}
            </select>
          </label>

          <label className='mt-4 text-lg lg:text-md md:text-md'>
            Selecciona una Unidad * :
            <select
              type='select'
              className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
              placeholder='Unidad'
              value={addressId}
              onChange={handleChangeAddressId}
            >
              <option disabled defaultValue key='' value=''>Selecciona una opción</option>
              {addresses.map((address) => (
                <option key={address.addressId} value={address.addressId}>
                  {address.addressUnit && (address.addressUnit)} {address.addressBuilding && (`- ${address.addressBuilding}`)} {address.addressIntNum && (`- ${address.addressIntNum}`)}
                </option>
              ))}
            </select>
          </label>

          {enablePlates && (
            <label className='mt-4 text-lg'>
              Placas del Vehículo:
              <input
                type='text'
                className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                placeholder='Placas del Vehículo'
                value={visitorPlates}
                onChange={handleChangePlates}
              />
            </label>
          )}

          <label className='mt-4 text-lg'>
            Día de la visita * :
            <input
              type='date'
              className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
              placeholder='Día de la visita'
              value={date}
              min={minDate}
              onChange={handleChangeDate}
            />
          </label>

          <label className='mt-4 text-lg'>
            Hora de la visita * :
            <input
              type='time'
              className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
              placeholder='Hora de la visita'
              value={time}
              onChange={handleChangeTime}
            />
          </label>

          <label className='mt-4 text-lg'>
            Comentario:
            <textarea

              className='mt-2 w-full h-40 pr-4 bg-gray-200 input input-lg text-black'
              placeholder='Comentario'
              value={comment}
              onChange={handleChangeComment}
            />
          </label>

          <div className="mt-6 card-actions">
            <button type='submit' className='btn btn-outline'>
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddVisit
