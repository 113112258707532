import React, {useContext, useEffect, useState} from 'react'
import Spinner from '../layout/Spinner'
import CollectContext from "../../context/collect/CollectContext";
import {useNavigate, useParams} from "react-router-dom";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import AlertContext from "../../context/alert/AlertContext";
import Ad from "../ads/Ad";
import {getAllAmenities} from "../../context/amenity/AmenityActions";
import AmenityCard from "./AmenityCard";
import AmenityRow from "./AmenityRow";

function AmenityResult() {

    let { id, nameUnit } = useParams();
    const navigate = useNavigate()
    const { userData,token} = useTokenAndUserData()
    const { dispatch: alertDispatch } = useContext(AlertContext);
    const { dispatch, loading } = useContext( CollectContext );

    const [amenities, setAmenities] = useState([]);


    useEffect(() => {

        const getData = async () => {
            if(userData.appId){
                const allAmenities = await getAllAmenities(userData.appId,token);
                setAmenities(allAmenities);
            }
        }
        getData();

    }, [userData])

    if(!loading) {

        return (

            <>
                <Ad/>

                <div className="text-center hero-content pt-0 pb-0 mb-5" >
                    <h1 className='text-3xl card-title'>Amenidades </h1>
                </div>

                <div className='w-full flex justify-center mb-8 gap-8 mt-9'>
                    <button onClick={() => navigate(`/amenity/create`)} className='mr-6 w-10/12 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg'>
                        Agregar amenidad
                    </button>

                </div>

                {amenities && amenities.length> 0 &&

                    <div className="p-2 h-screen ">
                        <div className="overflow-auto rounded-lg shadow hidden md:block relative mt-2">
                            <table className="w-full">
                                <thead className="bg-gray-50 border-b-2 border-gray-200 dark:bg-slate-800 ">
                                <tr>
                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Nombre</th>
                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Horario</th>
                                    <th className="w-16 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Aprobación </th>
                                    <th className="w-16 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Disponible</th>
                                    {/*<th className="w-24 p-3 text-lg font-semibold tracking-wide text-left dark:text-white dark:border-slate-600">Actualizado por</th>*/}
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-100 relative ">
                                {amenities.map(amenity => ( <AmenityRow key={amenity.id} amenity={amenity}/>))}
                                </tbody>
                            </table>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:hidden">
                            {amenities.map(amenity => ( <AmenityCard key={amenity.id} amenity={amenity}/> ))}
                        </div>
                    </div>

                    // <div className="text-center text-lg">
                    //     <p className=''> {!existCollects && "No hay cobros relacionados"}</p>
                    // </div>

                }
            </>
        )


    } else {
        return (
            <Spinner />
        )
    }

}

export default AmenityResult
