import AddressSearch from "./AddressSearch"
import AddressResults from "./AddressResults"

function Addresses() {
  return (
    <>
      <AddressSearch />
      <AddressResults />
    </>
  )
}

export default Addresses