const loginReducer = (state, action) => {
  switch(action.type) {

    case 'AUTH_USER':
      return {
        ...state,
        userData: action.payload,
        token: action.payload.token,
        isAuthenticated: true,
        loading: false,
      }
    case 'APP_SETTINGS':
      return {
        ...state,
        settings: action.payload.settings,
        loading: false,
      }
    case 'LOGOUT_USER':
      return {
        ...state,
        userData: {},
        token: '',
        isAuthenticated: false,
        loading: false,
      }
      case 'GET_USERS':
        return {
          ...state,
          users: action.payload,
          loading: false,
        }
      case 'GET_PREV_PAGE':
        return {
          ...state,
          previousPage: action.payload,
        }
      case 'GET_CURR_PAGE':
        return {
          ...state,
          currentPage: action.payload,
        }
      case 'GET_LAST_PAGE':
        return {
          ...state,
          lastPage: action.payload,
        }
      case 'GET_USER':
        return {
          ...state,
          user: action.payload,
          loading: false,
        }
      case 'ADD_USER':
        return {
          ...state,
          newUser: action.payload,
          loading: false,
        }
      case 'SET_PASS':
        return {
          ...state,
          tempPass: action.payload,
          loading: false,
        }
      case 'GET_ADDRESS':
        return {
          ...state,
          address: action.payload,
          loading: false,
        }
    case 'GET_PERMISSIONS':
      return {
        ...state,
        permissions: action.payload,
        loading: false,
      }
      case 'CLEAR_USERS':
        return {
          ...state,
          users: [],
        }
      case 'SET_MSG':
        return {
          ...state,
          message: action.payload,
          loading: false,
        }
    case 'SET_IS_UP':
      return {
        ...state,
        isUpUser : action.payload
      }
    default:
      return state
  }
}

export default loginReducer
