const visitReducer = (state, action) =>{
  switch(action.type) {
    case 'GET_VISITS':
      return {
        ...state,
        visits: action.payload,
        loading: false,
      }
    case 'GET_PREV_PAGE':
      return {
        ...state,
        previousPage: action.payload,
      }
    case 'GET_CURR_PAGE':
      return {
        ...state,
        currentPage: action.payload,
      }
    case 'GET_LAST_PAGE':
      return {
        ...state,
        lastPage: action.payload,
      }
    case 'GET_VISIT':
      return {
        ...state,
        visit: action.payload,
        loading: false,
      }
    case 'ADD_VISIT':
      return {
        ...state,
        newVisit: action.payload,
        loading: false,
      }
    case 'CLEAR_VISITS':
      return {
        ...state,
        visits: [],
      }
    case 'GET_VISTORS_AND_ADDRESSES':
      return {
        ...state,
        visitors: action.payload.visitors,
        addresses: action.payload.addresses,
        loading: false,
      }
    default:
      return state
  }
}

export default visitReducer