import {useContext, useEffect} from 'react'
import LoginContext from '../context/login/LoginContext'
import NoticeResult from "../components/notice/NoticeResult";
import {useNavigate} from "react-router-dom";
import useTokenAndUserData from "../context/token/useTokenAndUserData";
import {validatePermissions} from "../context/login/LoginActions";
import Ad from "../components/ads/Ad";
import NoticeContext from "../context/notice/NoticeContext";

function Notices() {

    window.scrollTo({
        top: 0,
        behavior: 'auto',
    })

    const { settings } = useContext(LoginContext);
    const { userData } = useTokenAndUserData();
    const { isUpNotice } = useContext( NoticeContext );
    //const Logo = settings.LOGO ? settings.LOGO : 'https://drive.google.com/uc?export=view&id=1mf81FCQsrYSVdN4tErBshm78BqG8H_dT';
    const navigate = useNavigate();

    return (
        <>

            <div className="w-full mx-auto lg:w-10/12">

                <Ad/>
                <div className="text-center hero-content pt-0 pb-0">
                    <h1 className='text-3xl card-title'>Avisos</h1>
                </div>

                <div className="my-5">
                    {validatePermissions(userData.userPermissions, 'NOTICE') &&
                        <div className='w-full flex justify-center mb-8 gap-8 mt-9'>
                            {isUpNotice ?
                                <button onClick={() => navigate(`/notice/create`)} className='mr-6 w-10/12 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg'>
                                    Crear Aviso
                                </button>
                                :
                                <button disabled={true} className='mr-6 w-10/12 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg'>
                                    Crear Aviso
                                </button>

                            }
                        </div>
                    }

                </div>

                <NoticeResult />

                {/*<div className="text-center hero-content">*/}
                {/*  <h2 className='card-title'>No hay nuevos avisos</h2>*/}
                {/*</div> */}

            </div>


        </>
    )
}

export default Notices
