import { useState, useContext, useEffect } from 'react'
import { MultiSelect } from 'react-multi-select-component'
import { useNavigate } from 'react-router-dom'
import LoginContext from '../../context/login/LoginContext'
import AlertContext from '../../context/alert/AlertContext'
import {addUser, getAllPermissions /*, getTempPassword */} from '../../context/login/LoginActions'
import { searchAddresses } from '../../context/address/AddressActions'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import Ad from "../ads/Ad";

// const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
// const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
// const EMAIL_REGEX = /^[a-zA-Z0-9.!%&/=?_~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+\.(com|gov|in|jo|org|mx|net)$/
const EMAIL_REGEX = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/

let roles = [
  {
    role: 'ADMIN',
    roleName: 'Administrador',
    description: 'Administrador'
  },
  {
    role: 'GUARD',
    roleName: 'Guardia',
    description: 'Guardia de seguridad'
  },
  {
    role: 'RESIDENT',
    roleName: 'Residente',
    description: 'Residente'
  }
]

function AddUser() {
  const { address, dispatch, permissions, userData } = useContext(LoginContext)
  const { token } = useTokenAndUserData();
  const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
  const navigate = useNavigate()

  function formatValue(value, first) {
    const prefix = first ? '' : ' - '
    if(value === null || value === '' || !value)
    {
      return ''
    }
    return `${prefix}${value}`
  }

  useEffect(() => {
    alertDispatch({ type: 'REMOVE_ALERT'})
    dispatch({ type: 'SET_LOADING' })

    const getVisitData = async () => {

      let addressData = await searchAddresses({keywords: '', pageSize: 2000, token})

      addressData = addressData.data.map((item) => ({
        value: item.addressId,
        label: `${formatValue(item.addressUnit, true)}${formatValue(item.addressBuilding)}${formatValue(item.addressIntNum)}`
      }));

      let permissionsData = await getAllPermissions({token});

      permissionsData = permissionsData.data.map((item) => ({
        value:  item.permissionId,
        label: `${item.permissionDescription}`
      }));

      if(userData.permissions?.includes('RESIDENT')) {
        permissionsData = permissionsData.filter(perm => {return (perm.value !== 4 && perm.value !== 8 && perm.value !== 9 && perm.value !== 10 )})
        roles = [
          {
            role: 'RESIDENT',
            roleName: 'Residente',
            description: 'Residente'
          }
        ]
      }

      dispatch({ type: 'GET_ADDRESS', payload: addressData });
      dispatch({ type: 'GET_PERMISSIONS', payload: permissionsData });

    }

    getVisitData()

  }, [dispatch, token, alertDispatch, userData])

  const [userName, setName] = useState('')
  const [userEmail, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(true)
  const [userRole, setRole] = useState('')
  const [comment, setComment] = useState('')
  const [addresses, setAddresses] = useState([])
  const [perms, setPerms] = useState([])

  const handleChangeName = (e) => setName(e.target.value)
  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
    if(e.target.value === '') {
      setValidEmail(true)
    } else {
      setValidEmail(EMAIL_REGEX.test(e.target.value))
    }
  }
  const handleChangeRole = (e) => {
    if(e.target.value !== 'RESIDENT') {
      setAddresses([])
    }
    setRole(e.target.value)
  }
  const handleChangeComment = (e) => setComment(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if(!validEmail) {
      setAlert('Por favor ingresa correo con formato valido', 'error')
    } else if(userName === '' || userEmail === '' || userRole === '') {
      setAlert('Por favor ingresa todos los datos requeridos', 'error')
    } else if(userRole === 'RESIDENT' && addresses.length <= 0) {
      setAlert('Por favor ingresa una unidad', 'error')
    }else {
      dispatch({ type: 'SET_LOADING' })
      // const userData = await getTempPassword()

      const newUser = await addUser({
        userName,
        userEmail: userEmail.toLowerCase().trim(),
        userPass: 'userPass',
        userRole,
        permissions : perms.map((item) => (item.value)),
        comment,
        addresses: addresses.map((item) => (item.value))
      }, token)

      if(Object.prototype.hasOwnProperty.call(newUser, 'userId')) {

        dispatch({ type: 'ADD_USER', payload: newUser })
        dispatch({ type: 'GET_USERS', payload: []})
        dispatch({ type: 'GET_LAST_PAGE', payload: false })
        dispatch({ type: 'GET_PREV_PAGE', payload: 0 })
        dispatch({ type: 'GET_CURR_PAGE', payload: 1 })

        setName('')
        setEmail('')
        setRole('')
        setComment('')

        navigate(`/users/${newUser.userId}`)

        // navigate(`/users/${newUser.userId}/${userData.password}`)
      } if(Object.prototype.hasOwnProperty.call(newUser, 'error', )) {
        setAlert(newUser.error, 'error')
      }
      
    }
  }

  return (
    <div className="w-full mx-auto lg:w-10/12">
      <Ad/>

      <div className="text-center hero-content">
        <h1 className='text-3xl card-title'>Agregar Usuario</h1>
      </div>

      <form onSubmit={handleSubmit}>
        <div className='form-control'>

          <label className='mt-4 text-lg'>
            Nombre * :
            <input
              type='text'
              className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
              placeholder='Nombre'
              value={userName}
              onChange={handleChangeName}
            />
          </label>

          <label className='mt-4 text-lg'>
            Correo * :
            <input
              type='text'
              className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
              placeholder='Correo'
              value={userEmail}
              onChange={handleChangeEmail}
            />
          </label>
          {!validEmail && (
            <label className='mt-4 text-lg text-error'>Correo con formato inválido</label>
          )}

          <label className='mt-4 text-lg lg:text-md md:text-md'>
            Rol * :
            <select
              type='select'
              className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
              placeholder='Rol del usuario'
              value={userRole}
              onChange={handleChangeRole}
            >
              <option disabled defaultValue key='' value=''>Selecciona una opción</option>
              {roles.map((role) => (
                <option key={role.role} value={role.role}>
                  {role.roleName}
                </option>
              ))}
            </select>
          </label>

          {userRole === 'RESIDENT' && (
              <>
                <div className='mt-4'>
                  <h1 className='text-lg lg:text-md md:text-md'>Unidad * :</h1>

                  <MultiSelect
                      className='mt-2 pl-2 pr-0 pt-2.5 bg-gray-200 input input-lg text-black'
                      options={address}
                      value={addresses}
                      onChange={setAddresses}
                      labelledBy={'Selecciona una opción'}
                  />
                </div>
              </>
          )}


          <div className='mt-4'>
            <h1 className='text-lg lg:text-md md:text-md'>Permisos * :</h1>
            <MultiSelect
                className='mt-2 pl-2 pr-0 pt-2.5 bg-gray-200 input input-lg text-black'
                options={permissions}
                value={perms}
                onChange={setPerms}
                labelledBy={'Selecciona una opción'}
            />
          </div>

          <label className='mt-4 text-lg'>
            Comentario:
            <textarea

              className='mt-2 w-full h-40 pr-4 bg-gray-200 input input-lg text-black'
              placeholder='Comentario'
              value={comment}
              onChange={handleChangeComment}
            />
          </label>

          <div className="mt-6 card-actions">
            <button type='submit' className='btn btn-outline'>
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddUser
