import axios from 'axios';
// const userServiceUrl = process.env.REACT_APP_USER_SERVICE || 'http://70.35.204.186:3000/pjd-cotoadmin-user'
const host = process.env.REACT_APP_HOST || window.location.origin
// const service = `${host}${process.env.REACT_APP_AMENITY_SERVICE || '/amenity/api/v1/'}`

const service = 'http://localhost:8090/survey/api/v1/'

const instance = axios.create({
    baseURL: service,
})

export const addSurvey = async (request) => {
    const response = await instance.post(`/create`, request);
    return response.data.response
}

export const updateSurvey = async (request) => {
    const response = await instance.post(`amenity/update`, request);
    return response.data.response
}

export const getSurveyById = async (appId, id) => {
    const response = await instance.get(`amenity/get_amenity`, {
        params:{
            appId,
            id
        }
    });
    return response.data.response
}
export const getSurveys = async (appId) => {
    try {
        // console.log('Searching tags')
        const response = await instance.get(`/all`,{
            params:{
                appId
            }
        });
        // console.log(response.data.response);
        return response.data.response;
    } catch (error) {
        throw new Error('Failed to retrieve days.');
    }
}

export const getAllQuestionsBySurvey = async (id, appId) => {
    try {
        // console.log('Searching tags')
        const response = await instance.get(`/find_by_id`,{
            params:{
                id:id,
                appId:appId
            }
        });
        // console.log(response.data.response);
        return response.data.response;
    } catch (error) {
        throw new Error('Failed to retrieve days.');
    }
}



export const getTypes = async () => {
    try {
        const response = await instance.get(`/get_types`);
        return response.data.response;

    } catch (error) {
        throw new Error('Failed to retrieve surveys.');
    }
}

