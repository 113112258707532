import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import {useContext, useEffect, useRef, useState} from "react";
import AlertContext from "../../context/alert/AlertContext";
import {useNavigate, useParams} from "react-router-dom";
import CollectContext from "../../context/collect/CollectContext";
import {getAllConcepts} from "../../context/collect/CollectActions";
import LoginContext from "../../context/login/LoginContext";
import {searchAddresses} from "../../context/address/AddressActions";
import {formatValue} from "../../utils/utilsFunctions";
import {MultiSelect} from "react-multi-select-component";
import RecurrentCollectContext from "../../context/RecurrentCollect/RecurrentCollectContext";
import {
    getAllCron,
    searchRecurrentCollectByIdAndAppId, updateRecurrentCollect
} from "../../context/RecurrentCollect/RecurrentCollectActions";
import Ad from "../ads/Ad";


function UpdateRecurrentCollect() {

    const { dispatch, crons} = useContext( RecurrentCollectContext );
    const { dispatch: collectDispatch, concepts} = useContext( CollectContext );
    const { address, dispatch : loginDispatch} = useContext(LoginContext)
    const { token } = useTokenAndUserData();
    const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
    const { userData } = useTokenAndUserData();

    const [user, setUser] = useState('');
    const [title, setTitle] = useState('');
    const [appId, setAppId] = useState(0);
    const [conceptId, setConceptId] = useState(0);
    const [description, setDescription] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [isEnable, setIsEnable] = useState(true);
    const [cron, setCron] = useState('');
    const [unitJobs, setUnitJobId] = useState([]);
    const [jobName, setJobName] = useState('');
    const [updatedBy, setUpdatedBy] = useState('');
    const [collect, setCollect] = useState();
    const enableRef = useRef();

    let { id } = useParams();

    useEffect(() => {

        alertDispatch({ type: 'REMOVE_ALERT'})
        dispatch({ type: 'SET_LOADING' })

        const getData = async () => {

            let getConcepts = await getAllConcepts(token);
            let getCron = await getAllCron(token);

            let addressData = await searchAddresses({keywords: '', pageSize: 2000, token})

            addressData = addressData.data.map((item) => ({
                value: item.addressId,
                label: `${formatValue(item.addressUnit, true)}${formatValue(item.addressBuilding)}${formatValue(item.addressIntNum)}`
            }));

            loginDispatch({ type: 'GET_ADDRESS', payload: addressData });

            collectDispatch({type: 'GET_CONCEPTS', payload: getConcepts});
            dispatch({type: 'GET_CRON', payload: getCron});
        }

        setAppId(userData.appId);

        getData();

    }, [dispatch, loginDispatch, token, alertDispatch, userData, collectDispatch]);

    useEffect(() => {

        const getData = async () => {

            if(userData.appId){
                let job = await searchRecurrentCollectByIdAndAppId(id, userData.appId, token);
                setUser(userData.email);
                setConceptId(job.concept.id);
                setDescription(job.description);
                setQuantity(job.quantity);
                setTitle(job.title);

                setUpdatedBy(job.updatedBy ? job.updatedBy : "");

                if(enableRef.current){
                    setIsEnable(job.isEnable);
                    enableRef.current.checked  = job.isEnable;

                }
                setCron(job.cron.cron);
                setJobName(job.jobName);

                const units = job.unitJob.map(u => (
                    {value : u.unitId, label : u.unitName
                    }));
                setUnitJobId(units);

            }
        }

        getData();

    }, [ id, userData, setCollect]);


    const handleChangeConcept = (e) => setConceptId(e.target.value);
    const handleChangeDescription = (e) => setDescription(e.target.value);
    const handleChangeTitle = (e) => setTitle(e.target.value);

    const handleChangeIsEnable = (e) => setIsEnable(!isEnable);
    const handleChangeQuantity= (e) => setQuantity(e.target.value);
    const handleChangeUnitJob = async (e) => {
        // console.log(e)
        setUnitJobId(e)
    };
    const handleChangeCron = async (e) => setCron(e.target.value);

    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()
        const unitJob = unitJobs.map((item) => ({
            unitId: item.value,
            unitName: item.label
        }));


        if(unitJob.length === 0 ) {
            setAlert('Por favor ingresa Unidades', 'error')
        } else {

            alertDispatch({ type: 'SET_LOADING' })

            // console.log({
            //     appId,
            //     conceptId,
            //     quantity,
            //     cronType: cron,
            //     isEnable,
            //     jobName,
            //     description,
            //     unitJob
            // });

            const newRecurrentCollect = await updateRecurrentCollect({
                title,
                updatedBy : user,
                appId,
                conceptId,
                quantity,
                cronType: cron,
                isEnable,
                jobName,
                description,
                unitJob
            }, token)

            dispatch({ type: 'ADD_RECURRENT_COLLECT', payload: newRecurrentCollect });
            dispatch({ type: 'GET__RECURRENT_COLLECTS', payload: []});

            setConceptId(0);
            setDescription('');
            setQuantity(0);

            navigate(`/recurrent_collects`);
        }
    }

    return (
        <div className="w-full mx-auto lg:w-10/12">

            <Ad/>

            <div className="text-center hero-content">
                <h1 className='text-3xl card-title'>Cobranza recurrente</h1>
            </div>

            <form onSubmit={handleSubmit}>

                <div className='form-control'>


                    <label className='mt-4 text-lg'>
                        Nombre * :
                        <input
                            type='text'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Nombre...'
                            value={title}
                            onChange={handleChangeTitle}
                        />
                    </label>


                    <label className='mt-4 text-lg'>
                        Recurrencia * :
                        <select
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Cada cuanto se hara la transaccion'
                            value={cron}
                            onChange={handleChangeCron}
                        >
                            <option defaultValue key='' value=''>Selecciona una opción</option>

                            {crons.map((type) => (
                                <option key={type.cron} value={type.cron}>
                                    {type.description}
                                </option>
                            ))}
                        </select>
                    </label>


                    <label className='mt-4 text-lg'>
                        Unidades * :
                        <MultiSelect
                            className='mt-2 pl-2 pr-0 pt-2.5 bg-gray-200 input input-lg text-black'
                            options={address}
                            value={unitJobs}
                            onChange={handleChangeUnitJob}
                            labelledBy={'Selecciona una opción'}
                        />

                    </label>

                    <label className='mt-4 text-lg'>
                        Cantidad * :
                        <input
                            type='number'
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Cantidad'
                            value={quantity}
                            min="0"
                            onChange={handleChangeQuantity}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Concepto * :
                        <select
                            className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                            placeholder='Tipo de concepto'
                            value={conceptId}
                            onChange={handleChangeConcept}
                        >
                            <option defaultValue key='' value=''>Selecciona una opción</option>

                            {concepts.map((type) => (

                                <option key={type.id} value={type.id}>
                                    {type.description}
                                </option>
                            ))}
                        </select>
                    </label>

                    <label className='mt-4 text-lg'>
                        Descripción:
                        <textarea
                            type='text'
                            className='mt-2 w-full h-40 pr-4 bg-gray-200 input input-lg text-black'
                            placeholder='Descripción'
                            value={description}
                            onChange={handleChangeDescription}
                        />
                    </label>
                    {updatedBy.length > 0 &&
                        <label className='mt-4 text-lg'>
                            Actualizado:
                            <input
                                type="text"
                                disabled={true}
                                className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                                placeholder='Balance Antes'
                                value={updatedBy}
                            />
                        </label>
                    }

                    <label className='label cursor-pointer mt-4 '>
                        <span className="text-lg">Habilitar:</span>
                        <input
                            type='checkbox'
                            className='toggle'
                            value={isEnable}
                            ref={enableRef}
                            onChange={handleChangeIsEnable}
                        />
                    </label>


                    <div className="mt-6 card-actions">
                        <button type='submit' className='btn btn-outline'>
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default UpdateRecurrentCollect
