import {FaRegCheckCircle} from "react-icons/fa";
import {useEffect} from "react";
import {Link} from "react-router-dom";

function AmenityRow( { amenity }) {

    return (
        <>
            <tr className="bg-white dark:bg-slate-700">

                <td className="p-3 text-lg text-gray-700   dark:text-slate-400">
                    <Link to={`/amenity/update/${amenity.id}`} className="w-100%">
                        {amenity.name}
                    </Link>
                </td>

                <td className="p-3 text-sm text-gray-700  dark:text-slate-400">
                    <Link to={`/amenity/update/${amenity.id}`}  className="w-100%">
                        {amenity.dayScheduleTime.map(day =>
                            <p key={day.day}> {day.day + ' : ' + day.scheduleTime} </p>
                        )}
                    </Link>
                </td>

                <td  className={`p-3 text-lg whitespace-nowrap `}>
                    <Link to={`/amenity/update/${amenity.id}`}  className="w-100%">

                    {amenity.needApproval && <FaRegCheckCircle /> }
                    </Link>
                </td>

                <td className={`p-3 text-lg whitespace-nowrap `}>
                    {amenity.isAvailable && <FaRegCheckCircle /> }
                </td>

            </tr>
        </>

    );
}

export default AmenityRow;
