const AdsReducer = (state, action) =>{
    switch(action.type) {

        case 'GET_ADS':
            return {
                ...state,
                ads: action.payload,
                loading: false,
                isDataLoaded: true
            }
        case 'GET_AD':
            return {
                ...state,
                ad: action.payload,
                loading: false,
            }
        case 'ADD_ADS':
            return {
                ...state,
                newAd: action.payload,
                loading: false,
            }
        case 'GET_TAGS':
            return {
                ...state,
                tags: action.payload,
                loading: false,
            }
        default:
            return state
    }
}

export default AdsReducer
