import {FaCloudDownloadAlt, FaStar, FaTimes} from "react-icons/fa";
import {Link} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import {validatePermissions, validateRoles} from "../../context/login/LoginActions";
import {downloadFile, removeFile, searchAllFiles} from "../../context/files/FileActions";
import FileContext from "../../context/files/FileContext";

function FileRow( { file }) {

    const { userData, token } = useTokenAndUserData();
    const [toUrl, setToUrl] = useState('');
    const { dispatch } = useContext( FileContext );
    const [appName, setAppName] = useState('');


    useEffect(() => {

        if(validateRoles(userData.permissions, 'isResident')){
            setToUrl(`/files/${file.id}`)
        }else {
            setToUrl(`/files/update/${file.id}`)
        }
    }, [userData]);

    useEffect(() => {
        if(userData.app.appName){
            setAppName(userData.app.appName.replace(" ", ""))
        }
    }, [userData]);

    const onClickDownload = async () => {
        const response  = await downloadFile(file.fileName, appName, token);

        if (response.status === 200) {
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = file.fileName;
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }

    const handleDelete = async () => {
        const response = await removeFile(file.fileName, userData.appId, userData.email, appName, token)
        const files = await searchAllFiles(userData.appId, token);
        dispatch({ type: 'GET_FILES', payload: files })
    }

    return (
        <>
            <tr className="bg-white dark:bg-slate-700">

                    <td className="p-3 text-lg text-gray-700 whitespace-nowrap dark:text-slate-400">
                        <Link to={toUrl}>
                            {file.isPriority && <FaStar className='text-xl md:text-md' /> }
                        </Link>
                    </td>

                    <td className="p-3 text-lg text-gray-700 whitespace-nowrap  dark:text-slate-400">
                        <Link to={toUrl}>{file.startDate} </Link>
                        </td>

                    <td className="p-3 text-lg text-gray-700 whitespace-nowrap  dark:text-slate-400">
                        <Link to={toUrl}> {file.title} </Link>
                    </td>

                    {/*<td className="p-3 text-lg text-gray-700 whitespace-nowrap  dark:text-slate-400">*/}
                    {/*    <Link to={toUrl}>*/}
                    {/*        <span className={`p-1.5 text-xs font-medium uppercase tracking-wider rounded-lg bg-opacity-50 ${colorType}`}>*/}
                    {/*            {file.updatedBy}*/}
                    {/*        </span>*/}
                    {/*    </Link>*/}
                    {/*</td>*/}

                    <td className="p-3 text-lg text-gray-700 whitespace-nowrap dark:text-slate-400">

                        <div className=''>
                            <button
                                className='close tooltip rounded-full'
                                data-tip='Descargar Aviso'
                                onClick={onClickDownload}
                            >
                                <FaCloudDownloadAlt className='text-4xl md:text-3xl' color='primary '/>
                            </button>
                        </div>
                    </td>


                {validatePermissions(userData.userPermissions, 'FILES') &&
                    <td className="p-3 text-lg text-gray-700 whitespace-nowrap dark:text-slate-400">
                        <div className=''>
                            <button
                                className='close tooltip rounded-full'
                                data-tip='Remover Aviso'
                                onClick={handleDelete}
                            >
                                <FaTimes className='text-4xl md:text-3xl' color='primary '/>
                            </button>
                        </div>
                    </td>
                }

            </tr>

        </>

    );
}

export default FileRow;
