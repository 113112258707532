import axios from 'axios';
// const userServiceUrl = process.env.REACT_APP_USER_SERVICE || 'http://70.35.204.186:3000/pjd-cotoadmin-user'
const host = process.env.REACT_APP_HOST || window.location.origin
const service = `${host}${process.env.REACT_APP_COLLECT_AD || '/ad'}`

// const service = `http://localhost:8092/ad`

const db = axios.create({
    baseURL: service,
})

export const addAd = async (formAdDto, token) => {
    const response = await db.post(`/api/v1/load_ad`, formAdDto, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    });

    // console.log(response.data);
    return response.data.response
}
export const searchAllTags = async (token) => {
    try {
        // console.log('Searching tags')
        const response = await db.get(`/api/v1/tags`, {
            headers: {
                Authorization: `Bearer ${token}`
            }});
        // console.log(response.data.response);
        return response.data.response;
    } catch (error) {
        console.log(error);
        throw new Error('Failed to retrieve tags.');
    }
}

export const getAllAds = async (appId, token) => {
    try {
        if(appId){
            // console.log(`${userServiceUrl}/api/v1/${appId}`)
            const response = await db.get(`/api/v1/${appId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }});

            // console.log(response.data.response);
            return response.data.response;
        }
    } catch (error) {
        console.log(error);
        throw new Error('Failed to retrieve ads.');
    }
}

