import { useState, useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import VisitorContext from '../../context/visitors/VisitorContext'
import AlertContext from '../../context/alert/AlertContext'
import { getVisitor, updateVisitor } from '../../context/visitors/VisitorActions'
import {validatePermissions, validateRoles} from '../../context/login/LoginActions'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import Ad from "../ads/Ad";

// const trustLevels = [
//   {
//     level: 'HIGH',
//     levelName: 'Alto',
//     description: 'Visitante muy confianble'
//   },
//   {
//     level: 'MEDIUM',
//     levelName: 'Medio',
//     description: 'Visitante confiable'
//   },
//   {
//     level: 'LOW',
//     levelName: 'Bajo',
//     description: 'Visitante no confiable'
//   }
// ]

function UpdateVisitor() {
  const { token, userData } = useTokenAndUserData()
  const { dispatch } = useContext(VisitorContext)
  const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
  const isAdmin = validateRoles(userData.permissions, 'isAdmin')
  const params = useParams()
  const navigate = useNavigate()

  const [visitorName, setName] = useState('')
  const [visitorPlates, setPlates] = useState('')
  // const [visitorTrustLevel, setTrustLevel] = useState('')
  const visitorTrustLevel = 'MEDIUM'
  const [description, setDescription] = useState('')
  const [recurrent, setRecurrent] = useState(false)
  const [provider, setProvider] = useState(isAdmin)
  const [blacklist, setBlackList] = useState(false)
  const [blacklistComment, setComment] = useState('')

  useEffect(() => {
    alertDispatch({ type: 'REMOVE_ALERT'})
    dispatch({ type: 'SET_LOADING' })
    const getVisitorData = async () => {
      const visitorData = await getVisitor(params.visitorId, token)
      // console.log(visitorData);
      dispatch({ type: 'GET_VISITOR', payload: visitorData })
      setName(visitorData.visitorName)
      setPlates(visitorData.visitorPlates)
      // setTrustLevel(visitorData.visitorTrustLevel)
      setDescription(visitorData.description)
      setRecurrent(visitorData.recurrent)
      setProvider(visitorData.provider)
      setBlackList(visitorData.blacklist)
      setComment(visitorData.blacklistComment)
    }

    getVisitorData()
  }, [dispatch, params.visitorId, token, alertDispatch])

  const handleChangeName = (e) => setName(e.target.value)
  const handleChangePlates = (e) => setPlates(e.target.value)
  // const handleChangeTrustLevel = (e) => setTrustLevel(e.target.value)
  const handleChangeDescription = (e) => setDescription(e.target.value)
  const handleChangeRecurrent = (e) => setRecurrent(!recurrent)
  // const handleChangeProvider = (e) => setProvider(!provider)
  const handleChangeBlackList = (e) => {
    if(blacklist) {
      setComment('')
    }
    setBlackList(!blacklist)
  }
  const handleChangeComment = (e) => setComment(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if(visitorName === '') {
      setAlert('Por favor ingresa todos los datos requeridos', 'error')
    } else {
      dispatch({ type: 'SET_LOADING' })

      const updatedVisitor = await updateVisitor(params.visitorId, {
        visitorName,
        visitorPlates,
        visitorTrustLevel,
        description,
        recurrent,
        provider,
        blacklist,
        blacklistComment,
      }, token)

      dispatch({ type: 'GET_VISITOR', payload: updatedVisitor })
      dispatch({ type: 'GET_VISITORS', payload: []})
      dispatch({ type: 'GET_LAST_PAGE', payload: false })
      dispatch({ type: 'GET_PREV_PAGE', payload: 0 })
      dispatch({ type: 'GET_CURR_PAGE', payload: 1 })

      setName('')
      setPlates('')
      // setTrustLevel('')
      setComment('')
      setDescription('')
      setRecurrent(false)
      // setProvider(false)
      setBlackList(false)

      navigate(`/visitors/${params.visitorId}`)
    }
  }

  return (
    <div className="w-full mx-auto lg:w-10/12">

      <Ad/>

      <div className="text-center hero-content">
        <h1 className='text-3xl card-title'>Actualizar Visitante</h1>
      </div>

      <form onSubmit={handleSubmit}>
        <div className='form-control'>

          <label className='mt-4 text-lg'>
            Nombre * :
            <input
              type='text'
              className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
              placeholder='Nombre'
              value={visitorName}
              onChange={handleChangeName}
            />
          </label>

          {validatePermissions(userData.userPermissions, 'visitors') && (
            <label className='mt-4 text-lg'>
              Placas:
              <input
                type='text'
                className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                placeholder='Placas'
                value={visitorPlates}
                onChange={handleChangePlates}
              />
            </label>
          )}

          {/* <label className='mt-4 text-lg lg:text-md md:text-md'>
            Nivel de confiabilidad* :
            <select
              type='select'
              className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
              placeholder='Nivel de confiabilidad'
              value={visitorTrustLevel}
              onChange={handleChangeTrustLevel}
            >
              <option disabled defaultValue key='' value=''>Selecciona una opción</option>
              {trustLevels.map((level) => (
                <option key={level.level} value={level.level}>
                  {level.levelName}
                </option>
              ))}
            </select>
          </label> */}

          <label className='mt-4 text-lg'>
            Descripción:
            <textarea

              className='mt-2 w-full h-40 pr-4 bg-gray-200 input input-lg text-black'
              placeholder='Descripción'
              value={description}
              onChange={handleChangeDescription}
            />
          </label>

          {validatePermissions(userData.userPermissions, 'visitors') && (
            <label className="label cursor-pointer mt-4 ">
              <span className="text-lg">Visitante Recurrente:</span> 
              <input 
                type='checkbox'
                className='toggle'
                value={recurrent}
                onChange={handleChangeRecurrent}
                checked={recurrent}
              />
            </label>
          )}

          {/* <label className="label cursor-pointer mt-4 ">
            <span className="text-lg">Proveedor:</span> 
            <input 
              type='checkbox'
              className='toggle'
              value={provider}
              onChange={handleChangeProvider}
            />
          </label> */}

          {validatePermissions(userData.userPermissions, 'BLACKLIST') && (
            <label className="label cursor-pointer mt-4 ">
              <span className="text-lg">Black List:</span> 
              <input 
                type='checkbox'
                className='toggle'
                value={blacklist}
                onChange={handleChangeBlackList}
                checked={blacklist}
              />
            </label>
          )}

          {blacklist && (
            <label className='mt-4 text-lg'>
              Comentario:
              <textarea

                className='mt-2 w-full h-40 pr-4 bg-gray-200 input input-lg text-black'
                placeholder='Comentario Black List'
                value={blacklistComment}
                onChange={handleChangeComment}
              />
            </label>
          )}

          <div className="mt-6 card-actions">
            <button type='submit' className='btn btn-outline'>
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default UpdateVisitor
