import {createContext,  useEffect, useReducer} from "react";
import TokenReducer from "./TokenReducer";
import useToken from "../login/useToken";

const TokenContext = createContext();

export const TokenProvider = ({ children }) => {

    const { token, userData } = useToken();

    const INITIAL_STATE = {
        token : '',
        userData: {},
        isDataLoaded: false
    }
    const [state, dispatch] = useReducer(TokenReducer, INITIAL_STATE);
    const {isDataLoaded} = state;

    function setData(data) {
        dispatch({
            type: 'SET_TOKEN_AND_USER_DATA',
            payload :data
        })
    }

    useEffect( () => {

        if (!isDataLoaded) {
            const data = {token, userData}
            // make a call to the backend here
            // and use setData to set the token, expiry and userData in the state when it's returned
            setData(data);
        }

    }, [isDataLoaded]);


    return (
        <TokenContext.Provider value ={{
            token, userData
        }}>
        { children }

    </TokenContext.Provider>
    );
}
 export default TokenContext;


