import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import LoginContext from '../context/login/LoginContext'
import AlertContext from '../context/alert/AlertContext'
import { authUser, sendRestoreUrl } from '../context/login/LoginActions'
import PropTypes from 'prop-types'
import cotoAdminLogo from '../components/layout/assets/cotoadmin-logo.jpg'
import useTokenAndUserData from "../context/token/useTokenAndUserData";

function Login({ setToken }) {
  const navigate = useNavigate()
  const { dispatch } = useContext(LoginContext)
  const { token } = useTokenAndUserData()
  const { setAlert } = useContext(AlertContext)
  const [userEmail, setEmail] = useState('')
  const [userPass, setPass] = useState('')


  const handleChangeEmail = (e) => setEmail(e.target.value)

  const handleChangePass = (e) => setPass(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if(userEmail === '' || userPass === '') {
      setAlert('Por favor ingresa todos los datos requeridos', 'error')
    } else {
      dispatch({ type: 'SET_LOADING' })
      const userData = await authUser({
        userEmail: userEmail.trim(),
        userPass,
        host: process.env.REACT_APP_HOST || window.location.origin,
      })


      if(Object.prototype.hasOwnProperty.call(userData, 'token')) {
        setToken(userData.token)
        dispatch({ type: 'AUTH_USER', payload: userData })
        setEmail('')
        setPass('')
        // window.location.reload()

        window.location = '/home'
      } else {
        setAlert(userData, 'error')
      }
    }
  }

  const handleRecoverPass = async (e) => {
    e.preventDefault()
    if(userEmail === '') {
      setAlert('Para recuperar la contraseña, por favor ingresa el correo.', 'error')
    } else {
      dispatch({ type: 'SET_LOADING' })
      const userData = await sendRestoreUrl( userEmail.trim())

      if(Object.prototype.hasOwnProperty.call(userData, 'error')) {
        setAlert(`El usuario no fue notificado porque hubo un error en el servicio de notificaciones. Favor de contactar a soporte.`, 'error2')
      } else if (userData.includes('Hemos enviado un link para recuperar tu contraseña a tu correo:')) {
        const email = userData.replace('Hemos enviado un link para recuperar tu contraseña a tu correo: ', '')
        setAlert(`Hemos enviado un correo a ${email} para recuperar tu contraseña.`, 'info')
      } else {
        setAlert(userData, 'error')
      }
      setEmail('')
      setPass('')
    }
  }

  // const handleSingOut = () => {
  //   localStorage.removeItem(keyToken)
  //   dispatch({ type: 'LOGOUT_USER', payload: {} })
  //   window.location = '/'
  // }

  return (
    <div className="w-full mx-auto lg:w-10/12">

      {!token ? (
        <>
          <div className="text-center hero-content">
            <div className='w-100 xl:w-80 lg:w-80 md:w-80 sd:w-80'> 
              <img
                className='text-center mx-auto'
                src={cotoAdminLogo}
                alt='Loading...'
              />
            </div>
            {/* <FaReact className='inline pr-2 text-6xl' /> */}
          </div>

          <form onSubmit={handleSubmit}>
            <div className='form-control'>
              <label className='mt-4 text-lg'>
                Correo:
                <input
                  type='text'
                  className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                  placeholder='Correo del usuario'
                  value={userEmail}
                  onChange={handleChangeEmail}
                />
              </label>

              <label className='mt-4 text-lg'>
                Contraseña:
                <input
                  type='password'
                  className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
                  placeholder='Contraseña'
                  value={userPass}
                  onChange={handleChangePass}
                />
              </label>

              <div className="card-actions">
                <button type='submit' className='btn btn-outline mt-6 mb-2.5 flex w-full items-center justify-center py-3 px-8 text-lg font-medium '>
                  Login
                </button>


              </div>

              <div className="card-actions items-center flex justify-between mt-5">
                <button className='text-gray-400' onClick={handleRecoverPass}>
                  Olvidé mi contraseña
                </button>

                <p className='text-center text-lg-bold text-gray-400'>
                  <a className='text-gray-400' href='https://pjdevelopers.com/cotoadmin/avisoprivacidad.html' target="_blank" rel="noreferrer">
                    Aviso de Privacidad
                  </a>
                </p>
              </div>
            </div>
          </form>
        </>  
      ) : 
      (
          navigate(`/home`)
        // navigate(`/home`)
        // <>
        //   {/* <div className="text-center hero-content">
        //     <FaReact className='inline pr-2 text-6xl' />
        //   </div> */}
        //   <h1 className='text-center hero-content text-3xl card-title pb-0'>
        //     Bienvenido {userData.name}
        //   </h1>
        //   <h3 className='text-center hero-content text-xl card-title pt-0 pb-0'>{userData.email}</h3>
        //   <div className="text-center hero-content mt-6 card-actions pt-0">
        //     <button onClick={handleSingOut} className='btn btn-outline'>
        //       Sign Out
        //     </button>
        //   </div>
        // </>
      )}

      {/*<p className='text-center text-lg-bold text-gray-400'>*/}
      {/*  <a className='text-gray-400' href='https://pjdevelopers.com/cotoadmin/avisoprivacidad.html' target="_blank" rel="noreferrer">*/}
      {/*  Aviso de Privacidad*/}
      {/*  </a>*/}
      {/*</p>*/}
      
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}

export default Login
