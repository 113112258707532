import { BsFillHouseDoorFill } from 'react-icons/bs'
import { useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import AddressContext from '../../context/address/AddressContext'
import Spinner from '../layout/Spinner'
import { getAddress } from '../../context/address/AddressActions'
import AlertContext from '../../context/alert/AlertContext'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";

function Address() {
  const { token } = useTokenAndUserData()
  const { address, loading, dispatch } = useContext(AddressContext)
  const { dispatch: alertDispatch } = useContext(AlertContext)

  const params = useParams()

  useEffect(() => {
    alertDispatch({ type: 'REMOVE_ALERT'})
    dispatch({ type: 'SET_LOADING' })
    const getAddressData = async () => {
      const addressData = await getAddress(params.addressId, token)
      dispatch({ type: 'GET_ADDRESS', payload: addressData })
    }

    getAddressData()
  }, [dispatch, params.addressId, token, alertDispatch])

  const {
    // addressId,
    // appId,
    addressUnit,
    addressBuilding,
    addressIntNum,
    addressPhone,
    comment,
    active,
    // createdOn,
    // createdBy,
    // updatedOn,
    // updatedBy
    } = address

  if(loading) {
    return <Spinner />
  } else {
    return (<>
      <div className="w-full mx-auto lg:w-10/12">
        <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 mt-4 md:grid-cols-3 mb-2 md:gab-8 flex">
          <div className="flex align-items-center justify-center custom-card-image mb-6 md:mb-0">
            <div className="rounded-lg">
              <figure>
                <BsFillHouseDoorFill className='w-60 h-60' />
              </figure>
            </div>
          </div>
  
          <div className="col-span-2 px-8">
            <div className="mb-6">
              <h1 className='text-3xl card-title'>
              {addressUnit && (addressUnit)} {addressBuilding && (`- ${addressBuilding}`)} {addressIntNum && (`- ${addressIntNum}`)}
              </h1>
              <p>{addressPhone && (`Telefono: ${addressPhone}`)}</p>
              <p>{ comment }</p>
            </div>
  
            <div className="grid grid-rows-2 xl:grid-rows-1 lg:grid-rows-1 md:grid-rows-1 sd:grid-rows-1 py-5 mb-6 rounded-lg shadow-md bg-base-100 stats">
              {active && (
                <div className='stat'>
                  <div className="stat-title text-md">Estado</div>
                  <div className="text-lg stat-value">{active ? ('Activo') : ('Inactivo')}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>   
    </>)
  }
}

export default Address
