import {useContext, useEffect, useState} from "react";
import AlertContext from "../../context/alert/AlertContext";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import FileContext from "../../context/files/FileContext";
import {useNavigate} from "react-router-dom";
import {addAmenity, getAllDays, getAllHours} from "../../context/amenity/AmenityActions";
import Select from 'react-select';
import TimeRangePicker from "./TimePicker";
import SelectColor from "./SelectColor";
import {colors} from "../../utils/constantest";
function AddAmenity() {

    const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
    const { userData, token } = useTokenAndUserData();
    const [selectedDays, setSelectedDays] = useState(null);
    const [name, setName] = useState('');
    const [needApproval, setNeedApproval] = useState(false);
    const [isAvailable, setIsAvailable] = useState(true);
    const [maxHour, setMaxHour] = useState(1);
    const [daysOfWeek, setDaysOfWeek] = useState([]);
    const [hours, setHours] = useState([]);
    const [color, setColor] = useState(colors[2]);

    const [daySchedule, setDaySchedule] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            const days = await getAllDays(token);

            const d = days.map(day => {
                return {
                    value: day.dayOfWeek,
                    label: day.dayOfWeek
                }
            });
            setDaysOfWeek(d);

            const h = await getAllHours(token);
            console.log(h);
            setHours(h);
        }
        getData();
    }, [token]);

    const handleChangeName = (e) => setName(e.target.value);
    const handleChangeMaxHour = (e) => {
        const value = e.target.value;
        if(value <= 0 || value >= 11){
            e.target.value = "";
        }
        console.log("")
        setMaxHour(e.target.value)
    };
    const handleChangeNeedApproval = (e) => setNeedApproval(!needApproval);
    const handleChangeIsAvailable = (e) => setIsAvailable(!isAvailable);

    const handleSubmit = async (e) => {
        e.preventDefault()

        if(name.length <= 0 || selectedDays.length <= 0 ) {
            setAlert('Por favor ingresa todos los datos requeridos', 'error')

        } else {
            alertDispatch({ type: 'SET_LOADING' })

            let daysSchedule = daySchedule;

            if (selectedDays.length !== daySchedule.length) {
                daysSchedule = daySchedule.filter(schedule =>
                    selectedDays.some(day => day.value === schedule.day)
                );
            }
            console.log(daysSchedule);

            const appId = userData.appId;
            const creationRequest = {
                name,
                needApproval,
                appId,
                isAvailable,
                maxHours: maxHour,
                color:color.name,
                daysOfWeek : daysSchedule,
                requestedBy: userData.email,
                createBy : userData.email,
                updatedBy : userData.email
            }

            console.log(creationRequest);

            const add = await addAmenity(creationRequest,token);
            console.log(add);

            // dispatch({ type: 'ADD_FILE', payload: newFile });
            // dispatch({ type: 'GET_FILES', payload: []});
            //
            navigate(`/amenity`);
        }
    }

    return (
        <div className="w-full mx-auto lg:w-10/12">
            <div className="text-center hero-content">
                <h1 className='text-3xl card-title'>Agregar Amenidad</h1>
            </div>

            <form onSubmit={handleSubmit}>
                <div className='form-control'>

                    <label className='mt-4 text-lg'>
                        Nombre * :
                        <input
                            className="mt-2 w-full pr-6 bg-gray-200 input input-lg text-black"
                            id="file_input"
                            type='text'
                            placeholder='Nombre'
                            onChange={handleChangeName} />

                    </label>

                    <label className="mt-4 text-lg">
                        Días de la semana * :
                        <div className="form-multiselect">
                            {daysOfWeek &&
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    name="colors"
                                    options={daysOfWeek}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={setSelectedDays}
                                />
                            }
                        </div>
                    </label>

                    {selectedDays && selectedDays.map(day =>
                        <TimeRangePicker key={day.value} day={day.value} setHourSelect={setDaySchedule} hourSelected={daySchedule} hours={hours}/>
                    )}

                    <label className='label cursor-pointer mt-4 '>
                        <span className="text-lg">Disponible:</span>
                        <input
                            type='checkbox'
                            className='toggle'
                            defaultChecked={true}
                            onChange={handleChangeIsAvailable}
                        />
                    </label>

                    <SelectColor color={color} setColor={setColor}/>

                    <label className='label cursor-pointer mt-4 '>
                        <span className="text-lg">Aprobación:</span>
                        <input
                            type='checkbox'
                            className='toggle'
                            value={needApproval}
                            onChange={handleChangeNeedApproval}
                        />
                    </label>

                    <label className='mt-4 text-lg'>
                        Horas permitidas:
                        <input
                            className="mt-2 w-full pr-6 bg-gray-200 input input-lg text-black"
                            type='number'
                            min="1"
                            max="12"
                            value={maxHour}
                            placeholder='Hora maxima'
                            onChange={handleChangeMaxHour} />

                    </label>

                    <div className="mt-6 card-actions">
                        <button type='submit' className='btn btn-outline'>
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddAmenity
