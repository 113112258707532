import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
// import { Link } from 'react-router-dom'
import { sendPasswordRestored } from '../../context/login/LoginActions'
import AlertContext from '../../context/alert/AlertContext'
import LoginContext from '../../context/login/LoginContext'
// import Logo from '../../components/layout/assets/logo.jpeg'

function RestorePass() {
  const params = useParams()
  const navigate = useNavigate()
  const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)
  const { settings } = useContext(LoginContext)

  //const Logo = settings.LOGO ? settings.LOGO : 'https://drive.google.com/uc?export=view&id=1mf81FCQsrYSVdN4tErBshm78BqG8H_dT'
  // let data = { message: 'Restaurando contraseña ...' }
  // const [message, setMessage] = useState(data)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    alertDispatch({ type: 'REMOVE_ALERT'})
    const getRestoredPass = async () => {
      setLoading(true)
      const response = await sendPasswordRestored(params.userToken)
      if(typeof response === 'string') {
        if (response.includes('Hemos enviado tu nueva contraseña al correo:')) {
          const email = response.replace('Hemos enviado tu nueva contraseña al correo: ', '')
          setAlert(`Contraseña actualizada exitosamente. Hemos enviado un correo a ${email} con tu nueva contraseña.`, 'info')
        } else {
          setAlert(`${response}`, 'error')
        }
      } else {
        setAlert(`El usuario no fue notificado porque hubo un error en el servicio de notificaciones. Favor de contactar a soporte.`, 'error2')
      }
      // setMessage({ message: response || ''})
      setLoading(false)
    }
    getRestoredPass()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

/*   if (isLoading) {
    return (
      <div className="w-full mx-auto lg:w-10/12">
      <div className="text-center hero-content">
        <div className='w-100 xl:w-80 lg:w-80 md:w-80 sd:w-80'> 
          <img
            className='text-center mx-auto'
            src={Logo}
            alt='Loading...'
          />
        </div>
      </div>

      <div className="text-center hero-content">
        <h3 className='card-title'>Cargando ...</h3>
      </div> 
    </div>
    )
  } */

  return (
    <div className="w-full mx-auto lg:w-10/12">
      {/* <div className="text-center hero-content">
        <div className='w-100 xl:w-80 lg:w-80 md:w-80 sd:w-80'> 
          <img
            className='text-center mx-auto'
            src={Logo}
            alt='Loading...'
          />
        </div>
      </div> */}

      {/* <div className="text-center hero-content">
        <h3 className='card-title'>{message.message}</h3>
      </div>  */}

      {/* <Link to='/' className='text-center hero-content text-lg-bold text-gray-400'>
        Ir a la página de Inicio
      </Link> */}
      
      <div className='w-full flex justify-center mb-8 gap-8 mt-9'>
        <button onClick={() => navigate(`/`)} className='w-6/12 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg'>
          Ingresar a CotoAdmin
        </button>
      </div>
    </div>
  )
}

export default RestorePass
