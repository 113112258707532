import {FaRegCheckCircle} from "react-icons/fa";
import {Link} from "react-router-dom";

function SurveyRow( { survey }) {

    return (
        <>
            <tr className="bg-white dark:bg-slate-700">

                <td className="p-3 text-lg text-gray-700   dark:text-slate-400">
                    <Link to={`/survey/questions/${survey.id}`} className="w-100%">
                        {survey.title}
                    </Link>
                </td>

                <td className="p-3  text-gray-700  dark:text-slate-400">
                    <Link to={`/survey/questions/${survey.id}`}  className="w-100%">
                        {survey.startDay}
                    </Link>
                </td>

                <td  className={`p-3 whitespace-nowrap `}>
                    <Link to={`/survey/questions/${survey.id}`} className="w-100%">

                        {survey.finalDay}
                    </Link>
                </td>

            </tr>
        </>

    );
}

export default SurveyRow;
