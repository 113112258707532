import { useContext, useEffect } from 'react'
import AlertContext from '../../context/alert/AlertContext'
import CollectsResult from "./CollectsResult";
import CollectSearch from "./CollectSearch";

function Payments() {

  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })
  
  const { dispatch: alertDispatch } = useContext(AlertContext)

  useEffect(() => {
    alertDispatch({ type: 'REMOVE_ALERT'})
  }, [alertDispatch])


  return (
      <>
        <CollectSearch />
        <CollectsResult />
      </>
  )
}

export default Payments
