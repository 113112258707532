import React, {useContext, useRef, useEffect, useCallback, useState} from 'react'
import UserItem from './UserItem'
import Spinner from '../layout/Spinner'
import LoginContext from '../../context/login/LoginContext'
import debounce  from "just-debounce-it";
import {useNearScreen} from "../../hooks/useNearScreen";

function UserResults() {

    const externalRef = useRef()
    const { users, currentPage, dispatch, loading } = useContext(LoginContext)
    const [minHeight, setMinHeight] = useState('20rem');

  const {isNearScreen} = useNearScreen({
      externalRef : loading ? null : externalRef,
      once: false  });

  const debounceHandleNextPage = useCallback( debounce(
      () =>  dispatch({ type: 'GET_CURR_PAGE', payload: currentPage + 1 }), 200
  ), [isNearScreen]);

  useEffect(() => {
      // console.log(isNearScreen);
      // console.log(users.length);
      if(isNearScreen) debounceHandleNextPage();

  }, [debounceHandleNextPage, isNearScreen]);


    useEffect(() => {

        const handleResize = () => {

            if (window.innerHeight < 769) {
                setMinHeight('30rem');
            } else if (window.innerHeight < 1025) {
                setMinHeight('60rem');
            } else if (window.innerHeight > 1025) {
                setMinHeight('70rem');
            } else {
                setMinHeight('50rem');
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerHeight]);


  // const onScroll = () => {
  //   if (listInnerRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
  //     if (scrollTop + clientHeight === scrollHeight) {
  //       dispatch({ type: 'GET_CURR_PAGE', payload: currentPage + 1 })
  //     }
  //   }
  // }

  if(!loading) {
    return (
      <>
          <div style={{ minHeight }} >
              <div
                  style={{
                      flexDirection: "column"
                  }}
                  className='grid grid-cols-1 gap-8 xl:grid-cols-3 lg:grid-cols-2 md:gris-cols-2'>
                  {users.map((user) => (
                      <UserItem key={user.userId} user={user} />
                  ))}
              </div>

          </div>

        <div
            id='scroll'
            ref={externalRef}
        ></div>
      </>
    )
  } else {
    return (
      <Spinner />
    )
  }
}

export default UserResults
