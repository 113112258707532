import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AddressContext from '../../context/address/AddressContext'
import AlertContext from '../../context/alert/AlertContext'
import { addAddress } from '../../context/address/AddressActions'
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import Ad from "../ads/Ad";

function AddAddress() {
  const { token } = useTokenAndUserData()
  const { dispatch } = useContext(AddressContext)
  const { setAlert, dispatch: alertDispatch } = useContext(AlertContext)

  const navigate = useNavigate()

  //change address to unit and numExt to building
  const [addressUnit, setAddressUnit] = useState('')
  const [addressBuilding, setAddressBuilding] = useState('')
  const [addressIntNum, setAddressIntNum] = useState('')
  const [addressPhone, setAddressPhone] = useState('')
  const [comment, setComment] = useState('')

  const handleChangeUnit = (e) => setAddressUnit(e.target.value)
  const handleChangeBuilding = (e) => setAddressBuilding(e.target.value)
  const handleChangeIntNum = (e) => setAddressIntNum(e.target.value)
  const handleChangePhone = (e) => setAddressPhone(e.target.value)
  const handleChangeComment = (e) => setComment(e.target.value)

  useEffect(() => {
    alertDispatch({ type: 'REMOVE_ALERT'})
  }, [alertDispatch])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if(addressUnit === '' ) {
      setAlert('Por favor ingresa todos los datos requeridos', 'error')
    } else {
      dispatch({ type: 'SET_LOADING' })
      const newAddress = await addAddress({
        addressUnit,
        addressBuilding,
        addressIntNum,
        addressPhone,
        comment
      }, token)
      
      dispatch({ type: 'ADD_VISIT', payload: newAddress })
      dispatch({ type: 'GET_ADDRESSES', payload: []})
      dispatch({ type: 'GET_LAST_PAGE', payload: false })
      dispatch({ type: 'GET_PREV_PAGE', payload: 0 })
      dispatch({ type: 'GET_CURR_PAGE', payload: 1 })

      setAddressUnit('')
      setAddressBuilding('')
      setAddressIntNum('')
      setAddressPhone('')
      setComment('')

      navigate(`/address/${newAddress.addressId}`)
    }
  }

  return (
    <div className="w-full mx-auto lg:w-10/12">

      <Ad/>

      <div className="text-center hero-content">
        <h1 className='text-3xl card-title'>Agregar Unidad</h1>
      </div>

      <form onSubmit={handleSubmit}>
        <div className='form-control'>

          <label className='mt-4 text-lg'>
            Unidad * :
            <input
              type='text'
              className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
              placeholder='Unidad'
              value={addressUnit}
              onChange={handleChangeUnit}
            />
          </label>

          <label className='mt-4 text-lg'>
            Edificio:
            <input
              type='text'
              className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
              placeholder='Edificio'
              value={addressBuilding}
              onChange={handleChangeBuilding}
            />
          </label>

          <label className='mt-4 text-lg'>
            Número Interior:
            <input
              type='text'
              className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
              placeholder='Número Interior'
              value={addressIntNum}
              onChange={handleChangeIntNum}
            />
          </label>

          <label className='mt-4 text-lg'>
            Télefono:
            <input
              type='text'
              className='mt-2 w-full pr-6 bg-gray-200 input input-lg text-black'
              placeholder='Télefono'
              value={addressPhone}
              onChange={handleChangePhone}
            />
          </label>

          <label className='mt-4 text-lg'>
            Comentario:
            <textarea
              className='mt-2 w-full h-40 pr-4 bg-gray-200 input input-lg text-black'
              placeholder='Comentario'
              value={comment}
              onChange={handleChangeComment}
            />
          </label>

          <div className="mt-6 card-actions">
            <button type='submit' className='btn btn-outline'>
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddAddress
