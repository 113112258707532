import React, { useState, useEffect } from 'react';

const TimeRangePicker = ({ day,hourSelected, setHourSelect, hours, start = "09:00 AM", end = "10:00 PM" }) => {

    const [startHour, setStartHour] = useState(parseInt(start.substring(0,2))); // Set initial start hour
    const [startPeriod, setStartPeriod] = useState(start.substring(6,8));
    const [endHour, setEndHour] = useState(parseInt(end.substring(0,2))); // Set initial end hour greater than start hour
    const [endPeriod, setEndPeriod] = useState(end.substring(6,8));

    useEffect(() => {

        if (day && startHour && startPeriod && endHour && endPeriod) {

            const updatedSchedule = {
                day: day,
                startTime: { hour:intToTime(startHour), period: getPeriod(startPeriod) },
                endTime: { hour:intToTime(endHour), period: getPeriod(endPeriod) }

            };

            const existingScheduleIndex = hourSelected.findIndex(schedule => schedule.day === day);

            if (existingScheduleIndex !== -1) {
                const updatedDaySchedule = [...hourSelected];
                updatedDaySchedule[existingScheduleIndex] = updatedSchedule;
                setHourSelect(updatedDaySchedule);
            } else {
                setHourSelect(prevHourSelected => [...prevHourSelected, updatedSchedule]);
            }
        }
    }, [day, startHour, startPeriod, endHour, endPeriod]);

    function intToTime(hours) {
        const zeroPad = (num) => (num < 10 ? "0" + num : num);
        const hoursPart = zeroPad(hours);
        const minutesPart = "00";
        // console.log(`${hoursPart}:${minutesPart}`)
        return `${hoursPart}:${minutesPart}`;

    }

    const getPeriod = (period) => {
        return period === 'AM' ? 'a' : 'p';
    }

    const handleStartHourChange = (event) => {
        const newStartHour = parseInt(event.target.value, 10);
        setStartHour(newStartHour);

        if (startPeriod === 'PM' && endPeriod === 'AM') {
            setEndPeriod('PM');
        } else if (newStartHour > endHour || (newStartHour === endHour && startPeriod === 'PM')) {
            setEndHour(newStartHour + 1);
            setEndPeriod('AM');
        }
    };

    const handleStartPeriodChange = (event) => {
        setStartPeriod(event.target.value);
    };

    const handleEndHourChange = (event) => {
        const newEndHour = parseInt(event.target.value, 10);
        setEndHour(newEndHour);

        // Update the end period based on the previously selected period
        // if (startPeriod === 'AM' && endPeriod === 'PM') {
        //     setEndPeriod('AM');
        if (startPeriod === 'PM' && endPeriod === 'AM') {
            setEndPeriod('PM');
        }
    };

    const handleEndPeriodChange = (event) => {
        setEndPeriod(event.target.value);
    };

    return (
        <div className="grid grid-cols-4 gap-1 mb-4">
            <div className="flex items-center">
                {day} * :
            </div>

            <div className="flex flex-col md:flex-row lg:flex-row gap-2">

                <div className="flex gap-2">
                    <div className="flex items-center">De:</div>

                    <select
                        value={startHour}
                        onChange={handleStartHourChange}
                        className="mt-2 w-22 pr-2 bg-gray-200 input input-sm text-black"
                    >
                        {hours &&
                            hours.map((hour) => (
                                <option key={hour.id} value={hour.id}>
                                    {hour.hour.substring(0,5)}
                                </option>
                            ))}
                    </select>

                    <select
                        value={startPeriod}
                        onChange={handleStartPeriodChange}
                        className="mt-2 md:w-15  lg:w-15  bg-gray-200 input input-sm text-black"
                    >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                    </select>
                </div>

                <div className="flex gap-2">
                    <div className="flex items-center">a:</div>
                    <select
                        value={endHour}
                        onChange={handleEndHourChange}
                        className="mt-2 w-22 pr-2 bg-gray-200 input input-sm text-black"
                    >
                        {hours &&
                            hours.map((hour) => (
                                <option
                                    key={hour.id}
                                    value={hour.id}
                                    disabled={
                                        startPeriod === endPeriod &&
                                        ((hour.id <= startHour && hour.hour !== '12') || (hour.id === startHour + 12))
                                    }
                                >
                                    {hour.hour.substring(0,5)}
                                </option>
                            ))}
                    </select>
                    <select
                        value={endPeriod}
                        onChange={handleEndPeriodChange}
                        className="mt-2 pr-6 md:w-15  lg:w-15  bg-gray-200 input input-sm text-black"
                    >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default TimeRangePicker;
