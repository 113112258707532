import {useContext, useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {HiChatAlt, HiIdentification} from 'react-icons/hi'
import {findNoticeById} from "../../context/notice/NoticeActions";
import NoticeContext from "../../context/notice/NoticeContext";
import AlertContext from "../../context/alert/AlertContext";
import {types} from "../../utils/constantest";
import {getColorType} from "../../utils/utilsFunctions";
import Ad from "../ads/Ad";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";

function NoticeItem() {

    const { dispatch } = useContext( NoticeContext );
    const { dispatch: alertDispatch } = useContext(AlertContext)
    const { userData, token } = useTokenAndUserData();
    const params = useParams()

    const [title, setTitle] = useState('');
    const [dateOfPublication, setDateOfPublication] = useState('');
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [colorType, setColorType] = useState('');

    useEffect(() => {

        alertDispatch({ type: 'REMOVE_ALERT'})
        dispatch({ type: 'SET_LOADING' })
        const getNoticeData = async () => {

            const noticeData = await findNoticeById(params.id, token);

            dispatch({ type: 'GET_NOTICES', payload: noticeData })

            setTitle(noticeData.title || '')
            setDateOfPublication(noticeData.announcementStartDate || '')
            setDescription(noticeData.description || '');
            setType(noticeData.type.typeName|| '');

            setColorType(getColorType(noticeData.type.typeName));
        }

        getNoticeData()

    }, [dispatch, params.id, alertDispatch])



    return (
        <div className='card shadow-lg compact side bg-base-100 w-full mx-auto lg:w-10/12 p-6'>
            <Ad/>

            <div className=" col-span-2 px-8card-body">

                <div>
                    <div className="rounded-full w-16 h-16">
                        <HiChatAlt className='text-6xl md:text-5xl' />
                    </div>
                </div>

                <div className="flex items-center w-full justify-between min-w-0 mb-2">
                    <h2 className='card-title'>
                        {title}
                    </h2>
                    <span className={`p-1.5 text-xs font-medium uppercase tracking-wider rounded-lg bg-opacity-50 ${colorType}`}>
                            {type}
                        </span>

                </div>
                <p className='text-base-content text-opacity-40 mb-2'>{dateOfPublication}</p>

                <h3 className='text-base-content '>{description}</h3>

            </div>
        </div>
    )
}

export default NoticeItem
