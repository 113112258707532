import AlertContext from "../../context/alert/AlertContext";
import {useContext, useEffect} from "react";
import ReportResult from "./ReportResult";

function Report() {

    return (
        <>
            <ReportResult />
        </>
    )
}

export default Report
