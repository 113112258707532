import {useNavigate} from "react-router-dom";
import useTokenAndUserData from "../../context/token/useTokenAndUserData";
import {validatePermissions} from "../../context/login/LoginActions";
import FileResults from "./FileResults";
import Ad from "../ads/Ad";
import {useContext, useEffect} from "react";
import FileContext from "../../context/files/FileContext";
import {checkNoticeHealth} from "../../context/notice/NoticeActions";
import {checkFileHealth} from "../../context/files/FileActions";

function Files() {

    const { userData } = useTokenAndUserData();
    const navigate = useNavigate();
    const { dispatch, isUpFile } = useContext( FileContext );

    useEffect( () => {
        const checkIsUp = async () => {
            const status = await checkFileHealth();
            const isUp  = status?.status === 200;
            dispatch({ type: 'SET_IS_UP', payload: isUp });
        }

        checkIsUp();

    }, [])

    return (
        <>

            <div className="w-full mx-auto lg:w-10/12">

                <Ad/>
                <div className="text-center hero-content pt-0 pb-0">
                    <h1 className='text-3xl card-title'>Documentos</h1>
                </div>

                <div className="my-5">
                    {validatePermissions(userData.userPermissions, 'FILES') &&
                        <div className='w-full flex justify-center mb-8 gap-8 mt-9'>

                            {isUpFile ?
                                <button onClick={() => navigate(`/files/create`)} className='mr-6 w-10/12 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg'>
                                    Agregar documento
                                </button>
                                :
                                <button disabled={true} className='mr-6 w-10/12 btn btn-md xl:btn-lg lg:btn-lg md:btn-lg'>
                                    Agregar documento
                                </button>

                            }

                        </div>
                    }

                </div>

                <FileResults />


            </div>


        </>
    )
}

export default Files
