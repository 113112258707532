import React, {useContext, useRef, useCallback, useEffect, useState} from 'react'
import VisitorItem from './VisitorItem'
import Spinner from '../layout/Spinner'
import VisitorContext from '../../context/visitors/VisitorContext'
import {useNearScreen} from "../../hooks/useNearScreen";
import debounce from "just-debounce-it";

function VisitorResults() {
    const listInnerRef = useRef()
    const { visitors, currentPage, dispatch, loading } = useContext(VisitorContext)
    const [minHeight, setMinHeight] = useState('50rem');

    useEffect(() => {
        const handleResize = () => {

            if (window.innerHeight < 769) {
                setMinHeight('30rem');
            } else if (window.innerHeight < 1025) {
                setMinHeight('60rem');
            } else if (window.innerHeight > 1025) {
                setMinHeight('70rem');
            } else {
                setMinHeight('50rem');
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerHeight]);

  const {isNearScreen} = useNearScreen({
    externalRef : loading ? null : listInnerRef,
    once: false  });

  const debounceHandleNextPage = useCallback( debounce(
      () =>  dispatch({ type: 'GET_CURR_PAGE', payload: currentPage + 1 }), 200
  ), [isNearScreen]);

  useEffect(() => {
    if(isNearScreen) debounceHandleNextPage();

  }, [debounceHandleNextPage, isNearScreen]);


  if(!loading) {
    return (
      <>
          <div style={{ minHeight }}>
              <div
                  style={{
                      flexDirection: "column"
                  }}
                  className='grid grid-cols-1 gap-8 xl:grid-cols-3 lg:grid-cols-2 md:gris-cols-2'>
                  {visitors.map((visitor) => (
                      <VisitorItem key={visitor.visitorId} visitor={visitor} />
                  ))}
              </div>
          </div>

        <div
            id='scroll'
            ref={listInnerRef}
        ></div>

      </>
    )
  } else {
    return (
      <Spinner />
    )
  }
}

export default VisitorResults
