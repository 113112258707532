import axios from 'axios'
import {customAlphabet} from 'nanoid'
//const userServiceUrl = process.env.REACT_APP_USER_SERVICE || 'http://70.35.204.186:3000/pjd-cotoadmin-user'

const host = process.env.REACT_APP_HOST || window.location.origin
const link = window.location.origin
const userServiceUrl = `${host}${process.env.REACT_APP_USER_SERVICE || '/user'}`
// const userServiceUrl = 'http://localhost:3004/user'

const accessMap = {
  isAdmin: ['ADMIN'],
  isResident: ['RESIDENT'],
  isGuard: ['GUARD'],
  home: ['ADMIN', 'GUARD', 'RESIDENT'],
  login: ['ADMIN', 'GUARD', 'RESIDENT'],
  searchVisit: ['GUARD'], //can be used in visitis
  visits: ['RESIDENT'], //agendados
  visit: ['ADMIN', 'GUARD', 'RESIDENT'],//it is where the code lives it;s kinda the item of the visitor
  useCode: ['GUARD'], //codigos
  createVisit: ['RESIDENT'], //can be used in visitis
  addresses: ['ADMIN'], //dirrecciones
  visitors: ['ADMIN', 'RESIDENT'], //visitantes
  users: ['ADMIN'], //users
  about: ['ADMIN', 'GUARD', 'RESIDENT'],
}

const db = axios.create({
  baseURL: userServiceUrl,
})

// Get logged user details
export const getLoggedUser = async (userId, token) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const response = await db.get(`/api/v1/users/${userId}`, config)
  return response.data.data
}

// Get settings of the instance
export const getSettings = async () => {

  const domain = host.split('//')[1].split('/')[0];
  const response = await db.get(`/api/v1/tenant/${domain}`)
  return response.data.data
}

// Authenticate user
export const authUser = async (data) => {
  console.log(data);
  const response = await db.post(`/api/v1/users/login`, data)
  return response.data.data || response.data.message
}

// Validate Token
export const validateToken = async (data) => {
  try {
    const response = await db.post(`/api/v1/users/token`, data)
    return response.data.data
  } catch (error) {
    return { permissions: [] }
  }
}

// Send url to restore password by email
export const sendRestoreUrl = async (userEmail) => {
  try {
    const data = { host: link }
    const response = await db.patch(`/api/v1/users/${userEmail}/restoreUrl`, data)
    return response.data.data || response.data.message
  } catch (error) {
    const response = error?.response?.data || 'Se produjo un error en el servidor'
    return response
  }
}

// Send restored password by email
export const sendPasswordRestored = async (userToken) => {
  try {
    const data = { host: link }
    const response = await db.patch(`/api/v1/users/${userToken}/restorePassword`, data)
    return response.data.data || response.data.message
  } catch (error) {
    return false
  }
}

// Validate User Permissions
export const validateRoles = (permissions, component) => {

  if (accessMap[`${component}`]) {
    return (accessMap[`${component}`]?.find((permission) => permissions.includes(permission))) ? true : false
  }

  return false
}

export const validateRol = (permissions, component) => {
    return permissions.includes(component);
}

export const validatePermissions = (permissions, permission_name) => {
  const existsPermissions = permissions?.find(perm => perm === permission_name.toUpperCase());
  return !!existsPermissions;
}

// Search/Get Users on the DB
export const searchUsers = async ({ keywords = '', pageSize = 25, page = 0, token }) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const params = new URLSearchParams({
    keywords,
    pageSize,
    page,
  })

  const response = await db.get(`/api/v1/users?${params}`, config)
  return response.data
}

// Get user details
export const getUser = async (userId, token, app_id) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const response = await db.get(`/api/v1/users/${userId}?app_id=${app_id}`, config)
  return response.data.data
}

// Generate temporal password for new users
export const getTempPassword = async (pass) => {
  let password = pass
  if (!password) {
    const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTVUWXYZabcdefghijklmnopqrstuvwxyz_-!.', 8)
    password = nanoid()
  }

  const config = { 
    headers: {
      authorization: `Bearer ${password}`
    }
  }

  const response = await db.get(`/api/v1/users/validate`, config)
  return {
    password,
    userPass: response.data.data.hash
  }
}

export const getTempPass = () => {
  try {
    const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTVUWXYZabcdefghijklmnopqrstuvwxyz_-!.', 8)
    return nanoid()
  } catch (error) {
    return 'Cmk_916!'
  }
}

// Create new user
export const addUser = async (data, token) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json'
    }
  }

  try {
    const response = await db.post(`/api/v1/users`, { ...data, host: link }, config);
    return response.data.data;

  } catch (error) {

    return error.response.data;
  }
}

// Update user
export const updateUser = async (userId, data, token) => {

  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  } 

  try {
    const response = await db.patch(`/api/v1/users/${userId}`, { ...data, host: link }, config)

    // console.log(response);

    return response.data.data || response.data.message
  } catch (error) {

    const response = error.response.data || 'Se produjo un error en el servidor'
    return response
  }
}

// Update user password
export const updateUserPass = async (userId, data, token) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const response = await db.patch(`/api/v1/users/${userId}/pass`, data, config)
  return response.data.data || response.data.message
}

// Delete user
export const deleteUser = async (userId, token) => {
  const config = { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  if(window.confirm('¿Seguro que deseas eliminar este usuario?')) {    
    const response = await db.delete(`/api/v1/users/${userId}`, config)
    return response.data.data
  }
  return false
}

export const getAllPermissions = async ({token}) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  // console.log(config);
  const response = await db.get(`/api/v1/permissions`, config)

  // console.log(response);
  return response.data
}

export const checkUserHealth = async () => {
  try {
    return await db.get(`/health`);
  } catch (error) {
    return error;
  }
}
