
export const types = [
    {
        typeName: 'noticia',
        colorType: 'blue'
    },
    {
        typeName: 'recordatorio',
        colorType: 'green'
    },
    {
        typeName: 'emergencia',
        colorType: 'red'
    },
    {
        typeName: 'evento',
        colorType: 'indigo'
    },
    {   typeName: 'mantenimiento',
        colorType: 'lime'
    }
]

export const dayIndexMap = {
    "Domingo": 0,
    "Lunes": 1,
    "Martes": 2,
    "Miércoles": 3,
    "Jueves": 4,
    "Viernes": 5,
    "Sábado": 6
};

export const monthIndexMap = {
    "Enero": 0,
    "Febrero": 1,
    "Marzo": 2,
    "Abril": 3,
    "Mayo": 4,
    "Junio": 5,
    "Julio": 6,
    "Agosto": 7,
    "Septiembre": 8,
    "Octubre": 9,
    "Noviembre": 10,
    "Diciembre": 11
};


export const colors = [
    {
        id: 1,
        name: 'purple',
        bgColor:'#c4b5fd',
        bgSpan:'#6d28d9'
    },
    {
        id: 2,
        name: 'green',
        bgColor:'#86efac',
        bgSpan:'#15803d'
    },
    {
        id: 3,
        name: 'blue',
        bgColor:'#93c5fd',
        bgSpan:'#1d4ed8'
    },
    {
        id: 4,
        name: 'cyan',
        bgColor:'#67e8f9',
        bgSpan:'#0e7490'
    },
    {
        id: 5,
        name: 'pink',
        bgColor:'#f9a8d4',
        bgSpan:'#be185d'
    },
    {
        id: 6,
        name: 'orange',
        bgColor:'#fcd34d',
        bgSpan:'#c2410c'
    },
    {
        id: 7,
        name: 'yellow',
        bgColor:'#fde047',
        bgSpan:'#a16207'
    },
    {
        id: 8,
        name: 'Red',
        bgColor:'#fca5a5',
        bgSpan:'#b91c1c'
    },
    {
        id: 9,
        name: 'fuchsia',
        bgColor:'#f0abfc',
        bgSpan:'#a21caf'
    },
    {
        id: 10,
        name: 'lime',
        bgColor:'#bef264',
        bgSpan:'#4d7c0f'
    },
]

